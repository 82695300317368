import { RouterLink } from '@vkph/components';
import { UiAvatar, UiAvatarSize, UiCol, UiRow } from '@vkph/ui';
import React, { FC } from 'react';

import { useCurrentProfile } from '@vkph/common/providers/current-profile';
import { useSettingsConfig } from '@vkph/common/providers/settings-config';
import { getRoutePath, RouteNames } from '@vkph/common/utils';
import { useTheme, variables } from '@vkph/ui/providers/theme';

import { HeaderActions } from '../actions';
import { HeaderEventsButton } from '../events-button';
import { HeaderLogo } from '../logo';
import { HeaderNotificationsButton } from '../notifications-button';
import { HeaderSearch } from '../search/HeaderSearch';

export const HeaderDesktop: FC = () => {
  const [{ variables: themeVariables }] = useTheme();
  const profile = useCurrentProfile();
  const { config } = useSettingsConfig();
  const isEventsButtonEnabled = !config.layouts?.headerEventsMeetingsDropdownButtonDisabled?.value;

  return (
    <UiCol flex="auto">
      <UiRow justify="space-between" align="middle" wrap={false} style={{ marginLeft: 6 }} gutter={[20, 0]}>
        <UiCol>
          <HeaderLogo />
        </UiCol>
        <UiCol flex="auto">
          <HeaderSearch />
        </UiCol>
        <UiCol>
          <UiRow align="middle" wrap={false} gutter={[16, 0]}>
            {isEventsButtonEnabled && (
              <UiCol>
                <HeaderEventsButton
                  type="default"
                  size="large"
                  style={{ backgroundColor: themeVariables.colorInputOnBrand }}
                />
              </UiCol>
            )}
            <UiCol>
              <HeaderNotificationsButton
                type="default"
                size="large"
                style={{ backgroundColor: themeVariables.colorInputOnBrand }}
              />
            </UiCol>
            <UiCol>
              <RouterLink to={getRoutePath(RouteNames.Profile, { id: profile.id })}>
                <UiAvatar
                  size={UiAvatarSize.L}
                  src={profile?.smallAvatar}
                  style={{ border: `2px solid ${variables.themePalette.white}` }}
                />
              </RouterLink>
            </UiCol>
            <UiCol>
              <HeaderActions />
            </UiCol>
          </UiRow>
        </UiCol>
      </UiRow>
    </UiCol>
  );
};
