import { UiSkeleton, UiSpace } from '@vkph/ui';
import React, { FC } from 'react';

export const CommentListStandaloneLoading: FC = () => {
  return (
    <UiSpace full size={24} style={{ padding: '12px 0' }} direction="vertical">
      <UiSpace>
        <UiSkeleton loading circle width={40} height={40} />
        <UiSpace direction="vertical">
          <UiSkeleton loading width={150} />
          <UiSkeleton loading width={150} />
        </UiSpace>
      </UiSpace>
      <UiSkeleton loading width={400} height={30} />
      <UiSpace>
        <UiSkeleton loading width={100} />
        <UiSkeleton loading width={100} />
      </UiSpace>
    </UiSpace>
  );
};
