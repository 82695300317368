import { VideoPlayer } from '@vkph/components';
import { UiModal, UiModalProps, UiModalTypes, message, modalHeaderHeight, modalOffset } from '@vkph/ui';
import React, { FC, useMemo } from 'react';

import { useClipboard } from '@vkph/common/hooks';
import { FileStorageListEntry } from '@vkph/common/types/models';
import ShareSvg from '@vkph/ui/svg/share.svg';
import { getModalStepsForSingleTitle } from '@vkph/ui/utils';

const getMaxPossibleElementSideSize = (elementSize: number, viewSize: number) => {
  if (elementSize > viewSize) {
    return viewSize - modalHeaderHeight - modalOffset * 2;
  }

  return elementSize;
};

const getMaxPossibleElementDimension = ({
  elementWidth,
  elementHeight,
  viewWidth,
  viewHeight,
}: {
  elementWidth: number;
  elementHeight: number;
  viewWidth: number;
  viewHeight: number;
}) => {
  const width = getMaxPossibleElementSideSize(elementWidth, viewWidth);
  const height = getMaxPossibleElementSideSize(elementHeight, viewHeight);

  const widthByRatio = width / elementWidth;
  const heightByRatio = height / elementHeight;

  const aspectRatio = widthByRatio > heightByRatio ? heightByRatio : widthByRatio;

  return {
    width: elementWidth * aspectRatio,
    height: elementHeight * aspectRatio,
  };
};

export interface FileInfoCardModalProps extends Omit<UiModalProps, 'type'> {
  fileItem: FileStorageListEntry;
  isShareable: boolean;
  onShare: () => void;
}

export const FileInfoCardVideoModal: FC<FileInfoCardModalProps> = (props) => {
  const { fileItem, isShareable, onShare, ...modalProps } = props;

  const [setCopied] = useClipboard(window.location.href, {
    onSuccess: () => message.success('Ссылка скопирована'),
  });

  const containerResolution = useMemo<{ width?: number; height?: number }>(() => {
    if (fileItem?.additional?.videoData) {
      const {
        additional: {
          videoData: { width: originalWidth, height: originalHeight },
        },
      } = fileItem;

      return getMaxPossibleElementDimension({
        elementWidth: originalWidth,
        elementHeight: originalHeight,
        viewWidth: window.innerWidth,
        viewHeight: window.innerHeight,
      });
    }

    return {
      width: undefined,
      height: undefined,
    };
  }, [fileItem]);

  return (
    <UiModal type={UiModalTypes.Fit} {...modalProps}>
      <UiModal.Header>
        <UiModal.Header.Title truncate steps={getModalStepsForSingleTitle(fileItem.name)} />
      </UiModal.Header>
      <UiModal.Content>
        <VideoPlayer
          id="player"
          url={fileItem?.file}
          containerWidth={containerResolution.width}
          containerHeight={containerResolution.height}
          actions={isShareable ? [{ icon: ShareSvg, onClick: setCopied }] : []}
        />
      </UiModal.Content>
    </UiModal>
  );
};
