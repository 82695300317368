import React from 'react';
import { animated, SpringValue } from 'react-spring';
import { useGesture } from 'react-use-gesture';

import styles from './SlotsTableSelection.scss';

type Props = {
  x: SpringValue<number>;
  width: SpringValue<number>;
  bindMiddleSectionGesture?: ReturnType<typeof useGesture>;
  bindLeftBorderGesture?: ReturnType<typeof useGesture>;
  bindRightBorderGesture?: ReturnType<typeof useGesture>;
};

export type SlotsTableSelectionComponent = React.FC<Props>;

export const SlotsTableSelection: SlotsTableSelectionComponent = (props) => {
  const { x, width, bindMiddleSectionGesture, bindLeftBorderGesture, bindRightBorderGesture } = props;

  return (
    <animated.div className={styles.slotsTableSelection} style={{ x, width }}>
      <animated.div
        {...(bindLeftBorderGesture && bindLeftBorderGesture())}
        className={styles.slotsTableSelection__border}
      />
      <animated.div
        {...(bindMiddleSectionGesture && bindMiddleSectionGesture())}
        className={styles.slotsTableSelection__inner}
      />
      <animated.div
        {...(bindRightBorderGesture && bindRightBorderGesture())}
        className={styles.slotsTableSelection__border}
      />
    </animated.div>
  );
};
