import { UserAvatar, Notification, RouterLink } from '@vkph/components';
import { UiAvatarProps, UiSpace, UiTruncateMarkup } from '@vkph/ui';
import React, { FC } from 'react';

import { useLongreadLink } from '@vkph/common/hooks';
import { LongreadTypes } from '@vkph/common/types/longread';
import { NotificationMicropostReactionModel, NotificationModel, PostTypes } from '@vkph/common/types/models';
import {
  RouteNames,
  getFullNameWithoutPatronymic,
  getPlainTextFromHtml,
  getRoutePath,
  parseMentionsToPlainText,
} from '@vkph/common/utils';

import { getNotificationDescription } from '../../../../../plugins/notification/get-notification-description';

interface Props {
  notification: NotificationModel<NotificationMicropostReactionModel>;
}

export const NotificationMicropostReaction: FC<Props> = (props) => {
  const { notification } = props;
  const {
    status,
    image,
    createdAt,
    initiator: { fullName, keycloakId, isActive },
  } = notification;

  const description = getNotificationDescription(notification);
  const title = getFullNameWithoutPatronymic(fullName);
  const avatar = { src: image } satisfies UiAvatarProps;
  const userLink = getRoutePath(RouteNames.Profile, { id: keycloakId });

  const { micropost } = notification.content;
  const { id, body } = micropost;
  const linkProps = useLongreadLink({ id, type: LongreadTypes.Post, postType: PostTypes.Micropost });

  const plainTextContent = body?.data && getPlainTextFromHtml(body.data);
  const micropostText =
    (plainTextContent && parseMentionsToPlainText(plainTextContent)) || 'Открыть микропост';

  return (
    <Notification status={status}>
      <Notification.Header
        title={title}
        subtitle={description}
        avatar={<UserAvatar {...avatar} />}
        to={userLink}
        isActive={isActive}
      />
      <Notification.Body>
        <UiSpace size={8} direction="vertical" full>
          <RouterLink {...linkProps}>
            <UiTruncateMarkup lines={2} truncate>
              {micropostText}
            </UiTruncateMarkup>
          </RouterLink>
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
