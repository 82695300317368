import { OriginListTyping } from '@vkph/common/types/models';

import { SearchCategory } from '../../../store/search/advanced-search/constants';

export const SEARCH_ORIGIN_LIST: OriginListTyping[] = [
  {
    type: SearchCategory.All,
    title: 'Искать везде',
  },
  {
    type: SearchCategory.Profile,
    title: 'Пользователи',
  },
  {
    type: SearchCategory.Blog,
    title: 'Группы',
  },
  {
    type: SearchCategory.Post,
    title: 'Посты',
  },
  {
    type: SearchCategory.Comment,
    title: 'Комментарии',
    listTitle: 'Комментарии',
  },
  {
    type: SearchCategory.News,
    title: 'Новости',
    listTitle: 'Новости',
  },
  {
    type: SearchCategory.Pages,
    title: 'Страницы',
    listTitle: 'Страницы',
  },
];

export const HEADER_SEARCH_CATEGORIES_AMOUNT = 3;

export const HEADER_SEARCH_CATEGORY_AMOUNT = 8;
