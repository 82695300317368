import { Post } from '@vkph/components';
import { UiIcon, UiSpace, UiTag, UiTooltipPlacement, UiTruncateMarkupProps, UiTypography } from '@vkph/ui';
import React, { FC, useMemo } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getPostAttachmentsStorage } from '@vkph/common/store/attachment';
import { PostBasicModel } from '@vkph/common/types/models';
import { getPlainTextFromHtml, parseTextPostContent, utcToLocalDate } from '@vkph/common/utils';
import AttachSvg from '@vkph/ui/svg/attach.svg';

import { PostCompoundSearchViewContent } from './content/PostCompoundSearchViewContent';

export type PostCompoundSearchViewProps = {
  post: PostBasicModel;
  compact?: boolean;
  contentLines?: UiTruncateMarkupProps['lines'];
  tooltipPlacement?: UiTooltipPlacement;
};

export const PostCompoundSearchView: FC<PostCompoundSearchViewProps> = (props) => {
  const { post, compact, contentLines, tooltipPlacement } = props;
  const { id, type, tags, title, publishedAt, createdAt } = post;

  const plainBodyText = useMemo(() => {
    const parsedHtml = parseTextPostContent(post);

    return getPlainTextFromHtml(parsedHtml);
  }, [post]);

  const { storage: attachmentsStorage } = useMemo(() => {
    return getPostAttachmentsStorage({
      postId: id,
      postType: type,
    });
  }, [id, type]);

  const { data: postAttachments } = useAbstractStorage(attachmentsStorage, {
    autoFetchAndRefetch: !compact,
    cancelPendingRequestOnUnmount: true,
  });

  const publishDate = useMemo(() => {
    return utcToLocalDate(publishedAt || createdAt, 'dd MMMM в H:mm');
  }, [publishedAt, createdAt]);

  const PostSearchViewSubtitle: FC = () => {
    if (compact) {
      return (
        <UiSpace size={0} direction="vertical" align="start">
          {publishDate}
          {plainBodyText && (
            <PostCompoundSearchViewContent
              lines={contentLines}
              type="secondary"
              compact
              text={plainBodyText}
              tooltipPlacement={tooltipPlacement}
            />
          )}
        </UiSpace>
      );
    }

    return (
      <UiSpace size={16} full direction="vertical">
        <UiSpace size={0} full direction="vertical">
          <UiSpace size={8} full direction="vertical">
            {publishDate}

            <UiSpace size={0} full direction="vertical">
              {title && <UiTypography.Title level={2}>{title}</UiTypography.Title>}
              {plainBodyText && <PostCompoundSearchViewContent lines={contentLines} text={plainBodyText} />}
            </UiSpace>
          </UiSpace>
        </UiSpace>
        {Boolean(tags.length) && <UiTag.Group tags={tags} />}
        {Boolean(postAttachments.length) && (
          <UiIcon.Label component={AttachSvg} label={`Вложения: ${postAttachments.length}`} strong />
        )}
      </UiSpace>
    );
  };

  return (
    <Post.Header
      spaceProps={{ align: 'start' }}
      padding={[0, 24]}
      author={post.author}
      postDate={post.publishedAt || post.createdAt}
      subTitle={<PostSearchViewSubtitle />}
    />
  );
};
