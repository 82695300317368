import { LAYOUT_NAVBAR_COLLAPSED_BREAKPOINT, useBreakpoint } from '@vkph/ui';
import React, { FC } from 'react';

import { NavbarMobile } from './mobile';
import { NavigationMenu } from './navigation/menu/NavigationMenu';

export const Navbar: FC = () => {
  const breakpoints = useBreakpoint();

  if (breakpoints[LAYOUT_NAVBAR_COLLAPSED_BREAKPOINT]) {
    return <NavigationMenu />;
  }

  return <NavbarMobile />;
};
