import { UiButton, UiIcon, UiInput, UiModal, UiModalTypes, UiSkeleton, UiTypography } from '@vkph/ui';
import classNames from 'classnames';
import React, { useMemo, useEffect, useState, FC } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getDictsStorage } from '@vkph/common/store/dictionaries';
import { DictDataParams } from '@vkph/common/types/api';
import { RecordResponse } from '@vkph/common/types/models';
import CheckSVG from '@vkph/ui/svg/check.svg';
import { getModalStepsForSingleTitle } from '@vkph/ui/utils';

import { SearchFilter, FilterCatogories } from '../SearchPage';
import styles from './SearchFiltersModal.scss';

interface Props {
  searchFilter: FilterCatogories;
  onClose: () => void;
  changeFilters: (selectedSearchFilter: SearchFilter) => void;
  selectedSearchFilters: SearchFilter[];
}

export const SearchFiltersModal: FC<Props> = (props) => {
  const { searchFilter, onClose, changeFilters, selectedSearchFilters } = props;

  const dictsStorage = useMemo(
    () =>
      getDictsStorage<RecordResponse, DictDataParams>({
        dictionaryName: searchFilter.dictionaryName,
      }),
    [searchFilter.dictionaryName],
  );

  const { data: categories, loading } = useAbstractStorage(dictsStorage.storage, {
    autoFetchAndRefetch: true,
  });

  const [selectedFilter, setSelectedFilter] = useState<SearchFilter>();
  const [searchText, setSearchText] = useState('');
  const [filteredBySearchCategories, setFilteredBySearchCategories] = useState<RecordResponse[]>([]);

  const onChangeFilters = () => {
    if (!selectedFilter) {
      return;
    }

    changeFilters(selectedFilter);
    onClose();
  };

  useEffect(() => {
    if (!categories || !selectedSearchFilters) {
      return;
    }

    const filterToSelect = selectedSearchFilters.find((filter) =>
      categories.some((category) => category.id === filter.id),
    );

    if (filterToSelect) {
      setSelectedFilter(filterToSelect);
    }
  }, [searchFilter, categories]);

  useEffect(() => {
    setFilteredBySearchCategories(categories);
  }, [categories]);

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value);
  const onClear = () => setSearchText('');

  useDebouncedCallback(() => {
    const text = searchText.trim();

    if (text.length > 0) {
      setFilteredBySearchCategories(
        categories.filter(({ attributes: { name } }) => name.toLowerCase().indexOf(text.toLowerCase()) > -1),
      );
    }
  }, 500);

  const onFilterSet = (id: string, name: string) =>
    setSelectedFilter({
      id,
      key: searchFilter.key,
      label: searchFilter.label,
      value: name,
    });

  return (
    <UiModal type={UiModalTypes.Small} isOpen={Boolean(searchFilter)} onClose={onClose}>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle(searchFilter?.searchTitle)} />
      </UiModal.Header>
      <UiModal.Content className={styles.searchFiltersModal__content}>
        <UiInput.Search
          className={styles.searchFiltersModal__input}
          allowClear
          size="large"
          placeholder={searchFilter?.searchPlaceholder}
          onClear={onClear}
          onChange={onChangeSearch}
        />
        <div className={styles.searchFiltersModal__items}>
          <UiSkeleton loading={loading} height={150}>
            {filteredBySearchCategories.map(({ id, attributes: { name } }) => (
              <UiButton
                key={id}
                className={styles.searchFiltersModal__item}
                type="tertiary"
                onClick={() => onFilterSet(id, name)}
              >
                <UiTypography.Text
                  strong
                  className={classNames({
                    [styles.searchFiltersModal__itemText]: selectedFilter?.id !== id,
                    [styles.searchFiltersModal__itemText_selected]: selectedFilter?.id === id,
                  })}
                >
                  {name}
                </UiTypography.Text>
                {selectedFilter?.id === id && (
                  <UiIcon
                    className={styles.searchFiltersModal__icon}
                    component={CheckSVG}
                    height={20}
                    width={20}
                  />
                )}
              </UiButton>
            ))}
          </UiSkeleton>
        </div>

        <div className={styles.searchFiltersModal__actions}>
          <UiButton
            className={styles.searchFiltersModal__action}
            type="tertiary"
            label="Отмена"
            size="large"
            onClick={onClose}
          />
          <UiButton
            className={styles.searchFiltersModal__action}
            type="primary"
            label="Применить"
            size="large"
            onClick={onChangeFilters}
          />
        </div>
      </UiModal.Content>
    </UiModal>
  );
};
