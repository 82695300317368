import { SearchOptionComplete, SearchOptionItem } from '@vkph/components';
import { UiButton, UiForm, message } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, useMemo, useState } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { competenciesDictsStorage } from '@vkph/common/store/profile';
import { GetSkillsStorage } from '@vkph/common/store/profile/skills';
import { SaveSkillParams } from '@vkph/common/store/profile/skills/api';
import { DictMatchTypes } from '@vkph/common/types/api';
import { SkillType, UserSkill } from '@vkph/common/types/models';
import { getErrorResponseMessage, generateAutoCompleteOptions } from '@vkph/common/utils';

import { skillsTextMap } from '../../Skills';
import { SkillsBaseModal } from '../skills-base/SkillsBaseModal';

interface InitialValues {
  skillsSelect: SearchOptionItem | null;
}

const initialValues: InitialValues = {
  skillsSelect: null,
};

interface Props {
  type: SkillType;
  closeModal: () => void;
  storage: GetSkillsStorage;
  skills: UserSkill[];
}

export const SkillsChoiceModal: FC<Props> = (props) => {
  const { closeModal, type, storage, skills } = props;
  const value: SearchOptionItem = { id: '', name: '' };
  const [searchQuery, setQuerySearch] = useState('');
  const [isSkillsSelect, setIsSkillsSelect] = useState(false);

  const { data: competenciesData } = useAbstractStorage(competenciesDictsStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { name: { type: DictMatchTypes.Icontains, value: searchQuery } },
  });

  const { saveSkillEffect } = storage;
  const saveSkillInProgress = useStore(saveSkillEffect.pending);

  const competenciesOptions = useMemo(() => {
    const existingSkillsIds = skills.map((item) => item.skill.id);
    const filtered = competenciesData.filter((item) => !existingSkillsIds.includes(item.id));

    return generateAutoCompleteOptions(filtered);
  }, [competenciesData]);

  const filteredOptions = useMemo(
    () =>
      competenciesOptions.filter((item) =>
        String(item.label)?.toLowerCase()?.includes(searchQuery.toLowerCase()),
      ),
    [competenciesOptions, searchQuery],
  );

  const SubmitChildren = (
    <UiButton
      block
      size="large"
      type="primary"
      label="Сохранить"
      htmlType="submit"
      loading={saveSkillInProgress}
      disabled={saveSkillInProgress || !isSkillsSelect}
    />
  );

  const onFinish = ({ skillsSelect }: { skillsSelect: SearchOptionItem }) => {
    const { name } = skillsSelect;

    const selectedSkill = filteredOptions.find((item) => String(item.label) === name);

    if (selectedSkill) {
      const saveSkillParams: SaveSkillParams = {
        name: selectedSkill.value,
        skillId: selectedSkill.key,
        skillType: type,
      };

      saveSkillEffect(saveSkillParams)
        .then(closeModal)
        .catch((e) => message.error(getErrorResponseMessage(e)));
    }
  };

  const onValuesChange = (values: InitialValues) => setIsSkillsSelect(Boolean(values && values.skillsSelect));

  return (
    <UiForm onFinish={onFinish} onValuesChange={onValuesChange} initialValues={initialValues}>
      <SkillsBaseModal
        title={`Добавить ${skillsTextMap[type].OneDown}`}
        onCancel={closeModal}
        submitChildren={SubmitChildren}
      >
        <UiForm.Item name="skillsSelect" noStyle>
          <SearchOptionComplete
            options={filteredOptions}
            onSearch={setQuerySearch}
            searchQuery={searchQuery}
            updateOptionListHandler={setQuerySearch}
            value={value}
            style={{ width: '100%' }}
          />
        </UiForm.Item>
      </SkillsBaseModal>
    </UiForm>
  );
};
