import { UserList } from '@vkph/components';
import { UiModal, UiModalSteps } from '@vkph/ui';
import { useStoreMap } from 'effector-react';
import React, { FC, useMemo } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getGamificationUsersByBadgeIdStorage } from '@vkph/common/store/gamification';
import { GamificationBadgeInfo } from '@vkph/common/types/models';

interface Props {
  badge: GamificationBadgeInfo;
}

export const TimelineCardBadgeUsersModal: FC<Props> = (props) => {
  const { badge } = props;
  const { id, name } = badge;

  const { storage } = useMemo(getGamificationUsersByBadgeIdStorage, []);
  const {
    loading: awardedUsersLoading,
    fetchFx: filterAwardedUsers,
    pagination: { count: totalAwardedUser },
  } = useAbstractStorage(storage, {
    autoFetchAndRefetch: Boolean(id),
    autoFetchParams: { badgeId: id },
    cancelPendingRequestOnUnmount: true,
  });

  const users = useStoreMap(storage.store, ({ data }) => data.map(({ user }) => user));

  const onUserSearch = (userName: string) => filterAwardedUsers({ badgeId: id, userName });

  const titleStep: UiModalSteps = useMemo(() => {
    return {
      1: {
        title: `Обладатели награды "${name}"`,
        badgeValue: totalAwardedUser,
      },
    };
  }, [name, totalAwardedUser]);

  return (
    <>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={titleStep} />
      </UiModal.Header>
      <UiModal.Content style={{ padding: '24px 0' }}>
        <UserList users={users} loading={awardedUsersLoading} onSearch={onUserSearch} />
      </UiModal.Content>
    </>
  );
};
