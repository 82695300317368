import {
  BaseJobModel,
  FullNameModel,
  Gender,
  SearchListItem,
  UserAvatarModel,
  UserIdModel,
  UserProfileModel,
} from '@vkph/common/types/models';
import { getFullNameWithoutPatronymic } from '@vkph/common/utils';

export interface ProfileMain extends UserAvatarModel {
  id: string;
  externalId?: UserIdModel;
  bossKeycloakId?: UserIdModel;
  fullName: FullNameModel;
  gender?: Gender;
  username: string;
  superAdmin: boolean;
  isActive: boolean;
  job?: BaseJobModel & Pick<UserProfileModel, 'remoteEmployee'>;
  actualAddess?: string;
  mobilePhone?: string;
  workPhone?: string;
  workMessenger?: string;
}

export const searchListResultDataMapper = (searchModel: ProfileMain[]): SearchListItem[] => {
  return searchModel.map((model) => ({
    id: model.id,
    title: getFullNameWithoutPatronymic(model.fullName),
    subTitle: model?.job?.position?.name ?? '',
    image: model?.smallAvatar ?? '',
    isActive: model.isActive,
  }));
};
