import { endpoints } from '@vkph/common/endpoints';
import { ProfileListParams } from '@vkph/common/store/profile';
import { DictPaginated } from '@vkph/common/types/api';
import { SearchListItem } from '@vkph/common/types/models';
import { abstractStorageFactory, buildEndpointWithQueryParams } from '@vkph/common/utils';

import { ProfileMain, searchListResultDataMapper } from '../../../types/search/profile';

export const getSearchProfileStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<ProfileMain>,
    SearchListItem[],
    SearchListItem[],
    ProfileListParams
  >({
    endpointBuilder: (params) => buildEndpointWithQueryParams(endpoints.profile.v2List(), params),
    defaultValue: [],
    dataMapper: ({ items }) => searchListResultDataMapper(items),
    paginationInfoRetriever: ({ meta: { objectsTotal: count } }) => ({ count }),
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};
