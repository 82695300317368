import { UiButton, UiModal } from '@vkph/ui';
import React, { FC } from 'react';

import { getModalStepsForSingleTitle } from '@vkph/ui/utils';

type Props = {
  title: string;
  onCancel: () => void;
  children: React.ReactNode;
  submitChildren: React.ReactNode;
};

export const SkillsBaseModal: FC<Props> = (props) => {
  const { title, onCancel, children, submitChildren } = props;

  return (
    <>
      <UiModal.Header>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle(title)} />
      </UiModal.Header>

      <UiModal.Content style={{ padding: '0 24px' }}>{children}</UiModal.Content>
      <UiModal.Footer hasTopBorder={false}>
        <UiModal.Footer.Buttons>
          {submitChildren}
          <UiButton type="tertiary" label="Отмена" size="large" onClick={onCancel} block />
        </UiModal.Footer.Buttons>
      </UiModal.Footer>
    </>
  );
};
