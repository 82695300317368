import { UiImage, UiTypography } from '@vkph/ui';
import React, { CSSProperties, FC } from 'react';
import { useDrag } from 'react-dnd';

import { WidgetModel } from '@vkph/common/types/models';
import { LayoutTypes } from '@vkph/common/types/populatedLayout';

import styles from '../WidgetsGallery.scss';

type Props = {
  size?: {
    width: number;
    height: number;
  };
  widget: WidgetModel;
};

const borderImageStyles: CSSProperties = { borderRadius: 0 };

export const WidgetsGallerySlide: FC<Props> = (props) => {
  const { widget, size } = props;
  const { id, attributes } = widget;
  const { name, description, fileStorageImage, types } = attributes;
  const [{ isDragging }, drag] = useDrag({
    item: { ...widget, type: types[0] || LayoutTypes.Column },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;

  return (
    <div ref={drag} style={{ opacity }} className={styles.widgetsGallery__slideWrapper}>
      <UiImage
        src={fileStorageImage?.file}
        alt={name}
        width={size?.width}
        height={size?.height}
        placeholderSize={150}
        style={borderImageStyles}
        wrapperStyle={borderImageStyles}
        className={styles.widgetsGallery__slideImage}
        wrapperClassName={styles.widgetsGallery__slideImage}
      />
      <UiTypography.Text className={styles.widgetsGallery__slideWrapperInfo}>
        {`id: ${id}`}
        <br />
        {`Имя: ${description || name}`}
        <br />
        {`Тип: ${types[0]}`}
      </UiTypography.Text>
    </div>
  );
};
