import { RouterLink } from '@vkph/components';
import { UiAvatar, UiAvatarSize, UiButton, UiCol, UiIcon, UiRow } from '@vkph/ui';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCurrentProfile } from '@vkph/common/providers/current-profile';
import { useSettingsConfig } from '@vkph/common/providers/settings-config';
import { getRoutePath, RouteNames } from '@vkph/common/utils';
import { variables } from '@vkph/ui/providers/theme';
import SearchSvg from '@vkph/ui/svg/search.svg';

import { HeaderActions } from '../actions';
import { HeaderEventsButton } from '../events-button';
import { HeaderLogo } from '../logo';
import { HeaderNotificationsButton } from '../notifications-button';

export const HeaderMobile: FC = () => {
  const navigate = useNavigate();
  const profile = useCurrentProfile();
  const { config } = useSettingsConfig();
  const isEventsButtonEnabled = !config.layouts?.headerEventsMeetingsDropdownButtonDisabled?.value;

  return (
    <UiCol flex="auto">
      <UiRow justify="space-between" align="middle" wrap={false} style={{ marginLeft: 6 }} gutter={[8, 0]}>
        <UiCol>
          <HeaderLogo />
        </UiCol>
        <UiCol>
          <UiRow align="middle" wrap={false} gutter={[12, 0]}>
            {isEventsButtonEnabled && (
              <UiCol>
                <HeaderEventsButton type="link" size="small" />
              </UiCol>
            )}
            <UiCol>
              <UiButton
                type="link"
                size="small"
                round
                icon={
                  <UiIcon component={SearchSvg} width={20} height={20} color={variables.themePalette.white} />
                }
                onClick={() => navigate(getRoutePath(RouteNames.AdvancedSearch))}
              />
            </UiCol>
            <UiCol>
              <HeaderNotificationsButton type="link" size="small" />
            </UiCol>
            <UiCol>
              <RouterLink to={getRoutePath(RouteNames.Profile, { id: profile.id })}>
                <UiAvatar size={UiAvatarSize.S} src={profile?.smallAvatar} bordered />
              </RouterLink>
            </UiCol>
            <UiCol>
              <HeaderActions />
            </UiCol>
          </UiRow>
        </UiCol>
      </UiRow>
    </UiCol>
  );
};
