import { UiCard, UiCol, UiRow, UiSkeleton, UiSpace, useBreakpoint } from '@vkph/ui';
import React, { FC } from 'react';

import { useSpace } from '@vkph/ui/hooks';

export const NewsPostSkeleton: FC = () => {
  const breakpoints = useBreakpoint();
  const { spaceM, spaceXL, spaceS } = useSpace();

  return (
    <UiCard size="default" bodyStyle={{ padding: breakpoints.lg ? spaceXL : spaceM }}>
      <UiCard.Content>
        <UiRow gutter={[spaceXL, spaceM]}>
          <UiCol span={24} lg={10}>
            <UiSkeleton loading width="100%" height={180} />
          </UiCol>
          <UiCol span={24} lg={14}>
            <UiSpace size="middle" direction="vertical" full>
              <UiSpace size={spaceS} direction="vertical" full>
                <UiSkeleton loading width={190} height={20} />
                <UiSpace size="small" direction="vertical" full>
                  <UiSkeleton loading width="100%" height={20} />
                  <UiSkeleton loading width="100%" height={80} />
                </UiSpace>
              </UiSpace>
              <UiSkeleton loading width={100} height={20} />
            </UiSpace>
          </UiCol>
        </UiRow>
      </UiCard.Content>
    </UiCard>
  );
};
