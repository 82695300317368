import {
  UiButton,
  UiCheckbox,
  UiDrawer,
  UiIcon,
  UiSelect,
  UiSkeleton,
  UiSwiper,
  UiTypography,
} from '@vkph/ui';
import classNames from 'classnames';
import { useStore } from 'effector-react';
import React, { FC, useEffect, useMemo, useState } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { widgetListStorage, widgetTypesStorage } from '@vkph/common/store/pages';
import { populatedLayoutStorage } from '@vkph/common/store/populated-layout';
import { LayoutTypes } from '@vkph/common/types';
import { createArrayMock, placementAlignMap } from '@vkph/common/utils';
import ArrowLeft from '@vkph/ui/svg/arrow-left.svg';
import ArrowRight from '@vkph/ui/svg/arrow-right.svg';

import styles from './WidgetsGallery.scss';
import { WidgetsGallerySlide } from './slide/WidgetsGallerySlide';

const MOCK_WIDTH = 240;
const SPACE_BETWEEN = 20;
const MOCK_COUNT = Math.round(window.innerWidth / (MOCK_WIDTH + SPACE_BETWEEN));
const widgetsListMocks = createArrayMock(MOCK_COUNT, (_, key) => (
  <UiSkeleton style={{ marginRight: SPACE_BETWEEN }} key={key} loading width={MOCK_WIDTH} height={150} />
));
const swiperButton = 'swiper-button';
const navigation = {
  nextEl: `.${styles.widgetsGallery__swiperButton_next}`,
  prevEl: `.${styles.widgetsGallery__swiperButton_prev}`,
};

const widgetTypesOptionsStorage = widgetTypesStorage.storage.store.map(({ data }) =>
  data.map((widgetType) => ({ label: widgetType, value: widgetType })),
);

export type WidgetsGalleryProps = {
  isSuperAdmin: boolean;
};

export const WidgetsGallery: FC<WidgetsGalleryProps> = (props) => {
  const { isSuperAdmin } = props;
  const [selectedType, setSelectedType] = useState<LayoutTypes>();
  const {
    editPopulatedLayoutState,
    cancelLayoutEditEvent,
    saveLayoutEditEffect,
    setIsSuperAdminPopulateEvent,
  } = populatedLayoutStorage;
  const { isEdit, isSuperAdminPopulateWidgets } = useStore(editPopulatedLayoutState);

  useEffect(() => {
    if (isEdit) {
      setSelectedType(undefined);
    }
  }, [isEdit]);

  const onSelectType = (type: string) => {
    setSelectedType(type as LayoutTypes);
  };

  const { data: widgetsListData, loading } = useAbstractStorage(widgetListStorage.storage, {
    autoFetchAndRefetch: isEdit,
    autoFetchParams: selectedType && { type: [selectedType] },
    cancelPendingRequestOnUnmount: true,
  });

  useAbstractStorage(widgetTypesStorage.storage, {
    autoFetchAndRefetch: isEdit,
    cancelPendingRequestOnUnmount: true,
  });

  const widgetTypesOptions = useStore(widgetTypesOptionsStorage);

  const widgetsSlides = useMemo(() => {
    return !loading && Array.isArray(widgetsListData?.items)
      ? widgetsListData?.items?.map((widget) => <WidgetsGallerySlide key={widget.id} widget={widget} />)
      : undefined;
  }, [widgetsListData, loading]);

  const slideClassName = useMemo(
    () => classNames(styles.widgetsGallery__slide, { [styles.widgetsGallery__slide_loading]: loading }),
    [loading],
  );

  const isWidgetsSlidesEmpty = !widgetsSlides || (widgetsSlides && widgetsSlides?.length === 0);

  const swiperParams = {
    navigation,
    slidesPerView: MOCK_COUNT,
    slideClassName,
    mousewheel: true,
    simulateTouch: false,
    spaceBetween: SPACE_BETWEEN,
  };

  const title = (
    <>
      <UiTypography.Text className={styles.widgetsGallery__headerType}>
        Тип
        <UiSelect
          type="text"
          bordered={false}
          defaultValue=""
          value={selectedType as string}
          onSelect={onSelectType}
          options={widgetTypesOptions}
          dropdownMatchSelectWidth={false}
          dropdownAlign={placementAlignMap.top}
        />
        {isSuperAdmin && (
          <UiCheckbox
            className={styles.widgetsGallery__checkBox}
            checked={isSuperAdminPopulateWidgets}
            onChange={() => setIsSuperAdminPopulateEvent()}
          >
            Задать для всех
          </UiCheckbox>
        )}
      </UiTypography.Text>
      {!loading && !isWidgetsSlidesEmpty && (
        <UiTypography.Text className={styles.widgetsGallery__headerTitle}>
          Начните перетаскивать виджет
        </UiTypography.Text>
      )}

      <span className={styles.widgetsGallery__headerControls}>
        <UiButton size="large" type="tertiary" label="Отменить" onClick={() => cancelLayoutEditEvent()} />
        <UiButton
          size="large"
          type="primary"
          label="Сохранить"
          onClick={() => saveLayoutEditEffect(isSuperAdmin)}
        />
      </span>
    </>
  );

  return (
    <UiDrawer
      destroyOnClose
      open={isEdit}
      mask={false}
      title={title}
      placement="bottom"
      height={280}
      className={styles.widgetsGallery}
    >
      <div className={styles.widgetsGallery__slider}>
        {!isWidgetsSlidesEmpty && (
          <UiButton
            size="large"
            type="primary"
            disabled={loading || isWidgetsSlidesEmpty}
            className={classNames(
              swiperButton,
              styles.widgetsGallery__swiperButton,
              styles.widgetsGallery__swiperButton_prev,
            )}
          >
            <UiIcon component={ArrowLeft} width={20} height={20} />
          </UiButton>
        )}

        {!isWidgetsSlidesEmpty && (
          <UiSwiper {...swiperParams} className={styles.widgetsGallery__swiper}>
            {widgetsSlides}
          </UiSwiper>
        )}

        {loading && widgetsListMocks}

        {!loading && isWidgetsSlidesEmpty && (
          <div className={styles.widgetsGallery__listEmpty}>
            Нет виджетов
            {Boolean(selectedType) && ` с типом`}
            &nbsp;{selectedType}
          </div>
        )}

        {!isWidgetsSlidesEmpty && (
          <UiButton
            size="large"
            type="primary"
            disabled={loading || isWidgetsSlidesEmpty}
            className={classNames(
              swiperButton,
              styles.widgetsGallery__swiperButton,
              styles.widgetsGallery__swiperButton_next,
            )}
          >
            <UiIcon component={ArrowRight} width={20} height={20} />
          </UiButton>
        )}
      </div>
    </UiDrawer>
  );
};
