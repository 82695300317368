import { PostAttachmentTypes } from '@vkph/common/types/models';
import EventIcon from '@vkph/ui/svg/event.svg';
import FileIcon from '@vkph/ui/svg/file.svg';
import PictureIcon from '@vkph/ui/svg/picture.svg';
import PollIcon from '@vkph/ui/svg/poll.svg';

type AttachmentActionParams = {
  icon: SvgrComponent;
  label: string;
};

export const attachmentActionFields: Record<PostAttachmentTypes, AttachmentActionParams> = {
  [PostAttachmentTypes.File]: {
    icon: FileIcon,
    label: 'Документ',
  },
  [PostAttachmentTypes.Image]: {
    icon: PictureIcon,
    label: 'Изображение',
  },
  [PostAttachmentTypes.Poll]: {
    icon: PollIcon,
    label: 'Мини-опрос',
  },
  [PostAttachmentTypes.Event]: {
    icon: EventIcon,
    label: 'Мероприятие',
  },
};

export const singleAttachmentTypes = new Set<PostAttachmentTypes>([
  PostAttachmentTypes.Poll,
  PostAttachmentTypes.Event,
]);
