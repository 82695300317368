import React, { FC } from 'react';

import {
  CMSPageModel,
  NotificationCmsFeedbackModel,
  NotificationCmsPageCommentModel,
  NotificationModel,
  NotificationTypes,
} from '@vkph/common/types/models';

import { isNotificationSomeOfTypes } from '../../../../types/guards/notification-guards';
import { NotificationCmsComment } from './comment/NotificationCmsComment';
import { NotificationCmsFeedback } from './feedback/NotificationCmsFeedback';
import { NotificationCmsMention } from './mention/NotificationCmsMention';

interface Props {
  notification: NotificationModel;
}

export const NotificationCms: FC<Props> = (props) => {
  const { notification } = props;

  if (isNotificationSomeOfTypes<CMSPageModel>(notification, [NotificationTypes.CmsPageUserMentioned])) {
    return <NotificationCmsMention notification={notification} />;
  }

  if (
    isNotificationSomeOfTypes<NotificationCmsFeedbackModel>(notification, [
      NotificationTypes.CmsFeedbackCreated,
    ])
  ) {
    return <NotificationCmsFeedback notification={notification} />;
  }

  if (
    isNotificationSomeOfTypes<NotificationCmsPageCommentModel>(notification, [
      NotificationTypes.CmsPageCommentCreated,
      NotificationTypes.CmsPageCommentUserMentioned,
      NotificationTypes.CmsPageCommentReactionCreated,
      NotificationTypes.CmsPageCommentReply,
    ])
  ) {
    return <NotificationCmsComment notification={notification} />;
  }

  return null;
};
