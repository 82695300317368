// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._613ad959_add-edit-file-folder-modal__content{padding:0 24px}", "",{"version":3,"sources":["webpack://./../../libs/modules/src/components/add-edit-folder-modal/AddEditFileFolderModal.scss"],"names":[],"mappings":"AAEE,+CACE,cAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.add-edit-file-folder-modal {\n  &__content {\n    padding: 0 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"add-edit-file-folder-modal__content": "_613ad959_add-edit-file-folder-modal__content",
	"addEditFileFolderModal__content": "_613ad959_add-edit-file-folder-modal__content"
};
export default ___CSS_LOADER_EXPORT___;
