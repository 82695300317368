import { UiTooltip, UiTruncateMarkup, UiTruncateMarkupProps } from '@vkph/ui';
import React, { FC, useState } from 'react';

import { NavigationItem } from '@vkph/modules/types/navigation';

import { MenuLinkItem } from '../link-item/MenuLinkItem';

export type MenuLinkTruncatedProps = NavigationItem & Pick<UiTruncateMarkupProps, 'className'>;

export const MenuLinkTruncated: FC<MenuLinkTruncatedProps> = (props) => {
  const { label, to, blank, className } = props;
  const [isTruncated, setIsTruncated] = useState(false);

  const menuLinkTruncated = (
    <MenuLinkItem to={to} target={blank ? '_blank' : '_self'}>
      <UiTruncateMarkup truncate onTruncate={setIsTruncated} className={className}>
        {label}
      </UiTruncateMarkup>
    </MenuLinkItem>
  );

  return isTruncated ? (
    <UiTooltip title={label} placement="right">
      {menuLinkTruncated}
    </UiTooltip>
  ) : (
    menuLinkTruncated
  );
};
