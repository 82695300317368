import { UiButton, UiCard, UiEmpty, UiSkeleton, UiTypography } from '@vkph/ui';
import format from 'date-fns/format';
import React, { useMemo } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { createArrayMock } from '@vkph/common/utils';
import GearSvg from '@vkph/ui/svg/gear.svg';

import { getCurrencyRatesStorage } from '../../store/currency';
import styles from './Currency.scss';
import { CurrencyItem } from './item/CurrencyItem';

const SHOW_SETTINGS_ICON = false; // TODO Раздел в разработке
const MOCKS_COUNT = 3;
const currencyMocks = createArrayMock(MOCKS_COUNT, (_, id) => (
  <span key={id} className={styles.currency__skeleton}>
    <UiSkeleton circle loading width={40} height={40} />
    <UiSkeleton loading height={18} width={130} />
    <UiSkeleton loading height={18} width={50} />
  </span>
));

export const Currency = () => {
  const currencyRatesStorage = useMemo(() => getCurrencyRatesStorage(), []);
  const { data: currencyRatesData, loading: isCurrencyRatesLoading } = useAbstractStorage(
    currencyRatesStorage.storage,
    {
      autoFetchAndRefetch: true,
      resetStoreOnUnmount: true,
    },
  );
  const date = currencyRatesData?.date && format(new Date(currencyRatesData?.date), 'dd.MM');
  const isCurrencyRatesData = !isCurrencyRatesLoading && currencyRatesData?.exchangeRates?.length;

  const Title = (
    <UiTypography.Title level={3} style={{ marginBottom: 8 }} className={styles.currency__header}>
      Акции
      {SHOW_SETTINGS_ICON && (
        <UiButton
          type="link"
          disabled={isCurrencyRatesLoading}
          className={styles.currency__headerIcon}
          icon={<GearSvg width={20} height={20} />}
        />
      )}
    </UiTypography.Title>
  );

  return (
    <UiCard title={Title}>
      {isCurrencyRatesLoading && currencyMocks}
      {isCurrencyRatesData &&
        currencyRatesData.exchangeRates.map((currencyProps) => (
          <CurrencyItem key={currencyProps.currencyCode} date={date} currency={currencyProps} />
        ))}
      {!isCurrencyRatesData && <UiEmpty.Feed emptyMessage={{ header: 'Данные об акциях отсутствуют' }} />}
    </UiCard>
  );
};
