import { message } from '@vkph/ui';
import { WidgetUserModel } from '@vkph/widget-sdk';
import {
  AuthTokenResponse,
  VKPHWidgetSDKDispatcher,
  WidgetUuid,
  sendMessageToWidget,
} from '@vkph/widget-sdk-dispatcher';
import classNames from 'classnames';
import { useStore } from 'effector-react';
import React, { ComponentType, FC, HTMLAttributes, PropsWithChildren, useEffect, useMemo } from 'react';
import { useDrop, DragObjectWithType } from 'react-dnd';

import { themeStorage } from '@vkph/common/store/theme';
import { getAuthTokenEffect, getCurrentUserEffect } from '@vkph/common/store/widgets';
import { WidgetModel } from '@vkph/common/types/models';
import { LayoutItem } from '@vkph/common/types/populatedLayout';
import { getErrorResponseMessage } from '@vkph/common/utils';

import { CreateWidgetComponentInternal } from './internal';
import { WidgetsOverlay, WidgetsOverlayProps } from './overlay/WidgetsOverlay';

interface WidgetModuleProps<ModuleProps> {
  modulePath: string;
  moduleProps?: ModuleProps;
  moduleEnvironment?: Record<string, string>;
}

interface LayoutsWidgetsProps<ModuleProps>
  extends WidgetsOverlayProps,
    Pick<HTMLAttributes<HTMLDivElement>, 'style'> {
  isLayoutsEdit?: boolean;
  moduleProps?: ModuleProps;
  onDropHandler: (widgetItem: WidgetModel, layoutItem: LayoutItem) => void;
}

export type DynamicRenderComponentType<ModuleProps = unknown> = FC<
  PropsWithChildren<WidgetModuleProps<ModuleProps>>
>;

export interface CreateWidgetComponentParams<ModuleProps = unknown> {
  DynamicRenderComponent?: DynamicRenderComponentType<ModuleProps> | undefined;
  moduleEnvironment?: Record<string, string>;
}

type CollectedDropProps = {
  isOver: boolean;
  canDrop: boolean;
};

type DragLayoutItem = DragObjectWithType & WidgetModel;

const widgetSDKDispatcher = new VKPHWidgetSDKDispatcher();

widgetSDKDispatcher.init();

export const createWidgetComponent = <ModuleProps,>(
  createWidgetComponentParams: CreateWidgetComponentParams<ModuleProps> = {},
): ComponentType<PropsWithChildren<LayoutsWidgetsProps<ModuleProps>>> => {
  const { DynamicRenderComponent, moduleEnvironment } = createWidgetComponentParams;

  return (props) => {
    const {
      isLayoutsEdit,
      children = null,
      className,
      style,
      layoutItem,
      widget,
      moduleProps,
      onDeactivateWidgetClick,
      isShowDeactivate,
      isDefaultWidget,
      onDropHandler,
    } = props;
    const { attributes } = widget || {};
    const { path, isExternal } = attributes || {};
    const widgetNameInQuotes = widget?.attributes?.name ? `"${widget?.attributes?.name}"` : '';
    const isIframeView = typeof path === 'string' && isExternal;
    const isModuleView = typeof path === 'string' && !isExternal;
    const isDefaultPlugView = isIframeView && isModuleView;
    const { colors: themeColorsData } = useStore(themeStorage.themeState);

    const isCurrentWidget = (uuid: WidgetUuid) => widget?.uuid === uuid;

    const onDrop = ({ type, ...widgetItem }: DragLayoutItem) => {
      if (onDropHandler) {
        onDropHandler(widgetItem, layoutItem);
      }
    };

    const [{ canDrop, isOver }, drop] = useDrop<DragLayoutItem, void, CollectedDropProps>({
      accept: isShowDeactivate || !widget ? layoutItem.types : '',
      drop: onDrop,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    });

    const styles = useMemo(
      () =>
        isLayoutsEdit && !widget
          ? {
              height: layoutItem.height,
              ...style,
            }
          : style,
      [widget, layoutItem, style, isLayoutsEdit],
    );

    const classNamesStyles = useMemo(
      () =>
        classNames(
          'layouts-widgets',
          {
            'layouts-widgets_setting-ready': isLayoutsEdit,
          },
          className,
        ),
      [isLayoutsEdit, className],
    );

    const onLoadWidget = (widgetUuid: WidgetUuid) => {
      sendMessageToWidget({ widgetUuid, messageType: 'colorScheme', payload: themeColorsData });
    };

    useEffect(() => {
      // TODO: B2BCORE-10275 Заменить общую подписку на индивидуальные с моделями данных
      return widgetSDKDispatcher.on<AuthTokenResponse>('authToken', async (data) => {
        const { widgetUuid } = data;

        if (isCurrentWidget(widgetUuid)) {
          try {
            const { accessToken, expiresIn } = await getAuthTokenEffect({ widgetUuid });

            sendMessageToWidget<AuthTokenResponse>({
              ...data,
              payload: { accessToken, expiresIn },
            });
          } catch (e) {
            message.error(
              getErrorResponseMessage(
                e,
                `Не удалось получить Токен авторизации для виджета ${widgetNameInQuotes}`,
              ),
            );
          }
        }
      });
    }, [widget?.uuid, widgetNameInQuotes]);

    useEffect(() => {
      // TODO: B2BCORE-10275 Заменить общую подписку на индивидуальные с моделями данных
      return widgetSDKDispatcher.on<WidgetUserModel>('currentUser', async (data) => {
        const { widgetUuid } = data;

        if (isCurrentWidget(widgetUuid)) {
          try {
            // TODO: B2BCORE-10273 Кэширование токенов на уровне WidgetSDKDispatcher
            const { accessToken } = await getAuthTokenEffect({ widgetUuid });
            const profileData = await getCurrentUserEffect({ widgetAccessToken: accessToken });

            sendMessageToWidget<WidgetUserModel>({
              ...data,
              payload: profileData,
            });
          } catch (e) {
            message.error(
              getErrorResponseMessage(
                e,
                `Не удалось получить данные текущего пользователя для виджета ${widgetNameInQuotes}`,
              ),
            );
          }
        }
      });
    }, [widget?.uuid]);

    return (
      <div ref={drop} style={styles} className={classNamesStyles}>
        {isIframeView && widget && <CreateWidgetComponentInternal widget={widget} onLoad={onLoadWidget} />}
        {isModuleView && typeof DynamicRenderComponent === 'function' && (
          <DynamicRenderComponent
            modulePath={path as string}
            moduleProps={moduleProps}
            moduleEnvironment={moduleEnvironment}
          />
        )}
        {isDefaultPlugView && (
          <div className="layouts-widgets__empty-content-container">
            <span>Widget no set</span>
          </div>
        )}
        {children}
        <WidgetsOverlay
          {...props}
          className={classNames('layouts-widgets__overlay', {
            'layouts-widgets__overlay-drop': canDrop && isOver,
            'layouts-widgets__overlay-default-widget': isDefaultWidget,
            'layouts-widgets__overlay-available-drop': canDrop,
            // 'widgets-overlay__buttons-disabled': !isIframeView, // TODO Вспомнить почему мы отключаем только isIframeView
          })}
          onDeactivateWidgetClick={onDeactivateWidgetClick}
          isShowDeactivate={isShowDeactivate}
        />
      </div>
    );
  };
};
