import React, { memo } from 'react';

import { getMatchedStringIndexes } from './helpers';

interface Props {
  searchString?: string;
  isOneMatch?: boolean;
  children: string | undefined;
}

const UnderlinedSearchStringComponent: React.FC<Props> = (props) => {
  const { searchString = '', children: originalString = '', isOneMatch } = props;

  const trimmedSearchStr = searchString.trim();

  if (!trimmedSearchStr || !originalString) {
    return <>{originalString}</>;
  }

  const matchedIndexes = getMatchedStringIndexes(originalString, trimmedSearchStr, isOneMatch);
  const searchStrLength = trimmedSearchStr.length;

  const result: (JSX.Element | string)[] = [];

  if (matchedIndexes.length > 0) {
    let pointer = 0;

    const spanUnderlineStyle = {
      textDecoration: 'underline',
    };

    matchedIndexes.forEach((searchStrIdx, arrIdx) => {
      const nextPointer = searchStrIdx + searchStrLength;

      result.push(
        originalString.slice(pointer, searchStrIdx),
        <span key={pointer} style={spanUnderlineStyle}>
          {originalString.slice(searchStrIdx, nextPointer)}
        </span>,
      );

      pointer = nextPointer;

      if (arrIdx === matchedIndexes.length - 1) {
        result.push(originalString.slice(pointer, originalString.length));
      }
    });
  } else {
    result.push(originalString);
  }

  return <>{result}</>;
};

export const UnderlinedSearchString = memo(UnderlinedSearchStringComponent);
