import { UiCheckbox, UiCheckboxValueType, UiRadio, UiRadioChangeEvent } from '@vkph/ui';
import React, { FC, PropsWithChildren, useState, useEffect } from 'react';

import { PollQuestionModel, PollQuestionTypes, VoteModel } from '@vkph/common/types/models';

import { ValueChange } from '../PollAnswers';

type Props = {
  question: PollQuestionModel;
  onChange: ValueChange;
  answers: VoteModel[];
  className: string;
};

export const GroupAnswersWrapper: FC<PropsWithChildren<Props>> = (props) => {
  const { children, onChange, question, answers, className } = props;
  const { type } = question;
  const [values, setValues] = useState<number[] | number>();

  useEffect(() => {
    const answersFiltered = answers.filter((el) => el.option);

    if (type === PollQuestionTypes.SINGLE_CHOICE && answersFiltered.length === 1) {
      setValues(answersFiltered[0].option);
    }

    if (type === PollQuestionTypes.MULTIPLE_CHOICE) {
      setValues(answersFiltered.map((el) => Number(el.option)));
    }
  }, [question]);

  const onValueChange = (vals: number[]) => {
    const newValues = vals.map((el) => {
      return {
        question: question.id,
        option: el,
      };
    });

    onChange(newValues, question.id);
  };

  const onRadioChange = ({ target: { value } }: UiRadioChangeEvent) => {
    setValues(value);
    onValueChange([value]);
  };

  const onCheckboxChange = (valuesList: UiCheckboxValueType[]) => {
    const checkBoxValues = valuesList.map((val) => Number(val));

    setValues(checkBoxValues);
    onValueChange(checkBoxValues);
  };

  if (type === PollQuestionTypes.SINGLE_CHOICE) {
    return (
      <UiRadio.Group onChange={onRadioChange} value={values} className={className}>
        {children}
      </UiRadio.Group>
    );
  }

  if (type === PollQuestionTypes.MULTIPLE_CHOICE && Array.isArray(values)) {
    return (
      <UiCheckbox.Group onChange={onCheckboxChange} value={values} className={className}>
        {children}
      </UiCheckbox.Group>
    );
  }

  return <>{children}</>;
};
