import { isBlogPost, isGamificationPost, isNewsPost, isProfileEntry } from '@vkph/common/types/guards';
import { CommentContentTypes, PostModel } from '@vkph/common/types/models';

export const getCommentsContentTypeByPost = (post: PostModel) => {
  if (isNewsPost(post)) {
    return CommentContentTypes.NewsEntry;
  }

  if (isGamificationPost(post)) {
    return CommentContentTypes.Gamification;
  }

  if (isProfileEntry(post)) {
    return CommentContentTypes.ProfileEntry;
  }

  return isBlogPost(post) ? CommentContentTypes.Post : CommentContentTypes.Micropost;
};
