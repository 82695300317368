import React, { FC } from 'react';

import {
  ProjectsTasksModel,
  NotificationModel,
  NotificationTaskCommentModel,
  NotificationTypes,
} from '@vkph/common/types/models';

import { isNotificationSomeOfTypes } from '../../../../types/guards/notification-guards';
import { NotificationTasksComment } from './comment/NotificationTasksComment';
import { NotificationTasksDefault } from './default/NotificationTasksDefault';

interface Props {
  notification: NotificationModel;
}

export const NotificationTasks: FC<Props> = (props) => {
  const { notification } = props;

  if (
    isNotificationSomeOfTypes<ProjectsTasksModel>(notification, [
      NotificationTypes.TasksCompleted,
      NotificationTypes.TasksAssignee,
      NotificationTypes.TasksChangeAttributes,
    ])
  ) {
    return <NotificationTasksDefault notification={notification} />;
  }

  if (
    isNotificationSomeOfTypes<NotificationTaskCommentModel>(notification, [
      NotificationTypes.TaskCommentReactionCreated,
    ])
  ) {
    return <NotificationTasksComment notification={notification} />;
  }

  return null;
};
