import {
  UiForm,
  UiFormItemProps,
  UiCheckbox,
  UiCheckboxChangeEvent,
  DraggablePropsStyles,
  UiDraggable,
  UiIcon,
} from '@vkph/ui';
import classNames from 'classnames';
import React, { useMemo } from 'react';

import { getPathnameWithoutParams } from '@vkph/common/utils';
import { NavigationItem } from '@vkph/modules/types/navigation';
import GrabberSvg from '@vkph/ui/svg/grabber.svg';

import { MenuLinkTruncated } from '../../menu/link-truncated/MenuLinkTruncated';
import styles from './NavigationFavoritesItem.scss';

const $shadow8px = '0 4px 8px rgba(46, 48, 51, 0.08)';

export type CheckboxChangeEventHandlerCustom = (e: UiCheckboxChangeEvent, name: string | undefined) => void;

interface Props extends UiFormItemProps<NavigationItem> {
  index: number;
  selectedKeys: string[];
  isFavoritesEdit: boolean;
  isSuccessEditLoading: boolean;
  onCheckboxFavoritesChange: CheckboxChangeEventHandlerCustom;
}

const getItemStyle = (isDragging: boolean, draggableStyle: DraggablePropsStyles): React.CSSProperties => ({
  userSelect: 'none',
  boxShadow: isDragging ? $shadow8px : 'none',
  ...draggableStyle,
});

export const NavigationFavoritesItem: React.FC<Props> = (props) => {
  const { value, index, selectedKeys, isFavoritesEdit, isSuccessEditLoading, onCheckboxFavoritesChange } =
    props;

  const isSelected = useMemo(() => {
    if (value?.to) {
      return selectedKeys.includes(getPathnameWithoutParams(value.to));
    }

    return false;
  }, [selectedKeys, value]);

  const onChange = (name: string | undefined) => (e: UiCheckboxChangeEvent) => {
    onCheckboxFavoritesChange(e, name);
  };

  return (
    <UiDraggable isDragDisabled={!isFavoritesEdit} draggableId={String(value?.to)} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          {value && (
            <div className={styles.navigationFavoritesItem}>
              <UiForm.Item noStyle name={[index, 'checked']} valuePropName="checked">
                <UiCheckbox
                  disabled={isSuccessEditLoading}
                  onChange={onChange(value?.to)}
                  className={classNames({
                    [styles.navigationFavoritesItem__checkbox]: isFavoritesEdit,
                    [styles.navigationFavoritesItem__checkbox_hide]: !isFavoritesEdit,
                  })}
                />
              </UiForm.Item>

              <MenuLinkTruncated
                {...value}
                className={classNames(styles.navigationFavoritesItem__label, {
                  [styles.navigationFavoritesItem__label_selected]: isSelected && !isFavoritesEdit,
                })}
              />

              <UiIcon
                component={GrabberSvg}
                width={20}
                height={20}
                className={classNames(styles.navigationFavoritesItem__dragIcon, {
                  [styles.navigationFavoritesItem__dragIcon_hide]: isFavoritesEdit,
                })}
              />
            </div>
          )}
        </div>
      )}
    </UiDraggable>
  );
};
