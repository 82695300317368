import {
  UiButton,
  UiCheckbox,
  UiCheckboxChangeEvent,
  UiDivider,
  UiForm,
  UiIcon,
  UiMenu,
  UiModal,
  UiModalTypes,
  UiSpace,
  UiTruncateMarkup,
  message,
} from '@vkph/ui';
import classNames from 'classnames';
import { useStore, useStoreMap } from 'effector-react';
import flatMap from 'lodash/flatMap';
import intersection from 'lodash/intersection';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { matchPath, matchRoutes, useLocation } from 'react-router-dom';

import { useAbstractStorage, useLocalStorage } from '@vkph/common/hooks';
import {
  currentProfileRolesPermissionsMapStore,
  useCurrentProfile,
} from '@vkph/common/providers/current-profile';
import { getWidgetStorage } from '@vkph/common/store/pages';
import { WidgetId, PagesNames } from '@vkph/common/types/models';
import { getPathnameWithoutParams, routes } from '@vkph/common/utils';
import {
  NavigationItem,
  WidgetDataNavigation,
  WidgetDefaultDataNavigation,
} from '@vkph/modules/types/navigation';
import { useOnClickOutside } from '@vkph/ui/hooks';
import ArrowExpandSvg from '@vkph/ui/svg/arrow-expand.svg';
import GearSvg from '@vkph/ui/svg/gear.svg';

import { NavigationEdit } from '../edit/NavigationEdit';
import { NavigationFavorites } from '../favorites/NavigationFavorites';
import styles from './NavigationMenu.scss';
import { MenuLinkTruncated } from './link-truncated/MenuLinkTruncated';

interface FormCheckedField extends NavigationItem {
  checked: boolean;
}

type FormCheckedValues = {
  menu: Record<string, FormCheckedField>;
  favorites: FormCheckedField[];
};

export type NavigationMenuType = {
  widgetId: WidgetId;
  defaultMenu: WidgetDefaultDataNavigation[];
  favoriteMenu: NavigationItem[];
  formValues: FormCheckedValues;
};

const navigationWidgetStorage = getWidgetStorage<WidgetDataNavigation>({
  type: PagesNames.Navbar,
});
const navigationWidgetStore = navigationWidgetStorage.storage.store.map(({ data: widget }) => {
  if (!widget) return widget;

  const defaultData = Array.isArray(widget.attributes.defaultData) ? widget.attributes.defaultData : [];
  const defaultDataFlat = flatMap(defaultData, 'items');
  const defaultDataRoutesSet = new Set(defaultDataFlat.map(({ to }) => to));
  const data = (Array.isArray(widget.attributes.data) ? widget.attributes.data : []).filter(({ to }) =>
    defaultDataRoutesSet.has(to),
  );

  const dataSet = new Set(data.map(({ to }) => to));
  const formValues = {
    menu: Object.fromEntries(
      defaultDataFlat.map((item) => [item.to, { ...item, checked: dataSet.has(item.to) }]),
    ),
    favorites: data.map((item) => ({ ...item, checked: true })),
  };

  return {
    widgetId: widget.id,
    defaultMenu: defaultData,
    favoriteMenu: data,
    formValues,
  };
});

type Props = {
  className?: string;
};

export const NavigationMenu: FC<Props> = (props) => {
  const { className } = props;
  const location = useLocation();

  const navigationRef = useRef<HTMLDivElement>(null);
  const [form] = UiForm.useForm<FormCheckedValues>();
  const [openKeys, setOpenKeys] = useLocalStorage<string[]>('openedNavigationKeys', []);
  const [isEditMenuOpen, setIsEditMenuOpen] = useState(false);
  const [isFavoritesEdit, setIsFavoritesEdit] = useState(false);
  const [isFieldsChanged, setIsFieldsChanged] = useState(false);
  const { updateWidgetEffect } = navigationWidgetStorage;
  const { data: navigationWidgetData } = useStore(navigationWidgetStorage.storage.store);
  const profile = useCurrentProfile();

  const permissions = useStore(currentProfileRolesPermissionsMapStore);
  const navigationMenu: NavigationMenuType | null = useStore(navigationWidgetStore);
  const updateUserWidgetDataPending = useStore(updateWidgetEffect.pending);
  const isCurrentProfileSuperAdmin = Boolean(profile?.superAdmin);
  const isCurrentProfileAdmin = isCurrentProfileSuperAdmin || permissions.size > 0;

  const flatPermissions = useStoreMap(currentProfileRolesPermissionsMapStore, (permission) =>
    Array.from(permission).map(([value]) => value),
  );

  const getMenuItemsByPermissions = useCallback(
    <T extends NavigationItem | FormCheckedField>(sectionItems: T[]): T[] => {
      return sectionItems.filter((item) => {
        const { superAdminOnly: sectionForAdminOnly, permissions: sectionPermissions = [] } = item;

        const isSectionWithoutPermissions = !sectionPermissions.length && !sectionForAdminOnly;
        const flatSectionPermissions = sectionPermissions.map((permission) => permission.value);
        const commonUserHasPermission = Boolean(intersection(flatSectionPermissions, flatPermissions).length);
        const superAdminHasPermission = isCurrentProfileSuperAdmin && sectionForAdminOnly;

        if (isSectionWithoutPermissions || superAdminHasPermission || commonUserHasPermission) {
          return item;
        }

        return null;
      });
    },
    [permissions, isCurrentProfileSuperAdmin],
  );

  const navigation = useMemo(() => {
    if (navigationMenu) {
      return {
        ...navigationMenu,
        formValues: {
          ...navigationMenu.formValues,
          favorites: getMenuItemsByPermissions(navigationMenu.formValues.favorites),
        },
        defaultMenu: navigationMenu.defaultMenu.map(({ section, items }) => ({
          section,
          items,
        })),
      };
    }

    return navigationMenu;
  }, [navigationMenu, isCurrentProfileAdmin]);

  useAbstractStorage(navigationWidgetStorage.storage, {
    autoFetchAndRefetch: !navigation,
  });

  const onToggleEditMenuOpen = () => setIsEditMenuOpen((value) => !value);

  const navigationSectionsIdsSet = useMemo(
    () => new Set(flatMap(navigation?.defaultMenu, 'section').map((section) => section.id)),
    [navigation?.defaultMenu],
  );

  const isValidOpenKey = useCallback(
    (key: unknown) => typeof key === 'string' && navigationSectionsIdsSet.has(key),
    [navigationSectionsIdsSet],
  );

  const validatedOpenKeys = useMemo(
    () => openKeys.filter(isValidOpenKey),
    [openKeys, navigationSectionsIdsSet],
  );

  const onClickEditHandler: React.MouseEventHandler<HTMLElement> = () =>
    setIsFavoritesEdit((value) => !value);

  const onCancelEditHandler = () => {
    form.resetFields();
    setIsFavoritesEdit((value) => !value);
    setIsFieldsChanged(false);
  };

  const hideFavoritesEdit = useCallback(() => {
    if (isFavoritesEdit) {
      onCancelEditHandler();
    }
  }, [isFavoritesEdit]);

  useOnClickOutside(navigationRef, hideFavoritesEdit);

  const onSuccessEditHandler: React.MouseEventHandler<HTMLElement> = () => {
    const { widgetId } = navigation || ({} as NavigationMenuType);

    const favorites = form.getFieldValue('favorites');
    const data = favorites
      .filter((favorite: FormCheckedField) => favorite.checked)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(({ checked, ...favorite }: FormCheckedField) => favorite);

    updateWidgetEffect({
      widgetId,
      attributes: {
        ...navigationWidgetData?.attributes,
        data,
      },
      onlyUser: true,
    }).then(() => {
      message.success('Сохранено');
      setIsFavoritesEdit((value) => !value);
      setIsFieldsChanged(false);
    });
  };

  const onFieldsChange = useCallback(() => {
    setIsFieldsChanged(true);
  }, [isFieldsChanged]);

  const MenuTitle = ({ title }: { title: string }) => (
    <UiTruncateMarkup truncate tooltipProps={{ title, placement: 'right' }}>
      {title}
    </UiTruncateMarkup>
  );

  const checkboxStyles = useMemo(
    () =>
      classNames({
        [styles.navigationMenu__checkbox]: isFavoritesEdit,
        [styles.navigationMenu__checkbox_hide]: !isFavoritesEdit,
      }),
    [isFavoritesEdit],
  );

  const onCheckboxFavoritesChange = ({ target }: UiCheckboxChangeEvent, name: string | undefined) => {
    const { checked } = target;

    if (name) {
      const namePath = ['menu', name];
      const value = { ...form.getFieldValue(namePath), checked };

      form.setFields([{ name: namePath, value }]);
    }
  };

  const onCheckboxMenuChange = ({ target }: UiCheckboxChangeEvent) => {
    const { name, checked } = target;
    const favorites = form.getFieldValue('favorites');
    const favoriteIndex = favorites.findIndex((favorite: FormCheckedField) => favorite.to === name);

    if (!name) {
      return;
    }

    if (favoriteIndex >= 0) {
      const namePath = ['favorites', favoriteIndex];
      const value = { ...form.getFieldValue(namePath), checked };

      form.setFields([{ name: namePath, value }]);
    } else if (checked) {
      form.setFieldsValue({ favorites: [...favorites, { ...form.getFieldValue(['menu', name]), checked }] });
    }
  };

  const navigationMenuClassName = useMemo(
    () =>
      classNames(
        styles.navigationMenu,
        { [styles.navigationMenu__scrollbar_hide]: isFavoritesEdit },
        className,
      ),
    [className, isFavoritesEdit],
  );

  const initialValues = useMemo(() => {
    let formValues = {};

    if (navigation?.defaultMenu?.length) {
      formValues = {
        favorites: [...navigation.formValues.favorites],
        menu: { ...navigation.formValues.menu },
      };
    }

    return formValues;
  }, [navigation]);

  useEffect(() => {
    if (navigation?.defaultMenu?.length) {
      form.setFieldsValue(initialValues);
    }
  }, [navigation, openKeys]);

  const selectedKeys = useMemo<string[]>(() => {
    const navigationPathnameMenu = navigation?.formValues?.menu;
    let key = navigationPathnameMenu?.[location.pathname]?.to;

    if (!key && navigationPathnameMenu) {
      const routesMap = Object.values(routes).map((route) => ({ path: route }));
      const matches = matchRoutes(routesMap, location.pathname);

      if (matches) {
        const [{ route }] = matches;
        const menuItems = Object.values(navigationPathnameMenu);
        const foundItem = menuItems.find((item) => matchPath(route.path, item.to));

        key = foundItem?.to;
      }
    }

    return key ? [key] : [];
  }, [location.pathname, navigation]);

  const renderNavigationMenuItem = ({ label, to, blank }: NavigationItem) => {
    return {
      label: (
        <UiSpace>
          <UiForm.Item noStyle name={['menu', to, 'checked']} valuePropName="checked" shouldUpdate>
            <UiCheckbox
              name={to}
              disabled={updateUserWidgetDataPending}
              className={checkboxStyles}
              onChange={onCheckboxMenuChange}
            />
          </UiForm.Item>
          {isFavoritesEdit && <UiTruncateMarkup truncate>{label}</UiTruncateMarkup>}
          {!isFavoritesEdit && <MenuLinkTruncated to={to} label={label} blank={blank} />}
        </UiSpace>
      ),
      key: getPathnameWithoutParams(to),
    };
  };

  const menuItems = useMemo(() => {
    const navigationItems = navigation?.defaultMenu?.map(({ section, items }) => {
      const currentMenuItems = getMenuItemsByPermissions(items);

      return {
        label: <MenuTitle title={section.name} />,
        key: section.id,
        icon: <UiIcon component={ArrowExpandSvg} width={20} height={20} />,
        children: currentMenuItems.map(renderNavigationMenuItem),
      };
    });

    return navigationItems?.filter((navigationItem) => navigationItem.children.length > 0);
  }, [navigation?.defaultMenu, isFavoritesEdit, permissions, renderNavigationMenuItem]);

  return (
    <div ref={navigationRef} className={navigationMenuClassName}>
      <UiForm form={form} name="favorites-form" initialValues={initialValues} onFieldsChange={onFieldsChange}>
        <NavigationFavorites
          selectedKeys={selectedKeys}
          isFavoritesEdit={isFavoritesEdit}
          isFavoritesEmpty={!navigation?.favoriteMenu?.length}
          isSuccessEditDisabled={!isFieldsChanged}
          isSuccessEditLoading={updateUserWidgetDataPending}
          onClickEditHandler={onClickEditHandler}
          onCancelEditHandler={onCancelEditHandler}
          onSuccessEditHandler={onSuccessEditHandler}
          onCheckboxFavoritesChange={onCheckboxFavoritesChange}
        />
        <UiDivider />
        <UiMenu
          mode="inline"
          inlineIndent={20}
          openKeys={validatedOpenKeys}
          selectedKeys={selectedKeys}
          triggerSubMenuAction="click"
          expandIcon={() => null}
          onOpenChange={setOpenKeys}
          items={menuItems}
        />

        {profile?.superAdmin && (
          <div className={styles.navigationMenu__editMenu}>
            <UiButton
              disabledFocus
              icon={<UiIcon component={GearSvg} width={20} height={20} />}
              className={styles.navigationMenu__editMenuBtn}
              onClick={onToggleEditMenuOpen}
            >
              Настроить меню
            </UiButton>

            <UiModal type={UiModalTypes.Medium} isOpen={isEditMenuOpen} onClose={onToggleEditMenuOpen}>
              {navigation && (
                <NavigationEdit
                  navigation={navigation}
                  onClose={onToggleEditMenuOpen}
                  navigationWidgetStorage={navigationWidgetStorage}
                />
              )}
            </UiModal>
          </div>
        )}
      </UiForm>
    </div>
  );
};
