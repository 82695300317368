const specialSymbols = /[*?]/;

export const getMatchedStringIndexes = (str: string, search: string, isOneMatch?: boolean) => {
  const indexMatches: number[] = [];
  const toMatch = search.replace(specialSymbols, '');

  if (!toMatch.length) {
    return indexMatches;
  }

  const reg = new RegExp(toMatch, 'gi');

  let match: RegExpExecArray | null = reg.exec(str);

  while (match) {
    indexMatches.push(match.index);
    match = isOneMatch ? null : reg.exec(str);
  }

  return indexMatches;
};
