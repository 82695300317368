import { ErrorBoundary } from '@vkph/components';
import { UiButton, UiDrawer, UiRow, useBreakpoint } from '@vkph/ui';
import { AxiosError } from 'axios';
import React, { CSSProperties, FC, PropsWithChildren } from 'react';

import { NotificationsInfo } from '@vkph/common/store/notifications';
import { markAllNotificationsRead } from '@vkph/common/store/notifications/api';
import { NotificationContent, NotificationFilters, NotificationModel } from '@vkph/common/types/models';
import { createArrayMock } from '@vkph/common/utils';
import { useSpace } from '@vkph/ui/hooks';
import { useTheme, variables } from '@vkph/ui/providers/theme';

import styles from './NotificationsDrawer.scss';
import { DrawerClose } from './close/DrawerClose';
import { FeedError } from './feed-error/FeedError';
import { NotificationLoading } from './loading/NotificationLoading';
import { NotificationCard } from './notification-card/NotificationCard';
import { DrawerPopover } from './popover/DrawerPopover';
import { DrawerTitle } from './title/DrawerTitle';

const MOCKS_LENGTH = 5;
const notificationsFeedMocks = createArrayMock(MOCKS_LENGTH, (_, key) => <NotificationLoading key={key} />);

type Props = {
  visible: boolean;
  onClose: () => void;
  loading: boolean;
  notifications: NotificationModel<NotificationContent>[];
  drawerTitle: string;
  // notificationTitleType: NotificationTitleTypes;
  // notificationBodyType: NotificationBodyTypes;
  icon: SvgrComponent;
  emptyFeed: React.ReactNode;
  onChange: (filters?: NotificationFilters) => void;
  error: AxiosError | null;
  markAllAsReadLabel: string;
  unreadCount: NotificationsInfo['unreadCount'];
};

export const NotificationsDrawer: FC<PropsWithChildren<Props>> = (props) => {
  const {
    visible,
    onClose,
    loading,
    notifications,
    drawerTitle,
    icon,
    emptyFeed,
    onChange,
    error,
    markAllAsReadLabel,
    children,
    unreadCount,
  } = props;
  const isEmptyFeed = !loading && !error && notifications?.length === 0;
  const { spaceXL, spaceM, space4XL } = useSpace();
  const [{ variables: themeVariables }] = useTheme();
  const { lg: isLayoutLarge } = useBreakpoint();
  const emptyPaddingStyle: CSSProperties = { padding: 0 };
  const bodyStyle: CSSProperties = isEmptyFeed
    ? { ...emptyPaddingStyle, background: themeVariables.colorInput }
    : emptyPaddingStyle;
  const contentWrapperStyle = isLayoutLarge ? { top: variables.layoutSizes.headerHeight } : undefined;

  return (
    <UiDrawer
      placement="right"
      closable={false}
      onClose={onClose}
      open={visible}
      // footer={<DrawerFooter />} // TODO: Раздел в разработке
      footer={children}
      width={400}
      footerStyle={emptyPaddingStyle}
      bodyStyle={bodyStyle}
      headerStyle={emptyPaddingStyle}
      contentWrapperStyle={contentWrapperStyle}
      title={<DrawerTitle title={drawerTitle} />}
      className={styles.notificationsDrawer}
      withCustomCloseIcon
      extra={<DrawerClose onClose={onClose} />}
    >
      <ErrorBoundary>
        {!isEmptyFeed && (
          <UiRow
            style={{
              paddingLeft: spaceXL,
              paddingRight: spaceM,
              height: space4XL,
              background: themeVariables.colorInput,
              borderBottom: themeVariables.spacerBorder,
            }}
            justify="space-between"
            align="middle"
          >
            <span>
              <UiButton
                type="link-secondary"
                className={styles.notificationsDrawer__markAsRead}
                onClick={() => markAllNotificationsRead().then(() => onChange())}
                disabled={!unreadCount || loading}
                label={markAllAsReadLabel}
              />
            </span>
            <DrawerPopover icon={icon} onChange={onChange} />
          </UiRow>
        )}
        {loading && notificationsFeedMocks}
        {isEmptyFeed && emptyFeed}
        {error && <FeedError />}
        {!loading &&
          !error &&
          notifications.map((notification) => (
            <NotificationCard key={notification.id} notification={notification} />
          ))}
      </ErrorBoundary>
    </UiDrawer>
  );
};
