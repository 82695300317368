import {
  UiTooltipPlacement,
  UiTruncateMarkup,
  UiTruncateMarkupProps,
  UiTypography,
  UiTypographyTextProps,
} from '@vkph/ui';
import HTMLReactParser from 'html-react-parser';
import React, { FC } from 'react';

import { PostCompoundSearchViewProps } from '../PostCompoundSearchView';

interface PostCompoundSearchViewContentProps
  extends Pick<PostCompoundSearchViewProps, 'compact' | 'tooltipPlacement'>,
    UiTypographyTextProps,
    Pick<UiTruncateMarkupProps, 'lines'> {
  text: string;
}

export const PostCompoundSearchViewContent: FC<PostCompoundSearchViewContentProps> = (props) => {
  const {
    text,
    compact,
    lines = compact ? 1 : 4,
    tooltipPlacement = UiTooltipPlacement.Top,
    ...restProps
  } = props;

  return (
    <UiTypography.Text {...restProps}>
      <UiTruncateMarkup truncate lines={lines} tooltipProps={{ title: text, placement: tooltipPlacement }}>
        {HTMLReactParser(text)}
      </UiTruncateMarkup>
    </UiTypography.Text>
  );
};
