import { ImageList, ImageListImage, ImageListProps } from '@vkph/components';
import React, { FC, useMemo } from 'react';

import { FileStorageFileModel, BasicPostAttachmentModel } from '@vkph/common/types/models';

interface Props extends Omit<ImageListProps, 'images'> {
  attachments: BasicPostAttachmentModel<FileStorageFileModel>[];
}

export const PostImages: FC<Props> = (props) => {
  const { attachments, ...restProps } = props;

  const mappedImages: ImageListImage[] = useMemo(
    () =>
      attachments.map(({ attachedEntity, attachmentId }) => ({
        id: attachmentId,
        url: attachedEntity.file,
      })),
    [attachments],
  );

  return <ImageList {...restProps} images={mappedImages} />;
};
