import React, { FC } from 'react';

import {
  NotificationMicropostCommentModel,
  NotificationMicropostEntryModel,
  NotificationMicropostReactionModel,
  NotificationModel,
  NotificationTypes,
  MicropostModel,
} from '@vkph/common/types/models';

import { isNotificationSomeOfTypes } from '../../../../types/guards/notification-guards';
import { NotificationMicropostComment } from './comment/NotificationMicropostComment';
import { NotificationMicropostEnrty } from './entry/NotificationMicropostEnrty';
import { NotificationMicropostMention } from './mention/NotificationMicropostMention';
import { NotificationMicropostReaction } from './reaction/NotificationMicropostReaction';

interface Props {
  notification: NotificationModel;
}

export const NotificationMicropost: FC<Props> = (props) => {
  const { notification } = props;

  if (
    isNotificationSomeOfTypes<MicropostModel>(notification, [NotificationTypes.BlogMicropostUserMentioned])
  ) {
    return <NotificationMicropostMention notification={notification} />;
  }

  if (
    isNotificationSomeOfTypes<NotificationMicropostEntryModel>(notification, [
      NotificationTypes.TimelinesSignerMicropostCreated,
    ])
  ) {
    return <NotificationMicropostEnrty notification={notification} />;
  }

  if (
    isNotificationSomeOfTypes<NotificationMicropostCommentModel>(notification, [
      NotificationTypes.MicropostCommentCreated,
      NotificationTypes.MicropostCommentReactionCreated,
      NotificationTypes.MicropostCommentUserMentioned,
      NotificationTypes.MicropostCommentReply,
    ])
  ) {
    return <NotificationMicropostComment notification={notification} />;
  }

  if (
    isNotificationSomeOfTypes<NotificationMicropostReactionModel>(notification, [
      NotificationTypes.MicropostReactionCreated,
    ])
  ) {
    return <NotificationMicropostReaction notification={notification} />;
  }

  return null;
};
