import { Notification } from '@vkph/components';
import { UiSpace, UiTruncateMarkup, UiTypography } from '@vkph/ui';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { BlogFullModel, NotificationModel } from '@vkph/common/types/models';
import { RouteNames, getFullNameWithoutPatronymic, getRoutePath } from '@vkph/common/utils';

interface Props {
  notification: NotificationModel<BlogFullModel>;
}

export const NotificationBlogAdministrator: FC<Props> = (props) => {
  const { notification } = props;
  const {
    image,
    createdAt,
    initiator: { fullName, keycloakId, isActive },
    service: serviceTitle,
    content: { id: blogId, name },
  } = notification;

  const notificationTitle = serviceTitle || getFullNameWithoutPatronymic(fullName);
  const avatar = { src: image };

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={getRoutePath(RouteNames.Profile, { id: keycloakId })}
        title={notificationTitle}
        subtitle="Уведомление"
        avatarProps={avatar}
        isActive={isActive}
      />
      <Notification.Body>
        <UiSpace full size={12} direction="vertical">
          <Link to={getRoutePath(RouteNames.GroupView, { id: blogId })}>
            <UiTypography.Link strong style={{ display: 'block' }}>
              <UiTruncateMarkup truncate lines={5}>
                {`Заблокирован администратор группы "${name}" - ${notificationTitle}. Необходимо
                назначить нового.`}
              </UiTruncateMarkup>
            </UiTypography.Link>
          </Link>
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
