import { PageNotFound } from '@vkph/components';
import { Layout } from '@vkph/ui';
import { createEffect } from 'effector';
import { useStore } from 'effector-react';
import React, { FC, useEffect, useState } from 'react';

import { useSettingsConfig } from '@vkph/common/providers/settings-config';
import { getRoutePath, RouteNames } from '@vkph/common/utils';

type RouterBasename = {
  basename: string;
};

type VKHRTekOptions = {
  root?: string | HTMLElement; // default 'root'
  page?: string; // default 'events'
  role?: 'employee' | 'company'; // default 'employee'
  router?: RouterBasename; // default undefined
};

type Window = typeof window;

type VKHRTekWidgetConstructor = {
  new (options?: VKHRTekOptions): VKHRTekWidget;
};

type VKHRTekWidget = {
  render(): void;
  unmount(): void;
};

type VKHRTekWindow = Window & {
  VKHRTek: VKHRTekWidgetConstructor;
};

const DOMAIN_SOURCE = 'https://vkdoc.mail.ru';
const VK_HR_TEK_ROOT_ID = 'vkhr-tek-root-id';

const importExternal = (url: string) => {
  return new Promise((resolve, reject) => {
    // TODO: отменить загрузку через тег script невозможно, можно переделать на fetch + abort https://share.effector.dev/W4I0ghLt
    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    script.onload = resolve;
    script.onerror = reject;

    document.body.appendChild(script);
  });
};

const importExternalEffect = createEffect(() => {
  return Promise.all([
    importExternal(`${DOMAIN_SOURCE}/env.js`),
    importExternal(`${DOMAIN_SOURCE}/static/widget/widget.js`),
  ]);
});

export const PageVKHRTek: FC = () => {
  const [isError, setIsError] = useState(false);
  const { config } = useSettingsConfig();
  const isPageEnabled = config.layouts?.pageVKHRTekEnabled?.value;

  useEffect(() => {
    if (!isPageEnabled) return undefined;

    const params: VKHRTekOptions = {
      root: `#${VK_HR_TEK_ROOT_ID}`,
      router: {
        basename: getRoutePath(RouteNames.VKHRTek).replace('/*', ''),
      },
    };
    let VKHRTekWidget = (window as VKHRTekWindow)?.VKHRTek;
    let widget: VKHRTekWidget | null = null;

    if (VKHRTekWidget) {
      widget = new VKHRTekWidget(params);
      widget.render();
    } else {
      importExternalEffect();
    }

    const unsubscribeDone = importExternalEffect.doneData.watch(() => {
      VKHRTekWidget = (window as VKHRTekWindow)?.VKHRTek;

      if (VKHRTekWidget) {
        widget = new VKHRTekWidget(params);
        widget.render();
      }
    });
    const unsubscribeFail = importExternalEffect.fail.watch(() => setIsError(true));

    return () => {
      unsubscribeDone();
      unsubscribeFail();
      widget?.unmount();
    };
  }, []);

  const isImportExternalEffectPending = useStore(importExternalEffect.pending);

  return (
    <Layout.Main>
      <Layout.Content stretch>
        {isImportExternalEffectPending && <Layout.Loading />}
        {(isError || !isPageEnabled) && <PageNotFound />}
        <div id={VK_HR_TEK_ROOT_ID} />
      </Layout.Content>
    </Layout.Main>
  );
};
