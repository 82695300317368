import { UiButton, UiIcon, UiTypography } from '@vkph/ui';
import React from 'react';

import BookmarkSvg from '@vkph/ui/svg/bookmark.svg';
import EditSvg from '@vkph/ui/svg/edit.svg';

import styles from './NavigationFavoritesHeader.scss';

const titleStyle = { margin: '0' };
const iconProps = { width: 20, height: 20 };

type Props = {
  isFavoritesEdit: boolean;
  isFavoritesEmpty: boolean;
  onClickEditHandler: React.MouseEventHandler<HTMLElement>;
};

export const NavigationFavoritesHeader: React.FC<Props> = React.memo(
  ({ isFavoritesEdit, isFavoritesEmpty, onClickEditHandler }) => (
    <div className={styles.navigationFavoritesHeader}>
      <UiIcon component={BookmarkSvg} className={styles.navigationFavoritesHeader__icon} {...iconProps} />
      <UiTypography.Title level={4} style={titleStyle}>
        Быстрый доступ
      </UiTypography.Title>
      {!isFavoritesEdit && !isFavoritesEmpty && (
        <UiButton
          type="link-secondary"
          onClick={onClickEditHandler}
          className={styles.navigationFavoritesHeader__editBtn}
          icon={<UiIcon component={EditSvg} {...iconProps} />}
        />
      )}
    </div>
  ),
);
