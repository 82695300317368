import { UiButton, UiCard, UiIcon, UiModal, UiModalTypes } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, useState } from 'react';

import { useCurrentProfile } from '@vkph/common/providers/current-profile';
import { profileFullInfoStorage } from '@vkph/common/store/profile';
import { useSpace } from '@vkph/ui/hooks';
import DraftSvg from '@vkph/ui/svg/draft.svg';
import { getModalStepsForSingleTitle } from '@vkph/ui/utils';

import { PostCompound } from '../../post/compound';
import { WritePostProps } from '../WritePost';

export const WritePostMobile: FC<WritePostProps> = (props) => {
  const { blog, isMainTimeline, postType } = props;
  const currentProfile = useCurrentProfile();
  const { data: profileFullInfoData } = useStore(profileFullInfoStorage.storage.store);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { spaceM } = useSpace();

  const isCurrentUserProfile = currentProfile.id === profileFullInfoData?.main?.id;

  const toggleModalOpen = () => setIsModalOpen((value) => !value);

  return (
    <>
      <UiCard bodyStyle={{ padding: spaceM }} size="default">
        <UiButton
          block
          size="small"
          label="Написать пост"
          type="secondary"
          icon={<UiIcon component={DraftSvg} width={20} height={20} />}
          onClick={toggleModalOpen}
        />
      </UiCard>

      <UiModal type={UiModalTypes.FullScreen} isOpen={isModalOpen} onClose={toggleModalOpen}>
        <UiModal.Header hasBottomBorder>
          <UiModal.Header.Title size="small" steps={getModalStepsForSingleTitle('Создать пост')} />
        </UiModal.Header>
        <UiModal.Content>
          <PostCompound.Edit
            blog={blog}
            postType={postType}
            onCancel={toggleModalOpen}
            onSuccess={toggleModalOpen}
            isShowSettingsVisibility={isMainTimeline || isCurrentUserProfile}
          />
        </UiModal.Content>
      </UiModal>
    </>
  );
};
