import { TagsField } from '@vkph/components';
import { UiForm, UiInput, UiRender, UiRenderType } from '@vkph/ui';
import { useStoreMap } from 'effector-react';
import React, { FC, PropsWithChildren, useMemo } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getFileStorageAllCategoriesStorage } from '@vkph/common/store/filestorage';
import { FileStorageEntryId } from '@vkph/common/types/models';
import {
  MAX_LENGTH_INPUT_255,
  requiredRule,
  generateMultiSelectOptions,
  MAX_LENGTH_INPUT_4096,
} from '@vkph/common/utils';

import { MultiSelectField } from '../../../multiselect-field/MultiSelectField';

type Props = {
  parent: FileStorageEntryId;
  extension?: string;
};

export const FileStorageCreateUpdateFields: FC<PropsWithChildren<Props>> = (props) => {
  const { children, parent, extension } = props;

  const fileStorageCategoriesStorage = useMemo(getFileStorageAllCategoriesStorage, []);

  useAbstractStorage(fileStorageCategoriesStorage.storage, {
    autoFetchParams: { entryId: parent },
    autoFetchAndRefetch: ({ fetchedAtLeastOnce }) => !fetchedAtLeastOnce,
    cancelPendingRequestOnUnmount: true,
    resetStoreOnUnmount: true,
  });

  const categoriesOptions = useStoreMap(fileStorageCategoriesStorage.storage.store, ({ data }) =>
    generateMultiSelectOptions(data, { valuePath: 'id', labelPath: 'name' }),
  );

  return (
    <>
      <UiForm.Item name="name" label="Название" required rules={[requiredRule]}>
        <UiInput
          suffix={extension ? `.${extension}` : ''}
          placeholder="Введите название"
          maxLength={MAX_LENGTH_INPUT_4096}
        />
      </UiForm.Item>
      {children}
      <UiForm.Item name="description" label="Описание">
        <UiInput.TextArea placeholder="Введите описание" maxLength={MAX_LENGTH_INPUT_255} />
      </UiForm.Item>
      <UiForm.Item name="tags" label="Теги">
        <TagsField />
      </UiForm.Item>
      <UiRender type={UiRenderType.DisplayNone} visible={Boolean(categoriesOptions.length)}>
        <UiForm.Item name="categories" shouldUpdate label="Категории">
          <MultiSelectField name="categories" options={categoriesOptions} />
        </UiForm.Item>
      </UiRender>
    </>
  );
};
