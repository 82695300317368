import humps from 'humps';

import { endpoints } from '@vkph/common/endpoints';
import { buildEndpointWithQueryParams } from '@vkph/common/utils';
import { abstractStorageFactory } from '@vkph/common/utils/effector';
import { Weather } from '@vkph/modules/types/weather';

export type GetWeatherStorageParams = { cityId: string };
export type GetWeatherStorageResponse = Record<string, Weather.Info>;

export const getWeatherStorage = ({ cityId }: GetWeatherStorageParams) => {
  const storage = abstractStorageFactory<GetWeatherStorageResponse, Weather.Info, null>({
    endpointBuilder: () => buildEndpointWithQueryParams(endpoints.informers.weather(), { cities: cityId }),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
    dataMapper: (data) => data[humps.camelize(cityId)],
  });

  return { storage };
};

export type WeatherStorage = ReturnType<typeof getWeatherStorage>;
