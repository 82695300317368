import React, { FC } from 'react';

import {
  NotificationModel,
  NotificationNewsCommentModel,
  NotificationTypes,
  PostNewsModel,
} from '@vkph/common/types/models';

import { isNotificationSomeOfTypes } from '../../../../types/guards/notification-guards';
import { NotificationNewsComment } from './comment/NotificationNewsComment';
import { NotificationNewsMention } from './mention/NotificationNewsMention';

interface Props {
  notification: NotificationModel;
}

export const NotificationNews: FC<Props> = (props) => {
  const { notification } = props;

  if (isNotificationSomeOfTypes<PostNewsModel>(notification, [NotificationTypes.NewsUserMentioned])) {
    return <NotificationNewsMention notification={notification} />;
  }

  if (
    isNotificationSomeOfTypes<NotificationNewsCommentModel>(notification, [
      NotificationTypes.NewsCommentCreated,
      NotificationTypes.NewsCommentUserMentioned,
      NotificationTypes.NewsCommentReactionCreated,
      NotificationTypes.NewsCommentReply,
    ])
  ) {
    return <NotificationNewsComment notification={notification} />;
  }

  return null;
};
