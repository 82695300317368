import { endpoints } from '@vkph/common/endpoints';
import { CreateUpdatePollModel, PollModel, VoteModel } from '@vkph/common/types/models';
import { api } from '@vkph/common/utils';

export const createPoll = (poll: CreateUpdatePollModel) =>
  api.post<PollModel>({
    url: endpoints.polls.polls(),
    data: poll,
  });

export const updatePollById = (id: number, data: CreateUpdatePollModel) =>
  api.put<PollModel>({
    url: endpoints.polls.pollsEntityId(id),
    data,
  });

export const deletePollById = (id: number) => {
  return api.delete({
    url: endpoints.polls.pollsEntityId(id),
  });
};

export const vote = (id: number, data: VoteModel[]) => {
  return api.post<PollModel>({
    url: endpoints.polls.pollsEntityIdVote(id),
    data,
  });
};

export const exportPollToXLSX = (id: number) => {
  return api.get<Blob>({
    url: endpoints.polls.pollsEntityIdExportXLSX(id),
    responseType: 'blob',
  });
};

export const exportPollAnswerToXLSX = (questionId: number, optionId: number) => {
  return api.get<Blob>({
    url: endpoints.polls.pollsQuestionIdOptionIdExportXLSX(questionId, optionId),
    responseType: 'blob',
  });
};
