import React, { useMemo, HTMLAttributes, FC } from 'react';

import { getCommentListStorage } from '@vkph/common/store/comments';
import { PostModel } from '@vkph/common/types/models';

import { CommentListWithInput } from '../../comment/comment-list-with-input/CommentListWithInput';
import { getCommentsContentTypeByPost } from '../../comment/helpers';

interface Props extends Pick<HTMLAttributes<HTMLElement>, 'style'> {
  post: PostModel;
  containerClassName?: string;
  onCommentsCountUpdate: (countDiff: number) => void;
  showInput?: boolean;
}

export const LongreadComments: FC<Props> = (props) => {
  const { post, containerClassName, onCommentsCountUpdate, showInput = true, style } = props;
  const commentsStorage = useMemo(getCommentListStorage, []);

  return (
    <CommentListWithInput
      objectId={post.id}
      contentType={getCommentsContentTypeByPost(post)}
      commentsStorage={commentsStorage}
      containerClassName={containerClassName}
      onCommentsCountUpdate={onCommentsCountUpdate}
      showInput={showInput}
      placeholderText="Оставить комментарий"
      style={style}
      isSuggestionsForceUp
    />
  );
};
