import { UiButton, UiTruncateMarkup } from '@vkph/ui';
import React from 'react';

import styles from './CalendarWidgetListItem.scss';

interface Props {
  title: string;
  subtitle: string;
  onClick?: () => void;
}

export const CalendarWidgetListItem: React.FC<Props> = (props) => {
  const { title, subtitle, onClick } = props;

  return (
    <UiButton className={styles.calendarWidgetListItem} onClick={onClick}>
      <span className={styles.calendarWidgetListItem__title}>{title}</span>
      <UiTruncateMarkup
        truncate
        lines={3}
        lineHeight="20px"
        className={styles.calendarWidgetListItem__subtitle}
      >
        {subtitle}
      </UiTruncateMarkup>
    </UiButton>
  );
};
