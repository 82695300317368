import { UiRow, UiSkeleton, UiSpace } from '@vkph/ui';
import React, { FC } from 'react';

import { createArrayMock } from '@vkph/common/utils';

export const FileInfoCardGeneralLoading: FC = () => {
  return (
    <UiSpace size={24} style={{ padding: '0 24px 24px 24px' }} direction="vertical">
      <UiRow gutter={[0, 16]}>
        {createArrayMock(5, (_, index) => (
          <UiSpace key={index}>
            <UiSkeleton loading height={20} width={130} />
            <UiSkeleton loading height={20} width={186} />
          </UiSpace>
        ))}
      </UiRow>
      <UiRow justify="space-between">
        <UiSkeleton loading width={140} height={24} />
        <UiSkeleton loading width={80} height={24} />
      </UiRow>
    </UiSpace>
  );
};
