import { message, UiButton, UiForm, UiModal, UiOptionData } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, useMemo } from 'react';

import { createUpdateFileStorageEntryEffect } from '@vkph/common/store/filestorage';
import { closeGlobalModal, GlobalModalNames } from '@vkph/common/store/global-modals';
import { FileStorageUpdatePayload } from '@vkph/common/types/file-storage';
import { FileStorageEntryInfoModel } from '@vkph/common/types/models';
import {
  generateTagApiOptions,
  generateTagFieldOptions,
  getErrorResponseMessage,
  generateMultiSelectSingleOption,
} from '@vkph/common/utils';
import { getModalStepsForSingleTitle, getSplitFileNameExtension } from '@vkph/ui/utils';

import { FileStorageCreateUpdateFields } from '../fields/FileStorageCreateUpdateFields';

export type FileStorageCreateUpdateFormValues = Pick<
  FileStorageEntryInfoModel<UiOptionData>,
  'id' | 'name' | 'description' | 'tags' | 'categories'
>;

export const FileStorageUpdateModal: FC<FileStorageUpdatePayload> = (props) => {
  const { onSuccess: onSuccessModal, fileStorageInfo, parent } = props;
  const [form] = UiForm.useForm<FileStorageCreateUpdateFormValues>();
  const onCloseModal = () => closeGlobalModal(GlobalModalNames.FileStorageUpdate);
  const { name: filename, extension } = getSplitFileNameExtension(fileStorageInfo.name);
  const isUpdatePending = useStore(createUpdateFileStorageEntryEffect.pending);

  const initialFormValues = useMemo<Partial<FileStorageCreateUpdateFormValues>>(() => {
    return {
      id: fileStorageInfo.id,
      name: filename,
      description: fileStorageInfo.description,
      tags: generateTagFieldOptions(fileStorageInfo.tags),
      categories: fileStorageInfo.categories.map((option) =>
        generateMultiSelectSingleOption(option, { valuePath: 'id', labelPath: 'name' }),
      ),
    };
  }, [fileStorageInfo, filename]);

  const onFinish = (formValues: FileStorageCreateUpdateFormValues) => {
    const { tags, categories, name, ...restFormValues } = formValues;
    const id = form.getFieldValue('id');

    const params = {
      ...restFormValues,
      id,
      name: extension ? `${name}.${extension}` : name,
      tags: generateTagApiOptions(tags),
      categories: categories.map(({ value }) => Number(value)),
      parent,
    };

    createUpdateFileStorageEntryEffect(params)
      .then((data) => {
        message.success('Файл успешно обновлен');
        onSuccessModal?.(data);
        onCloseModal();
      })
      .catch((e) => message.error(getErrorResponseMessage(e, 'Ошибка обновления файла')));
  };

  return (
    <>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle('Редактировать файл')} />
      </UiModal.Header>
      <UiModal.Content basePadding>
        <UiForm onFinish={onFinish} initialValues={initialFormValues} form={form} requiredMark>
          <FileStorageCreateUpdateFields extension={extension} parent={parent} />
        </UiForm>
      </UiModal.Content>
      <UiModal.Footer hasTopBorder>
        <UiModal.Footer.Buttons>
          <UiButton
            label="Сохранить"
            onClick={form.submit}
            size="large"
            type="primary"
            loading={isUpdatePending}
          />
          <UiButton label="Отмена" onClick={onCloseModal} size="large" type="tertiary" />
        </UiModal.Footer.Buttons>
      </UiModal.Footer>
    </>
  );
};
