import React from 'react';

import { MIN_SYMBOLS_TO_SEARCH } from '../../configs/min-symbols-to-search@default';
import { SearchPage, FilterCatogories } from './SearchPage';

const searchFilters: FilterCatogories[] = [];

export const SearchPageDefault: React.FC = () => (
  <SearchPage
    minSymbolsToSearch={MIN_SYMBOLS_TO_SEARCH}
    searchFilters={searchFilters}
    breadcrumbName="Поиск по пользователям"
  />
);
