import { UiButton, UiIcon } from '@vkph/ui';
import React from 'react';
import { Link } from 'react-router-dom';

import { getRoutePath, RouteNames } from '@vkph/common/utils';
import AddSVG from '@vkph/ui/svg/add.svg';
import CalendarSVG from '@vkph/ui/svg/calendar-widget.svg';

import styles from './CalendarWidgetHeader.scss';

interface Props {
  addEvent: () => void;
}

export const CalendarWidgetHeader: React.FC<Props> = (props) => {
  const { addEvent } = props;

  return (
    <div className={styles.calendarWidgetHeader}>
      <Link className={styles.calendarWidgetHeader__titleBlock} to={getRoutePath(RouteNames.Calendar)}>
        <UiIcon
          width={24}
          height={24}
          component={CalendarSVG}
          className={styles.calendarWidgetHeader__icon}
        />
        <span className={styles.calendarWidgetHeader__title}>Календарь</span>
      </Link>
      <UiButton type="link-secondary" onClick={addEvent} icon={<AddSVG />} />
    </div>
  );
};
