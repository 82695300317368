import { UiIcon, UiImageBackground, UiOverlay, UiTag, UiTruncateMarkup, UiTypography } from '@vkph/ui';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { useLongreadLink } from '@vkph/common/hooks';
import { LongreadTypes } from '@vkph/common/types/longread';
import { NewsArticle, NewsCategoriesDist, PostTypes } from '@vkph/common/types/models';
import FlameSvg from '@vkph/ui/svg/flame.svg';

import styles from './NewsPinnedItem.scss';

const titleStyle: React.CSSProperties = {
  margin: '12px 0 0',
  color: 'white',
};

interface Props {
  newsArticle: NewsArticle;
  newsCategoriesMap?: NewsCategoriesDist | null;
}

export const NewsPinnedItem: FC<Props> = (props) => {
  const { newsArticle, newsCategoriesMap } = props;
  const { id, categoryId, cover, type, title, important } = newsArticle;
  const newsCategory = categoryId && newsCategoriesMap?.[categoryId];
  const linkProps = useLongreadLink({ type: LongreadTypes.News, id, newsType: type || PostTypes.Simple });

  return (
    <Link tabIndex={-1} className={styles.newsPinnedItem} {...linkProps}>
      <UiOverlay
        content={
          <>
            {important && (
              <UiIcon
                component={FlameSvg}
                width={20}
                height={20}
                className={styles.newsPinnedItem__importantIcon}
              />
            )}

            <div className={styles.newsPinnedItem__header}>
              {newsCategory && (
                <UiTag strong title={newsCategory.name} color={newsCategory.extraInfo.color} />
              )}

              <UiTypography.Title level={3} style={titleStyle}>
                <UiTruncateMarkup lines={3} truncate>
                  {title}
                </UiTruncateMarkup>
              </UiTypography.Title>
            </div>
          </>
        }
      >
        <UiImageBackground className={styles.newsPinnedItem__img} url={cover} />
      </UiOverlay>
    </Link>
  );
};
