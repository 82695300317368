import { useBreakpoint, UiSkeleton } from '@vkph/ui';
import React, { FC, Suspense } from 'react';

import { BlogModel, PostTypes, ProfileInfoModel } from '@vkph/common/types/models';

import { PostsStorage } from '../../store/post';

const WritePostDesktop = React.lazy(() => import('./desktop/WritePostDesktopModule'));
const WritePostMobile = React.lazy(() => import('./mobile/WritePostMobileModule'));

export type WritePostProps = {
  profileInfo?: ProfileInfoModel | null;
  postType: PostTypes;
  blog?: BlogModel;
  postsStorage?: PostsStorage;
  isMainTimeline?: boolean;
};

export const WritePost: FC<WritePostProps> = (props) => {
  const breakpoints = useBreakpoint();

  if (breakpoints.lg) {
    return (
      <Suspense fallback={<UiSkeleton loading height={88} width="100%" />}>
        <WritePostDesktop {...props} />
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<UiSkeleton loading height={60} width="100%" />}>
      <WritePostMobile {...props} />
    </Suspense>
  );
};
