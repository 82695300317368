import {
  UiButton,
  UiCard,
  UiEmpty,
  UiIcon,
  UiItemType,
  UiMenu,
  UiModal,
  UiModalTypes,
  UiSkeleton,
  UiSpace,
  message,
} from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { useClipboard } from '@vkph/common/hooks';
import { GetFileStorageEntryInfoStorage } from '@vkph/common/store/filestorage';
import { FileStorageContextServiceTypes } from '@vkph/common/types/models';
import { createArrayMock, getErrorResponseMessage } from '@vkph/common/utils';
import { variables } from '@vkph/ui/providers/theme';
import CopySvg from '@vkph/ui/svg/copy.svg';
import FavoriteAddSvg from '@vkph/ui/svg/favorite-add.svg';
import FavoriteCheckedSvg from '@vkph/ui/svg/favorite-checked.svg';
import ShareSvg from '@vkph/ui/svg/share.svg';

import { FileActionsShareModal } from './share-modal/FileActionsShareModal';

type Props = {
  fileInfoStorage: GetFileStorageEntryInfoStorage;
};

const MOCK_COUNT = 2;
const actionsPendingMock = createArrayMock(MOCK_COUNT, (_, index) => (
  <UiSkeleton key={index} loading height={38} style={{ margin: '0 16px' }} block width={329} />
));

const SHAREABLE_CONTEXT_LIST: FileStorageContextServiceTypes[] = [
  FileStorageContextServiceTypes.Blogs,
  FileStorageContextServiceTypes.FileStorage,
];

export const FileActions: FC<Props> = (props) => {
  const { fileInfoStorage } = props;
  const { storage: fileInfoStorageInstance, toggleFavoriteEffect } = fileInfoStorage;
  const { data: fileInfoData, error: fileInfoError } = useStore(fileInfoStorageInstance.store);
  const isFileInfoPending = useStore(fileInfoStorageInstance.fetchEffect.pending);

  const [setCopied] = useClipboard(window.location.href, {
    onSuccess: () => message.success('Ссылка скопирована'),
  });
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const isShareable = useMemo(() => {
    if (fileInfoData?.context?.service) {
      return SHAREABLE_CONTEXT_LIST.includes(fileInfoData.context.service);
    }

    return false;
  }, [fileInfoData?.context?.service]);

  const onToggleFavorite = useCallback(() => {
    if (fileInfoData) {
      const { id, isFavorite } = fileInfoData;

      toggleFavoriteEffect({ id, isFavorite })
        .then(() => message.success(isFavorite ? 'Удалено из избранного' : 'Добавлено в избранное'))
        .catch((e) =>
          message.error(
            getErrorResponseMessage(
              e,
              `${isFavorite ? 'Ошибка удаления из избранного' : 'Ошибка добавления в избранное'}`,
            ),
          ),
        );
    }
  }, [fileInfoStorage, fileInfoData]);

  const items = useMemo<UiItemType[]>(() => {
    if (fileInfoData) {
      const { isFavorite } = fileInfoData;

      return [
        {
          key: 'copyUrl',
          label: 'Скопировать ссылку',
          icon: <UiIcon component={CopySvg} width={20} height={20} />,
          extraIcon: null,
          onClick: setCopied,
        },
        {
          key: 'favorite',
          label: 'Избранное',
          icon: (
            <UiIcon
              color={isFavorite ? variables.themePalette.colorWarning : variables.themePalette.colorIcon}
              component={isFavorite ? FavoriteCheckedSvg : FavoriteAddSvg}
              width={20}
              height={20}
            />
          ),
          extraIcon: null,
          onClick: onToggleFavorite,
        },
      ];
    }

    return [];
  }, [setCopied, onToggleFavorite, fileInfoData]);

  return (
    <>
      <UiCard emptyPadding>
        <UiCard.Content style={{ padding: '16px 0' }}>
          {!isFileInfoPending && (!fileInfoData || fileInfoError) && (
            <UiEmpty.Feed emptyMessage={{ header: 'Нет доступных действий' }} />
          )}
          {isFileInfoPending && (
            <UiSpace direction="vertical" size={2}>
              {actionsPendingMock}
            </UiSpace>
          )}
          {!isFileInfoPending && <UiMenu strong selectable={false} items={items} />}
        </UiCard.Content>
        {isShareable && (
          <UiCard.Footer style={{ paddingTop: 0 }}>
            <UiButton
              icon={<UiIcon component={ShareSvg} />}
              block
              size="large"
              type="primary"
              label="Поделиться файлом"
              onClick={() => setIsShareModalOpen(true)}
            />
          </UiCard.Footer>
        )}
      </UiCard>
      <UiModal
        type={UiModalTypes.Medium}
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
      >
        {fileInfoData && (
          <FileActionsShareModal fileInfo={fileInfoData} onClose={() => setIsShareModalOpen(false)} />
        )}
      </UiModal>
    </>
  );
};
