import { Layout } from '@vkph/ui';
import { Widget } from '@vkph/widget-sdk-dispatcher';
import React, { CSSProperties, FC } from 'react';

const VK_TEAMS_URL = 'https://myteam.mail.ru/webim/';

export const PageVKTeams: FC = () => {
  const widgetStyle: CSSProperties = {
    position: 'absolute',
    inset: 0,
    height: '100%',
  };

  return (
    <Layout.Main style={{ margin: 0 }}>
      <Layout.Content stretch>
        <Widget uuid="vk-teams" url={VK_TEAMS_URL} style={widgetStyle} />
      </Layout.Content>
    </Layout.Main>
  );
};
