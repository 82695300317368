// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".a3d34321_poll-questions__header{margin-bottom:12px}.a3d34321_poll-questions__cut{padding-right:44px}.a3d34321_poll-questions>.ant-row{margin-bottom:20px}.a3d34321_poll-questions>.ant-row .ant-form-item{margin:0}", "",{"version":3,"sources":["webpack://./../../libs/modules/src/components/poll/poll-editor/poll-questions/PollQuestions.scss"],"names":[],"mappings":"AAEE,iCACE,kBAAA,CAGF,8BACE,kBAAA,CAGF,kCACE,kBAAA,CAGF,iDACE,QAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.poll-questions {\n  &__header {\n    margin-bottom: 12px;\n  }\n\n  &__cut {\n    padding-right: 44px;\n  }\n\n  & > :global(.ant-row) {\n    margin-bottom: 20px;\n  }\n\n  & > :global(.ant-row .ant-form-item) {\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"poll-questions__header": "a3d34321_poll-questions__header",
	"pollQuestions__header": "a3d34321_poll-questions__header",
	"poll-questions__cut": "a3d34321_poll-questions__cut",
	"pollQuestions__cut": "a3d34321_poll-questions__cut",
	"poll-questions": "a3d34321_poll-questions",
	"pollQuestions": "a3d34321_poll-questions"
};
export default ___CSS_LOADER_EXPORT___;
