import { UiSkeleton, UiTypography } from '@vkph/ui';
import React, { FC, ReactNode } from 'react';

import styles from './CalendarWidgetEmpty.scss';

type Props = {
  addEvent: () => void;
};

export const CalendarWidgetEmpty: FC<Props> = (props) => {
  const { addEvent } = props;

  const description = 'Встреч ещё не запланировано';

  const AddLink: React.FC<{ children: ReactNode }> = (propsLink) => {
    const { children } = propsLink;

    return (
      <UiTypography.Link strong onClick={addEvent}>
        {children}
      </UiTypography.Link>
    );
  };

  return (
    <div className={styles.calendarWidgetEmpty}>
      <UiSkeleton loading={false} count={3} width="100%">
        <UiTypography.Text type="secondary">
          {description}
          <br />
          <AddLink>Добавить встречу</AddLink>
        </UiTypography.Text>
      </UiSkeleton>
    </div>
  );
};
