import { LinkListItem } from '@vkph/components';
import { UiSkeleton } from '@vkph/ui';
import React from 'react';

import { createArrayMock } from '@vkph/common/utils';

import styles from '../SearchCategoryResults.scss';

const MOCKS_COUNT = 4;

const listItemsMock = createArrayMock(MOCKS_COUNT, (_, id) => <LinkListItem.Skeleton key={id} />);

export const SearchCategoryMock = () => {
  return (
    <div className={styles.searchCategoryResults}>
      <div className={styles.searchCategoryResults__title}>
        <UiSkeleton loading width={100} />
      </div>
      {listItemsMock}
    </div>
  );
};
