import { PollQuestionTypes } from '@vkph/common/types/models';

export const INITIAL_QUESTION_VALUE = {
  question: '',
  options: ['', ''],
  type: PollQuestionTypes.SINGLE_CHOICE,
};

const POLL_QUESTION_TYPES = {
  [PollQuestionTypes.SINGLE_CHOICE]: {
    label: 'Выбрать один',
    value: PollQuestionTypes.SINGLE_CHOICE,
  },
  [PollQuestionTypes.MULTIPLE_CHOICE]: {
    label: 'Выбрать несколько',
    value: PollQuestionTypes.MULTIPLE_CHOICE,
  },
  [PollQuestionTypes.FREE_ANSWER]: {
    label: 'Текстовое поле',
    value: PollQuestionTypes.FREE_ANSWER,
  },
  [PollQuestionTypes.FREE_FILE]: {
    label: 'Прикрепить файл',
    value: PollQuestionTypes.FREE_FILE,
  },
};

export const AVAILABLE_POLL_QUESTION_TYPES = [
  POLL_QUESTION_TYPES[PollQuestionTypes.SINGLE_CHOICE],
  POLL_QUESTION_TYPES[PollQuestionTypes.MULTIPLE_CHOICE],
];

export const INITIAL_OPTIONS_LENGTH = INITIAL_QUESTION_VALUE.options.length;
