import {
  UiButton,
  UiForm,
  UiIcon,
  UiModal,
  UiTree,
  UiTreeKey,
  UiTreeNode,
  UiTypography,
  message,
} from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, useCallback, useState } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getFileStorageListStorage, transferFileStorageEntriesEffect } from '@vkph/common/store/filestorage';
import { GlobalModalNames, GlobalModalsStorePayloads } from '@vkph/common/store/global-modals';
import { FileStorageEntryType } from '@vkph/common/types/models';
import { getErrorResponseMessage } from '@vkph/common/utils';
import FolderSvg from '@vkph/ui/svg/folder-files.svg';
import { getModalStepsForSingleTitle, getUpdatedTreeData } from '@vkph/ui/utils';

type Props = NonNullable<GlobalModalsStorePayloads[GlobalModalNames.FileStorageMoveEntries]['payload']>;

export const FileStorageMoveEntries: FC<Props> = (props) => {
  const { rootId, rootName, moveItems, onSuccess, onClose } = props;
  const [form] = UiForm.useForm();
  const isFormPending = useStore(transferFileStorageEntriesEffect.pending);
  const [selectedKey, setSelectedKey] = useState<UiTreeKey | null>(null);
  const { storage: activeFileStorageListStorage } = getFileStorageListStorage(rootId);
  const { fetchFx: fetchFileStorageList } = useAbstractStorage(activeFileStorageListStorage, {
    resetStoreOnUnmount: true,
    cancelPendingRequestOnUnmount: true,
  });
  const [treeData, setTreeData] = useState<UiTreeNode[]>([
    {
      title: <UiTypography.Text>{rootName}</UiTypography.Text>,
      key: rootId,
      icon: <UiIcon component={FolderSvg} width={32} height={32} />,
      selectable: !moveItems.some((item) => item.parent === rootId || item.id === rootId),
    },
  ]);

  const onLoadData = useCallback(
    ({ key, children }: UiTreeNode): Promise<void> => {
      if (children) {
        return Promise.resolve();
      }

      // Пока размер ограничен в 1000, в будущем сделать infinite scroll
      return fetchFileStorageList({
        entryId: String(key),
        pageSize: 1000,
        pageNumber: 1,
        type: FileStorageEntryType.Folder,
      }).then(({ items }) => {
        setTreeData((origin) => {
          return getUpdatedTreeData(
            origin,
            key,
            items.map((data) => ({
              key: data.id,
              title: <UiTypography.Text>{data.name}</UiTypography.Text>,
              icon: <UiIcon component={FolderSvg} width={32} height={32} />,
              isLeaf: data.foldersCount === 0,
              selectable: !moveItems.some((item) => item.parent === data.id || item.id === data.id),
            })),
          );
        });
      });
    },
    [moveItems, setTreeData],
  );

  const onSelect = useCallback((selectedKeys: UiTreeKey[]) => {
    if (selectedKeys.length) {
      setSelectedKey(selectedKeys[0]);
    }
  }, []);

  const onFinish = useCallback(() => {
    if (selectedKey) {
      transferFileStorageEntriesEffect({
        destination: String(selectedKey),
        objectIds: moveItems.map((item) => item.id),
      })
        .then(() => {
          message.success('Объекты перемещены в выбранную папку');
          onSuccess?.();
          onClose();
        })
        .catch((e) =>
          message.error(getErrorResponseMessage(e, 'Не удалось переместить объекты в выбранную папку')),
        );
    }
  }, [selectedKey, onClose, onSuccess, moveItems]);

  return (
    <>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle('Переместить в папку')} />
      </UiModal.Header>
      <UiModal.Content style={{ padding: '12px 0' }}>
        <UiForm size="large" onFinish={onFinish} form={form}>
          <UiTree onSelect={onSelect} treeData={treeData} loadData={onLoadData} showIcon />
        </UiForm>
      </UiModal.Content>
      <UiModal.Footer hasTopBorder>
        <UiModal.Footer.Buttons>
          <UiButton
            label="Переместить"
            loading={isFormPending}
            onClick={form.submit}
            size="large"
            type="primary"
          />
          <UiButton label="Отмена" onClick={onClose} size="large" type="tertiary" />
        </UiModal.Footer.Buttons>
      </UiModal.Footer>
    </>
  );
};
