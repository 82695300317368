// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".d63c5032_poll-question{padding:20px 0}", "",{"version":3,"sources":["webpack://./../../libs/modules/src/components/poll/poll-question/PollQuestion.scss"],"names":[],"mappings":"AACA,wBACE,cAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.poll-question {\n  padding: 20px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"poll-question": "d63c5032_poll-question",
	"pollQuestion": "d63c5032_poll-question"
};
export default ___CSS_LOADER_EXPORT___;
