import { UiButton, UiCol, UiRow, UiIcon } from '@vkph/ui';
import React, { useMemo } from 'react';

import { PollModel, VoteModel } from '@vkph/common/types/models';
import ArrowBackSVG from '@vkph/ui/svg/arrow-back.svg';
import ArrowFrontSVG from '@vkph/ui/svg/arrow-front.svg';
import CheckSVG from '@vkph/ui/svg/check.svg';

import styles from './PollControls.scss';

export type PollControlsType = {
  questionIndex: number;
  onIndexChange: (questionIndex: number) => void;
  onVote: () => void;
  poll: PollModel;
  currentAnswers?: VoteModel[];
  isEdit?: boolean;
  isLoading?: boolean;
};

export const PollControls: React.FC<PollControlsType> = (props) => {
  const { onIndexChange, onVote, poll, questionIndex, isLoading, isEdit, currentAnswers = [] } = props;
  const { questions } = poll;

  const isAnswered = useMemo(
    () => currentAnswers.find((el) => el.question === questions[questionIndex].id),
    [currentAnswers, questions, questionIndex],
  );

  const isLastQuestion = questionIndex === questions.length - 1;
  const isFirstQuestion = questionIndex === 0;
  const isOneQuestionInPoll = questions.length === 1;

  return (
    <UiRow justify="space-between" className={styles.pollControls} gutter={[16, 0]}>
      {!isOneQuestionInPoll && !isFirstQuestion && (
        <UiCol flex="auto">
          <UiButton
            disabled={questionIndex === 0}
            className={styles.pollControls__button}
            type="secondary"
            label="Предыдущий вопрос"
            onClick={() => onIndexChange(questionIndex - 1)}
            icon={<UiIcon component={ArrowBackSVG} height={20} width={20} />}
          />
        </UiCol>
      )}

      {!isLastQuestion && (
        <UiCol flex="auto">
          <UiButton
            disabled={!isAnswered && !isEdit}
            className={styles.pollControls__button}
            type="primary"
            label="Следующий вопрос"
            onClick={() => onIndexChange(questionIndex + 1)}
            icon={<UiIcon component={ArrowFrontSVG} height={20} width={20} />}
          />
        </UiCol>
      )}

      {isLastQuestion && (
        <UiCol flex="auto">
          <UiButton
            disabled={!isAnswered || isLoading || isEdit}
            className={styles.pollControls__button}
            type="primary"
            label="Проголосовать"
            onClick={onVote}
            icon={<UiIcon component={CheckSVG} height={20} width={20} />}
          />
        </UiCol>
      )}
    </UiRow>
  );
};
