import { Post } from '@vkph/components';
import { UiSkeleton, UiSpace, useBreakpoint } from '@vkph/ui';
import React from 'react';

import { useSpace } from '@vkph/ui/hooks';

export const LongreadLoading: React.FC = () => {
  const { lg: isLayoutLarge } = useBreakpoint();
  const { spaceM, space2XL, space4XL } = useSpace({ unit: 'px' });

  return (
    <UiSpace
      size={isLayoutLarge ? 'large' : 'small'}
      direction="vertical"
      full
      style={{ padding: isLayoutLarge ? `${space2XL} ${space4XL}` : spaceM }}
    >
      <Post.Header.Skeleton />
      <UiSkeleton loading height={120} />
      <UiSkeleton loading height={200} />
    </UiSpace>
  );
};
