import { Notification, RouterLink } from '@vkph/components';
import { UiAvatarProps, UiButton, UiSpace, UiTruncateMarkup } from '@vkph/ui';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  CMSPageType,
  CMSViewType,
  NotificationCmsFeedbackModel,
  NotificationModel,
} from '@vkph/common/types/models';
import { RouteNames, getFullNameWithoutPatronymic, getRoutePath } from '@vkph/common/utils';

import { getNotificationDescription } from '../../../../../plugins/notification/get-notification-description';

interface Props {
  notification: NotificationModel<NotificationCmsFeedbackModel>;
}

export const NotificationCmsFeedback: FC<Props> = (props) => {
  const { notification } = props;
  const { content, createdAt, initiator, image } = notification;
  const { cms, feedback } = content;
  const { name, type, url } = cms;
  const { id } = feedback;
  const { fullName, keycloakId, isActive } = initiator;

  const navigate = useNavigate();
  const notificationDescription = getNotificationDescription(notification);
  const notificationTitle = getFullNameWithoutPatronymic(fullName);
  const avatar = { src: image } satisfies UiAvatarProps;
  const feedbackPageRoute = getRoutePath(RouteNames.AdminContentManagementCmsPageFeedbackView, {
    id,
    type,
  });

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={getRoutePath(RouteNames.Profile, { id: keycloakId })}
        title={notificationTitle}
        subtitle={notificationDescription}
        avatarProps={avatar}
        isActive={isActive}
      />
      <Notification.Body size={0}>
        <UiSpace direction="vertical" full>
          <RouterLink
            to={getRoutePath(RouteNames.CmsView, {
              type: type === CMSPageType.Regular ? CMSViewType.Page : CMSViewType.Personal,
              slugId: url,
            })}
          >
            <UiTruncateMarkup truncate tooltipProps={{ title: name }}>
              {name}
            </UiTruncateMarkup>
          </RouterLink>
          <UiButton label="Посмотреть" type="primary" onClick={() => navigate(feedbackPageRoute)} />
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
