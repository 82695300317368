import { UiRadio, UiSpace } from '@vkph/ui';
import React, { FC } from 'react';

import { UiFormComposition } from '@vkph/ui/components/form';
import { variables } from '@vkph/ui/providers/theme';

import styles from './MarkerColorField.scss';

export enum MarkerColor {
  Red,
  Coral,
  Orange,
  Yellow,
  Lime,
  Sea,
  Turquoise,
  Cyan,
  Blue,
  Lavender,
  Pink,
  Magenta,
  Silver,
  Gold,
}

export const MarkerColorMap: Record<MarkerColor, string> = {
  [MarkerColor.Red]: variables.themePalette.colorAccidentRed,
  [MarkerColor.Coral]: variables.themePalette.colorAccidentCoral,
  [MarkerColor.Orange]: variables.themePalette.colorAccidentOrange,
  [MarkerColor.Yellow]: variables.themePalette.colorAccidentYellow,
  [MarkerColor.Lime]: variables.themePalette.colorAccidentLime,
  [MarkerColor.Sea]: variables.themePalette.colorAccidentSea,
  [MarkerColor.Turquoise]: variables.themePalette.colorAccidentTurquoise,
  [MarkerColor.Cyan]: variables.themePalette.colorAccidentCyan,
  [MarkerColor.Blue]: variables.themePalette.colorAccidentBlue,
  [MarkerColor.Lavender]: variables.themePalette.colorAccidentLavender,
  [MarkerColor.Pink]: variables.themePalette.colorAccidentPink,
  [MarkerColor.Magenta]: variables.themePalette.colorAccidentMagenta,
  [MarkerColor.Silver]: variables.themePalette.colorAccidentTagSilver,
  [MarkerColor.Gold]: variables.themePalette.colorAccidentGold,
};

export const MarkerColorField: FC<Partial<UiFormComposition['Item']>> = (props) => {
  return (
    <div className={styles.markerColorField}>
      <UiRadio.Group buttonStyle="solid" size="small" className={styles.markerColorField} {...props}>
        <UiSpace direction="horizontal" size={7.69}>
          {Object.values(MarkerColorMap).map((markerColor) => (
            <UiRadio.Button key={markerColor} value={markerColor} style={{ background: markerColor }} />
          ))}
        </UiSpace>
      </UiRadio.Group>
    </div>
  );
};
