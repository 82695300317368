import { Notification } from '@vkph/components';
import { UiAvatar, UiAvatarProps, UiButton, UiIcon, UiSpace, UiTypography } from '@vkph/ui';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { NotificationModel, ProjectsTasksModel } from '@vkph/common/types/models';
import { RouteNames, getFullNameWithoutPatronymic, getRoutePath } from '@vkph/common/utils';
import TaskDefaultSvg from '@vkph/ui/svg/task-default.svg';

import { getNotificationDescription } from '../../../../../plugins/notification/get-notification-description';

type Props = {
  notification: NotificationModel<ProjectsTasksModel>;
};

export const NotificationTasksDefault: FC<Props> = (props) => {
  const { notification } = props;
  const {
    createdAt,
    content,
    initiator: { fullName, keycloakId, isActive },
    image: initiatorAvatar,
  } = notification;

  const navigate = useNavigate();

  const title = getFullNameWithoutPatronymic(fullName);
  const avatar = { src: initiatorAvatar } satisfies UiAvatarProps;
  const headerLink = getRoutePath(RouteNames.Profile, { id: keycloakId });
  const description = getNotificationDescription(notification);
  const taskLink = getRoutePath(RouteNames.Tasks);

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={headerLink}
        title={title}
        subtitle={description}
        avatarProps={avatar}
        isActive={isActive}
      />
      <Notification.Body>
        <UiSpace size={12} direction="vertical" full>
          <UiSpace size={12}>
            <UiAvatar size={32} src={<UiIcon component={TaskDefaultSvg} />} />
            <UiTypography.Text strong>{content.key}</UiTypography.Text>
          </UiSpace>
          <UiButton type="tertiary" onClick={() => navigate(taskLink)} label="Посмотреть" />
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
