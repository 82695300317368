import { Notification } from '@vkph/components';
import { UiAvatarProps, UiButton, UiIcon, UiSpace, UiTruncateMarkup, UiTypography } from '@vkph/ui';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ContentSurveyModel, NotificationModel } from '@vkph/common/types/models';
import { RouteNames, getFullNameWithoutPatronymic, getRoutePath } from '@vkph/common/utils';
import ArrowFrontSvg from '@vkph/ui/svg/arrow-front.svg';
import HintSvg from '@vkph/ui/svg/hint.svg';
import PollSvg from '@vkph/ui/svg/poll.svg';

import { getNotificationDescription } from '../../../../plugins/notification/get-notification-description';

interface Props {
  notification: NotificationModel<ContentSurveyModel>;
}

export const NotificationSurvey: FC<Props> = (props) => {
  const { notification } = props;
  const {
    content,
    image,
    createdAt,
    initiator: { fullName, keycloakId, isActive },
    service: serviceTitle,
  } = notification;
  const { title, surveyType, questionsCount, link } = content;

  const navigate = useNavigate();

  const notificationDescription = getNotificationDescription(notification);
  const notificationTitle = serviceTitle || getFullNameWithoutPatronymic(fullName);
  const avatar = { src: image } satisfies UiAvatarProps;

  const onOpenLink = () => navigate(link);

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={getRoutePath(RouteNames.Profile, { id: keycloakId })}
        title={notificationTitle}
        subtitle={notificationDescription}
        avatarProps={avatar}
        isActive={isActive}
      />

      <Notification.Body>
        <UiSpace direction="vertical" full>
          {/* TODO: Разобраться куда должна вести ссылка */}
          <UiTypography.Link strong>
            <UiTruncateMarkup truncate lines={2}>
              {title}
            </UiTruncateMarkup>
          </UiTypography.Link>
          <UiSpace size={16} full>
            <UiIcon.Label component={PollSvg} label={surveyType} />
            <UiIcon.Label component={HintSvg} label={questionsCount} />
          </UiSpace>
          {/* TODO Обработать логику кнопки "Пройти опрос" когда готов функционал  */}
          <UiButton
            onClick={onOpenLink}
            label="Пройти опрос"
            type="primary"
            icon={<UiIcon component={ArrowFrontSvg} width={12} height={12} />}
          />
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
