import { Widget, WidgetProps } from '@vkph/widget-sdk-dispatcher';
import React, { FC } from 'react';

import { WidgetModel } from '@vkph/common/types/models';

interface Props extends Pick<WidgetProps, 'onLoad' | 'onError'> {
  widget: WidgetModel;
}

export const CreateWidgetComponentInternal: FC<Props> = (props) => {
  const { widget, ...rest } = props;
  const { url, uuid } = widget;

  return <Widget url={url} uuid={uuid} {...rest} />;
};
