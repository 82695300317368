import { UiEmpty, message } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { CommentListStorage } from '@vkph/common/store/comments';
import { reactionEffect } from '@vkph/common/store/reactions';
import {
  CommentContentTypes,
  CommentId,
  CommentObjectId,
  EmojiUuid,
  ReactionContentType,
} from '@vkph/common/types/models';
import { getErrorResponseMessage } from '@vkph/common/utils';

import { CommentList, CommentListProps } from '../comment-list';
import { CommentListStandaloneLoading } from './loading/CommentListStandaloneLoading';

export interface CommentListStandaloneProps extends Partial<Pick<CommentListProps, 'maxNestLevelWithShift'>> {
  objectId: CommentObjectId;
  contentType: CommentContentTypes;
  storage: CommentListStorage;
  onComment?: (objectId: CommentObjectId) => void;
}

export const CommentListStandalone: FC<CommentListStandaloneProps> = (props) => {
  const { storage, objectId, contentType, maxNestLevelWithShift = 5, onComment: onCommentSuccess } = props;

  const {
    storage: commentListStorage,
    updateCommentEffect,
    deleteCommentEffect,
    createCommentEffect,
  } = storage;

  const isCreateCommentPending = useStore(createCommentEffect.pending);
  const isUpdateCommentPending = useStore(updateCommentEffect.pending);
  const isDeleteCommentPending = useStore(deleteCommentEffect.pending);
  const isCommentActionPending = isCreateCommentPending || isUpdateCommentPending || isDeleteCommentPending;

  const {
    data: commentsData,
    loading: isCommentsLoading,
    error: commentsError,
  } = useAbstractStorage(commentListStorage, {
    resetStoreOnUnmount: true,
    autoFetchAndRefetch: true,
    cancelPendingRequestOnUnmount: true,
    autoFetchParams: { contentType, objectId },
  });

  const getActionErrorMessage = (action: string) => `Не удалось ${action} комментарий`;

  const onComment = (text: string, parentId: CommentId | null) =>
    createCommentEffect({ text, parentId, objectId, contentType })
      .then(commentListStorage.refetchWithLastParams)
      .then(() => onCommentSuccess?.(objectId))
      .catch((e) => message.error(getErrorResponseMessage(e, getActionErrorMessage('добавить'))));

  const onUpdateComment = (commentId: CommentId, text: string) =>
    updateCommentEffect({ commentId, text, objectId, contentType }).catch((e) =>
      message.error(getErrorResponseMessage(e, getActionErrorMessage('изменить'))),
    );
  const onDeleteComment = (commentId: CommentId) =>
    deleteCommentEffect({ commentId }).catch((e) =>
      message.error(getErrorResponseMessage(e, getActionErrorMessage('удалить'))),
    );
  const onReaction = (commentId: CommentId, emojiUuid?: EmojiUuid) =>
    reactionEffect({ objectId: commentId, contentType: ReactionContentType.Comment, emojiUuid }).catch((e) =>
      message.error(getErrorResponseMessage(e, `Не удалось ${emojiUuid ? 'поставить' : 'удалить'} реакцию`)),
    );
  const onActualSend = (text: string, parentId: CommentId | null = null) => onComment(text, parentId);

  const isDataExist = commentsData.length > 0;
  const isLoading = isCommentsLoading && !isDataExist;
  const isEmpty = !isDataExist && !isLoading;

  if (commentsError) {
    return <UiEmpty.Feed emptyMessage={{ header: 'Ошибка получения комментариев' }} />;
  }

  return (
    <>
      {isLoading && <CommentListStandaloneLoading />}
      {isDataExist && (
        <CommentList
          maxNestLevelWithShift={maxNestLevelWithShift}
          loading={isCommentActionPending}
          onReaction={onReaction}
          onCommentDelete={onDeleteComment}
          onCommentEdit={onUpdateComment}
          onSend={onActualSend}
          comments={commentsData}
        />
      )}
      {isEmpty && <UiEmpty.Feed emptyMessage={{ header: 'Нет комментариев' }} />}
    </>
  );
};
