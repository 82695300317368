import { UiButton, UiRender, UiRenderType, UiSpace, UiTruncateMarkup, UiTypography } from '@vkph/ui';
import React, { FC } from 'react';

type Props = {
  visible: boolean;
  content: string | null;
  onClick?: () => void;
};

export const NotificationBlogPostContent: FC<Props> = (props) => {
  const { content, onClick, visible } = props;

  return (
    <UiRender type={UiRenderType.NullElement} visible={visible}>
      <UiSpace size={12} direction="vertical" full>
        {onClick && (
          <>
            <UiTypography.Link onClick={onClick}>
              <UiTruncateMarkup lines={2} truncate>
                {content}
              </UiTruncateMarkup>
            </UiTypography.Link>
            <UiButton type="tertiary" label="Посмотреть" onClick={onClick} />
          </>
        )}
        {!onClick && (
          <UiTypography.Text strong>
            <UiTruncateMarkup lines={2} truncate>
              {content}
            </UiTruncateMarkup>
          </UiTypography.Text>
        )}
      </UiSpace>
    </UiRender>
  );
};
