import { endpoints } from '@vkph/common/endpoints';
import { DictPaginated } from '@vkph/common/types/api';
import { CMSPageModel, SearchParams } from '@vkph/common/types/models';
import { buildEndpointWithQueryParams, abstractStorageFactory } from '@vkph/common/utils';

export const getSearchPagesStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<CMSPageModel>,
    CMSPageModel[],
    CMSPageModel[],
    SearchParams
  >({
    endpointBuilder: ({ pageNumber, pageSize, searchString, searchField, sectionId }) =>
      buildEndpointWithQueryParams(endpoints.cms.pages(), {
        pageNumber,
        pageSize,
        query: searchString,
        searchField,
        sectionId,
      }),
    defaultValue: [],
    dataMapper: ({ items }) => items,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};
