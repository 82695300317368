import { ActionsDropdown, File } from '@vkph/components';
import { UiSpace, UiStatCell, UiStatCellBorderType } from '@vkph/ui';
import React, { FC } from 'react';

import {
  AttachmentEntityId,
  BasicPostAttachmentModel,
  FileStorageFileModel,
} from '@vkph/common/types/models';

type Props = {
  attachments: BasicPostAttachmentModel<FileStorageFileModel>[];
  isEdit?: boolean;
  onDelete: (id: AttachmentEntityId) => void;
};

export const PostFiles: FC<Props> = (props) => {
  const { attachments, isEdit, onDelete } = props;

  return (
    <UiStatCell.Frame border={UiStatCellBorderType.Solid}>
      <UiSpace size={12} full direction="vertical">
        {attachments.map((attachment) => {
          const { attachedEntity } = attachment;

          return (
            <File
              key={attachedEntity.id}
              justify="space-between"
              stretch
              fileName={attachedEntity.name}
              file={{ ...attachedEntity, foldersCount: 0, filesCount: 0, parent: '' }}
              actions={
                isEdit
                  ? [
                      <ActionsDropdown
                        key="dropdown"
                        items={[
                          {
                            label: 'Удалить',
                            onClick: () => onDelete(attachment.attachmentId),
                          },
                        ]}
                      />,
                    ]
                  : undefined
              }
            />
          );
        })}
      </UiSpace>
    </UiStatCell.Frame>
  );
};
