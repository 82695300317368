import { MultiSelectAvatarLabelDataType } from '@vkph/components';
import { UiAvatar, UiButton, UiForm, UiModal, UiModalProps, UiSpace, UiTypography, message } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getBlogMembersStorage, getSingleBlogStorage } from '@vkph/common/store/blogs';
import { fileStorageFileShareEffect } from '@vkph/common/store/filestorage';
import { useProfileOptionsListStorage } from '@vkph/common/store/profile/hooks';
import { BlogTypes } from '@vkph/common/types/blogs';
import { isFileStorageBlogContext } from '@vkph/common/types/guards';
import {
  BlogModel,
  BlogRestrictedType,
  FileStorageEntryInfoModel,
  UserAvatarModel,
  UserIdModel,
} from '@vkph/common/types/models';
import { getErrorResponseMessage, getFullNameWithoutPatronymic } from '@vkph/common/utils';
import { OptionModel } from '@vkph/ui/types/option';
import { getModalStepsForSingleTitle } from '@vkph/ui/utils';

import { MultiSelectField } from '../../../multiselect-field/MultiSelectField';

type UserDataType = Omit<MultiSelectAvatarLabelDataType, 'isRejected'>;
type UserOption = OptionModel<UserIdModel, UserDataType>;
type RolesItemContent = (params: UserOption) => JSX.Element;

type FormValues = {
  users: UserOption[];
};

interface Props extends Pick<UiModalProps, 'onClose'> {
  fileInfo: FileStorageEntryInfoModel;
}

export const FileActionsShareModal: FC<Props> = (props) => {
  const { onClose, fileInfo } = props;
  const [form] = UiForm.useForm<FormValues>();
  const isFileStorageFileSharePending = useStore(fileStorageFileShareEffect.pending);

  const { options: profileListOptions, onUpdate } = useProfileOptionsListStorage({
    autoFetchParams: { isActive: true },
  });

  const blogId = useMemo(() => {
    return isFileStorageBlogContext(fileInfo?.context) ? fileInfo.context.blogId : 0;
  }, [fileInfo]);

  const blogMembersStorage = useMemo(() => getBlogMembersStorage(blogId), [blogId]);
  const singleBlogStorage = useMemo(
    () =>
      getSingleBlogStorage<BlogModel>({
        slugId: blogId,
        type: BlogTypes.BlogsView,
      }),
    [blogId],
  );

  const { data: blogMembersData } = useAbstractStorage(blogMembersStorage.storage, {
    autoFetchAndRefetch: Boolean(blogId),
  });
  const { data: singleBlogData } = useAbstractStorage(singleBlogStorage.storage, {
    autoFetchAndRefetch: Boolean(blogId),
  });

  const onFinish = ({ users }: FormValues) => {
    fileStorageFileShareEffect({
      fileId: fileInfo.id,
      targets: users.map((user) => user.value),
    })
      .then(() => {
        message.success('Вы успешно поделились файлом');
        onClose?.();
      })
      .catch((e) => message.error(getErrorResponseMessage(e, 'Не удалось поделиться файлом')));
  };

  // TODO: вынести к мульиселекту
  const UserAvatarSelectOption: FC<PropsWithChildren<Partial<Pick<UserAvatarModel, 'avatar'>>>> = (
    optionProps,
  ) => {
    const { avatar, children } = optionProps;

    return (
      <UiSpace size={8} align="center">
        <UiAvatar size={24} src={avatar} />
        <UiTypography.Text>{children}</UiTypography.Text>
      </UiSpace>
    );
  };

  const onSearchDebounced = useDebouncedCallback(onUpdate, 500);

  const userOptions = useMemo<UserOption[]>(() => {
    if (blogId && singleBlogData?.restrictedType !== BlogRestrictedType.Open) {
      return blogMembersData.map((member) => {
        const label = getFullNameWithoutPatronymic(member);

        return {
          value: member?.keycloakId,
          data: {
            avatar: member?.avatar,
            selectedLabel: label,
            label: <UserAvatarSelectOption avatar={member?.avatar}>{label}</UserAvatarSelectOption>,
          },
        };
      });
    }

    return profileListOptions.map((option) => {
      return {
        value: option?.value,
        data: {
          avatar: option?.avatar,
          selectedLabel: option?.label,
          label: <UserAvatarSelectOption avatar={option?.avatar}>{option.label}</UserAvatarSelectOption>,
        },
      };
    });
  }, [profileListOptions, blogId, blogMembersData, singleBlogData]);

  const itemContent = useCallback<RolesItemContent>(
    ({ data: { avatar, selectedLabel } }) => (
      <UserAvatarSelectOption key={selectedLabel} avatar={avatar}>
        {selectedLabel}
      </UserAvatarSelectOption>
    ),
    [],
  );

  return (
    <UiForm form={form} requiredMark size="large" layout="vertical" onFinish={onFinish}>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle('Поделиться файлом')} />
      </UiModal.Header>

      <UiModal.Content basePadding>
        <UiForm.Item label="Пользователь" required name="users">
          <MultiSelectField
            options={userOptions}
            name="users"
            ItemContent={itemContent}
            onSearch={onSearchDebounced}
          />
        </UiForm.Item>
      </UiModal.Content>

      <UiModal.Footer hasTopBorder>
        <UiModal.Footer.Buttons>
          <UiButton
            type="primary"
            onClick={form.submit}
            loading={isFileStorageFileSharePending}
            label="Поделиться"
          />
          <UiButton type="secondary" onClick={onClose} label="Отмена" />
        </UiModal.Footer.Buttons>
      </UiModal.Footer>
    </UiForm>
  );
};
