import React, { FC } from 'react';

import {
  IdeaModel,
  NotificationIdeaCommentModel,
  NotificationModel,
  NotificationTypes,
} from '@vkph/common/types/models';

import { isNotificationSomeOfTypes } from '../../../../types/guards/notification-guards';
import { NotificationIdeaComment } from './comment/NotificationIdeaComment';
import { NotificationIdeaDefault } from './default/NotificationIdeaDefault';

type Props = {
  notification: NotificationModel;
};

export const NotificationIdea: FC<Props> = (props) => {
  const { notification } = props;

  if (
    isNotificationSomeOfTypes<IdeaModel>(notification, [
      NotificationTypes.IdeaApprove,
      NotificationTypes.IdeaDecline,
      NotificationTypes.IdeaSetResponsible,
      NotificationTypes.IdeaRequiredReview,
    ])
  ) {
    return <NotificationIdeaDefault notification={notification} />;
  }

  if (
    isNotificationSomeOfTypes<NotificationIdeaCommentModel>(notification, [
      NotificationTypes.IdeaCommentCreated,
      NotificationTypes.IdeaCommentReply,
      NotificationTypes.IdeaCommentReactionCreated,
    ])
  ) {
    return <NotificationIdeaComment notification={notification} />;
  }

  return null;
};
