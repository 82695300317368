import { UiAvatarProps } from '@vkph/ui';
import React, { FC } from 'react';

import { NotificationAlbumImageCommentModel, NotificationModel } from '@vkph/common/types/models';
import { getFullNameWithoutPatronymic, getRoutePath, RouteNames, parseEntityText } from '@vkph/common/utils';

import { getNotificationDescription } from '../../../../../plugins/notification/get-notification-description';
import { NotificationComment, NotificationTargetTypes } from '../../comment';

type Props = {
  notification: NotificationModel<NotificationAlbumImageCommentModel>;
};

export const NotificationAlbumImageComment: FC<Props> = (props) => {
  const { notification } = props;

  const { content, createdAt, image, initiator } = notification;
  const { fullName, keycloakId, isActive } = initiator;
  const { comment, blog, parent } = content;
  const { id, slug, name } = blog;

  const parsedParentText = parent?.text && parseEntityText(parent.text).textValue;
  const parsedCommentText = comment?.text && parseEntityText(comment.text).textValue;

  const commentText = parsedParentText || parsedCommentText;
  const replyText = parent ? parsedCommentText : undefined;

  const notificationDescription = getNotificationDescription(notification);
  const notificationTitle = getFullNameWithoutPatronymic(fullName);
  const avatar = { src: image } satisfies UiAvatarProps;
  const userLink = getRoutePath(RouteNames.Profile, { id: keycloakId });

  return (
    <NotificationComment
      status={notification.status}
      title={notificationTitle}
      subtitle={notificationDescription}
      avatarProps={avatar}
      isActive={isActive}
      to={userLink}
      commentText={commentText}
      replyText={replyText}
      createdAt={createdAt}
      targetType={NotificationTargetTypes.Page}
      targetName={name}
      targetDescription="К изображению в группе:"
      targetUrl={getRoutePath(RouteNames.GroupView, { id: slug || id })}
    />
  );
};
