import { RouterLink } from '@vkph/components';
import { UiTruncateMarkup, UiTypography } from '@vkph/ui';
import React, { FC } from 'react';

import { useLongreadNavigation } from '@vkph/common/hooks';
import { isCommentSomeOfContentTypes } from '@vkph/common/types/guards';
import { LongreadTypes } from '@vkph/common/types/longread';
import {
  CommentContentTypes,
  CommentDetailListModel,
  MicropostModel,
  NewsArticle,
  PostBlogModel,
  PostTypes,
} from '@vkph/common/types/models';

import { getCommentLinkInfo } from './helpers';

type LongreadEntry = PostBlogModel | MicropostModel | NewsArticle;
type LongreadEntryType = PostTypes.Entry | PostTypes.Micropost | PostTypes.Simple;

const longreadTypes: Partial<Record<CommentContentTypes, LongreadEntryType>> = {
  [CommentContentTypes.Post]: PostTypes.Entry,
  [CommentContentTypes.Micropost]: PostTypes.Micropost,
  [CommentContentTypes.NewsEntry]: PostTypes.Simple,
};

type Props = {
  comment: CommentDetailListModel;
};

export const CommentListItemLink: FC<Props> = (props) => {
  const { comment } = props;
  const { contentType } = comment;
  const { title = '', to = '' } = getCommentLinkInfo(comment);
  const { openLongread } = useLongreadNavigation();
  const titleMarkup = <UiTruncateMarkup truncate>{title}</UiTruncateMarkup>;

  if (
    isCommentSomeOfContentTypes<LongreadEntry>(comment, [
      CommentContentTypes.Post,
      CommentContentTypes.Micropost,
      CommentContentTypes.NewsEntry,
    ])
  ) {
    const onClick = () => {
      const type = longreadTypes[contentType];

      if (!type) {
        return;
      }

      const isNews = type === PostTypes.Simple;

      if (isNews) {
        openLongread({ type: LongreadTypes.News, id: comment.data.id, newsType: type });
      } else {
        openLongread({ type: LongreadTypes.Post, id: comment.data.id, postType: type });
      }
    };

    return (
      <UiTypography.Link strong onClick={onClick}>
        {titleMarkup}
      </UiTypography.Link>
    );
  }

  if (to) {
    return <RouterLink to={to}>{titleMarkup}</RouterLink>;
  }

  return <UiTypography.Text type="secondary">{titleMarkup}</UiTypography.Text>;
};
