import { Achievement, Post, Reactions, UserAvatar } from '@vkph/components';
import { UiButton, UiDivider, UiRender, UiRenderType, UiSpace, message, useBreakpoint } from '@vkph/ui';
import React, { FC, useContext } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { reactionEffect } from '@vkph/common/store/reactions';
import {
  CommentContentTypes,
  EmojiUuid,
  ReactionContentType,
  TimelineRecordActorModel,
  TimelineThanksContentModel,
  UserIdModel,
} from '@vkph/common/types/models';
import { RouteNames, getErrorResponseMessage, getRoutePath } from '@vkph/common/utils';
import { useSpace } from '@vkph/ui/hooks';

import { PostCompound } from '../../post/compound/PostCompound';
import { TimelineCardGutterContext, TimelineCardProps } from '../TimelineCard';

interface Props extends Pick<TimelineCardProps, 'onClick'> {
  record: TimelineRecordActorModel<TimelineThanksContentModel>;
}

export const TimelineCardThanks: FC<Props> = (props) => {
  const { record, onClick } = props;
  const [verticalGutter, horizontalGutter] = useContext(TimelineCardGutterContext);
  const { lg: isLayoutLarge } = useBreakpoint();
  const { spaceM } = useSpace();

  const { content, createdAt, publishedAt, actor } = record;
  const { thanks } = content;

  const onOpenProfile: LinkProps['onClick'] = (e) => e.stopPropagation();
  const getProfileRouter = (id: UserIdModel) => getRoutePath(RouteNames.Profile, { id });

  const onReaction = (emojiUuid?: EmojiUuid) => {
    reactionEffect({
      objectId: thanks.id,
      contentType: ReactionContentType.Thanks,
      emojiUuid,
    }).catch((e) =>
      message.error(getErrorResponseMessage(e, `Не удалось ${emojiUuid ? 'поставить' : 'удалить'} реакцию`)),
    );
  };

  return (
    <Post style={{ paddingTop: verticalGutter }}>
      <Post.Header padding={[0, horizontalGutter]} author={actor} postDate={publishedAt || createdAt} />
      <Post.Body padding={[spaceM, horizontalGutter]}>
        <UiSpace size={spaceM} direction="vertical" full>
          <UiButton.Decorator onClick={() => onClick?.(record)}>
            <Achievement.Placeholder>
              <Achievement
                align="center"
                direction="vertical"
                paragraph={thanks.msg}
                title={thanks.attributes.name}
              >
                <Achievement.Icon
                  type="thank"
                  src={thanks.attributes.image}
                  coins={thanks.coins > 0 ? thanks.coins.toString() : ''}
                >
                  <Link to={getProfileRouter(thanks.fromUser.id)} onClick={onOpenProfile}>
                    <UserAvatar size={80} src={thanks.fromUser.avatar} isActive={thanks.fromUser.isActive} />
                  </Link>
                  <Link to={getProfileRouter(thanks.toUser.id)} onClick={onOpenProfile}>
                    <UserAvatar size={80} src={thanks.toUser.avatar} isActive={thanks.toUser.isActive} />
                  </Link>
                </Achievement.Icon>
              </Achievement>
            </Achievement.Placeholder>
          </UiButton.Decorator>
          <Reactions onReaction={onReaction} comments={thanks.commentsCount} reactions={thanks.reactions} />
        </UiSpace>
      </Post.Body>
      <UiRender type={UiRenderType.NullElement} visible={isLayoutLarge}>
        <UiDivider emptyMargin />
        <Post.Footer>
          <PostCompound.CommentsWithInput
            showInput={Boolean(record.actor.isActive)}
            objectId={thanks.id}
            contentType={CommentContentTypes.Thanks}
          />
        </Post.Footer>
      </UiRender>
    </Post>
  );
};
