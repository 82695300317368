import { UiButton, UiIcon, UiSpace } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, useMemo, useState } from 'react';

import { PollModel, PollPermissions, VoteModel } from '@vkph/common/types/models';
import DownloadSVG from '@vkph/ui/svg/download.svg';
import PollSVG from '@vkph/ui/svg/poll.svg';
import { downloadFile } from '@vkph/ui/utils';

import { exportPollToXLSXEffect, votePollEffect } from '../../store/poll';
import styles from './Poll.scss';
import { checkIsPollTimeOver } from './helpers';
import { PollAnswers } from './poll-answers/PollAnswers';
import { PollControls } from './poll-controls/PollControls';
import { PollHeader } from './poll-header/PollHeader';
import { PollModal } from './poll-modal/PollModal';
import { PollQuestion } from './poll-question/PollQuestion';

type Props = {
  poll: PollModel;
  onVote: () => void;
  onSuccess: (poll: PollModel) => void;
  onDelete?: () => void;
  isEdit?: boolean;
};

export const Poll: FC<Props> = (props) => {
  const { poll, onVote, onSuccess, onDelete, isEdit = false } = props;
  const { id, questions, title, userHasResult, permissions } = poll;
  const isPermissionToExport = permissions?.includes(PollPermissions.PollExportExcel);
  const isLoading = useStore(votePollEffect.pending);
  const [answers, setAnswers] = useState<VoteModel[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isFinished = checkIsPollTimeOver(poll) || userHasResult;

  const selectedQuestion = (index: number) => questions[index];

  const activeAnswers = useMemo(
    () => answers.filter((el) => el.question === selectedQuestion(selectedIndex).id),
    [answers, selectedIndex],
  );

  const handleVote = () => {
    votePollEffect({ id, body: answers }).then(onVote);
  };

  const handleChange = (newVal: VoteModel[] | null, questionId: number) => {
    const hasValue = newVal?.find?.((vote) => vote.option || vote.value);
    const oldVal = answers.filter((el) => el.question !== questionId);
    const newAnswers = newVal && hasValue ? [...oldVal, ...newVal] : [...oldVal];

    setAnswers(newAnswers);
  };

  const saveAllToFileXLSX = (pollId: number, name: string) => {
    exportPollToXLSXEffect(pollId).then((file) => {
      downloadFile(file, `${name}.xlsx`);
    });
  };

  const seeResults = () => {
    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const isSingleQuestionPoll = questions.length === 1;
  const isShowListOfQuestions = (isFinished && isSingleQuestionPoll) || !isFinished;

  return (
    <>
      <div className={styles.poll}>
        <PollHeader
          poll={poll}
          onDelete={onDelete}
          onSuccess={onSuccess}
          isEdit={isEdit}
          isLoading={isLoading}
        />
        {isShowListOfQuestions && (
          <>
            <PollQuestion
              questionIndex={selectedIndex}
              title={questions[selectedIndex].text}
              total={questions.length}
            >
              <PollAnswers
                poll={poll}
                question={selectedQuestion(selectedIndex)}
                answers={activeAnswers}
                disabled={isLoading || isEdit}
                onChange={handleChange}
                isSingleQuestionPoll={isSingleQuestionPoll}
              />
            </PollQuestion>
          </>
        )}
        {!isFinished && (
          <PollControls
            isEdit={isEdit}
            questionIndex={selectedIndex}
            currentAnswers={answers}
            onIndexChange={setSelectedIndex}
            onVote={handleVote}
            poll={poll}
          />
        )}
        {isFinished && (
          <UiSpace style={{ paddingTop: 24 }} size={24} direction="vertical" full>
            {questions.length > 1 && (
              <UiButton
                block
                size="large"
                type="primary"
                label="Смотреть результаты"
                onClick={seeResults}
                icon={<UiIcon component={PollSVG} height={20} width={20} />}
              />
            )}
            {isPermissionToExport && (
              <UiButton
                type="link"
                label="Выгрузить все ответы в файл"
                icon={<UiIcon component={DownloadSVG} height={20} width={20} />}
                onClick={() => saveAllToFileXLSX(id, title)}
              />
            )}
          </UiSpace>
        )}
      </div>

      <PollModal
        poll={poll}
        isOpen={isModalOpen}
        onClose={onModalClose}
        saveAllToFileXLSX={() => saveAllToFileXLSX(id, title)}
      />
    </>
  );
};
