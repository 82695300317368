import { UiButton, UiIcon, message, UiSpace, UiTypographyTextTypes } from '@vkph/ui';
import React, { FC } from 'react';

import { acceptInviteEffect, rejectInviteEffect } from '@vkph/common/store/blogs';
import { notificationsStore } from '@vkph/common/store/notifications';
import {
  NotificationBlogInviteContentModel,
  NotificationBlogInviteState,
  NotificationModel,
} from '@vkph/common/types/models';
import { getErrorResponseMessage } from '@vkph/common/utils';
import { variables } from '@vkph/ui/providers/theme';
import ErrorSvg from '@vkph/ui/svg/error.svg';
import SuccessSvg from '@vkph/ui/svg/success.svg';

type Props = {
  notification: NotificationModel<NotificationBlogInviteContentModel>;
};

export const BlogInviteActions: FC<Props> = (props) => {
  const { notification } = props;
  const { content } = notification;
  const { state, blog } = content;
  const { id } = blog;

  const isInvitationState = (expectedState: NotificationBlogInviteState) => state === expectedState;

  const onAccept = () => {
    acceptInviteEffect({ blogId: id })
      .then(() => {
        message.success('Приглашение принято');
        notificationsStore.refetchWithLastParams();
      })
      .catch((e) => message.error(getErrorResponseMessage(e, 'Не удалось принять приглашение')));
  };

  const onReject = () => {
    rejectInviteEffect({ blogId: id })
      .then(() => {
        message.success('Приглашение отклонено');
        notificationsStore.refetchWithLastParams();
      })
      .catch((e) => message.error(getErrorResponseMessage(e, 'Не удалось отклонить приглашение')));
  };

  return (
    <>
      {isInvitationState(NotificationBlogInviteState.Approved) && (
        <UiIcon.Label
          component={SuccessSvg}
          style={{ color: variables.themePalette.colorSuccess }}
          type={UiTypographyTextTypes.Primary}
          label="Вы подписались"
          strong
        />
      )}
      {isInvitationState(NotificationBlogInviteState.Declined) && (
        <UiIcon.Label
          component={ErrorSvg}
          style={{ color: variables.themePalette.colorNegative }}
          type={UiTypographyTextTypes.Primary}
          label="Вы отклонили"
          strong
        />
      )}
      {isInvitationState(NotificationBlogInviteState.Pending) && (
        <UiSpace align="center" size={12}>
          <UiButton label="Подписаться" type="primary" onClick={onAccept} />
          <UiButton label="Отклонить" type="tertiary" onClick={onReject} />
        </UiSpace>
      )}
    </>
  );
};
