import { endpoints } from '@vkph/common/endpoints';
import { DictPaginated } from '@vkph/common/types/api';
import { SearchListItem, SearchParams } from '@vkph/common/types/models';
import { abstractStorageFactory, buildEndpointWithQueryParams } from '@vkph/common/utils';

import { ProfileMain, searchListResultDataMapper } from '../../../types/search/profile';

export const getSearchProfileAdvancedStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<ProfileMain>,
    SearchListItem[],
    SearchListItem[],
    SearchParams
  >({
    endpointBuilder: ({ searchString, ...restParams }) =>
      buildEndpointWithQueryParams(endpoints.profile.v3AdvancedSearch(), {
        query: searchString,
        skipEmptyName: true,
        ...restParams,
      }),
    defaultValue: [],
    dataMapper: ({ items }) => searchListResultDataMapper(items),
    paginationInfoRetriever: ({ meta: { objectsTotal: count } }) => ({ count }),
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};
