import { ActionsDropdown } from '@vkph/components';
import { UiButtonProps, UiIcon } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, useMemo } from 'react';

import { currentProfileRolesPermissionsMapStore } from '@vkph/common/providers/current-profile/service/current-profile-service';
import { refetchOnCreateUpdateMeetingEvent } from '@vkph/common/store/calendar';
import { GlobalModalNames, openGlobalModal } from '@vkph/common/store/global-modals';
import { EventsAllowedPermission } from '@vkph/common/types/models';
import { variables } from '@vkph/ui/providers/theme';
import AddCircleSvg from '@vkph/ui/svg/add-circle.svg';
import CalendarSVG from '@vkph/ui/svg/calendar.svg';
import EventSvg from '@vkph/ui/svg/event.svg';

export const HeaderEventsButton: FC<UiButtonProps> = (props) => {
  const {
    icon = <UiIcon component={AddCircleSvg} width={20} height={20} color={variables.themePalette.white} />,
    ...rest
  } = props;

  const permissions = useStore(currentProfileRolesPermissionsMapStore);
  const isAllowCreateEvents = permissions.has(EventsAllowedPermission.CreateEvents);

  const items = useMemo(() => {
    const meetingActions = [];

    if (isAllowCreateEvents) {
      meetingActions.push({
        label: 'Создать мероприятие',
        onClick: () => openGlobalModal(GlobalModalNames.CreateUpdateEvent),
        icon: EventSvg,
      });
    }

    meetingActions.push({
      label: 'Создать встречу',
      onClick: () =>
        openGlobalModal(GlobalModalNames.CalendarCreateUpdateMeeting, {
          onSuccess: refetchOnCreateUpdateMeetingEvent,
        }),
      icon: CalendarSVG,
    });

    return meetingActions;
  }, [refetchOnCreateUpdateMeetingEvent, isAllowCreateEvents]);

  return (
    <ActionsDropdown items={items} dropdownButtonProps={{ ...rest, icon, round: true }} trigger={['click']} />
  );
};
