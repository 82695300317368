import { endpoints } from '@vkph/common/endpoints';
import { DictPaginated } from '@vkph/common/types/api';
import { EventModel, SearchParams } from '@vkph/common/types/models';
import { abstractStorageFactory, buildEndpointWithQueryParams } from '@vkph/common/utils';

export const getSearchEventStorage = () => {
  const storage = abstractStorageFactory<DictPaginated<EventModel>, EventModel[], EventModel[], SearchParams>(
    {
      endpointBuilder: ({ pageNumber, pageSize, searchString, isDraft }) =>
        buildEndpointWithQueryParams(endpoints.events.eventsList(), {
          pageNumber,
          pageSize,
          searchString,
          isDraft,
        }),
      defaultValue: [],
      dataMapper: ({ items }) => items,
      paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal }),
      cancelPendingRequestOnFetch: true,
    },
  );

  return { storage };
};
