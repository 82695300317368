import { Layout } from '@vkph/ui';
import React, { useEffect, useState, ReactElement } from 'react';

import { ErrorBoundary } from '@vkph/components/';
import { DynamicRenderComponentType } from '@vkph/modules/components/create-widget-component';
import { WidgetModule } from '@vkph/modules/types/widgetModule';

export type DynamicModuleProps = WidgetModule | Record<string, React.FC>;

export const DynamicRenderComponent: DynamicRenderComponentType<DynamicModuleProps> = (props) => {
  const { modulePath, moduleProps } = props;
  const [module, setModule] = useState<ReactElement | null>(null);
  const [isError, setIsError] = useState(false);

  const getDynamicModule = () => {
    import(/* webpackMode: "lazy", webpackChunkName: "[request]" */ `modules/${modulePath}`)
      .then(({ default: Widget }) => {
        setModule(<Widget {...moduleProps} />);
      })
      .catch((e) => {
        setModule(null);
        setIsError(true);
        // eslint-disable-next-line no-console
        console.error(e);
      });
  };

  useEffect(() => {
    getDynamicModule();
  }, [setModule, modulePath, moduleProps]);

  return <ErrorBoundary>{module || <Layout.Loading spinning={!isError} />}</ErrorBoundary>;
};
