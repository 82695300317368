import { Notification, RouterLink } from '@vkph/components';
import { UiAvatarProps, UiSpace } from '@vkph/ui';
import React, { FC } from 'react';

import { NotificationBlogInviteContentModel, NotificationModel } from '@vkph/common/types/models';
import { RouteNames, getFullNameWithoutPatronymic, getRoutePath } from '@vkph/common/utils';

import { BlogInviteActions } from './actions/BlogInviteActions';

interface Props {
  notification: NotificationModel<NotificationBlogInviteContentModel>;
}

export const NotificationBlogInvite: FC<Props> = (props) => {
  const { notification } = props;
  const {
    image,
    createdAt,
    initiator: { fullName, keycloakId, isActive },
    service: serviceTitle,
    content: { blog },
  } = notification;

  const { name, id, slug } = blog;

  const notificationTitle = serviceTitle || getFullNameWithoutPatronymic(fullName);
  const avatar = { src: image } satisfies UiAvatarProps;

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={getRoutePath(RouteNames.Profile, { id: keycloakId })}
        title={notificationTitle}
        subtitle="предложение подписаться на группу"
        avatarProps={avatar}
        isActive={isActive}
      />
      <Notification.Body>
        <UiSpace direction="vertical" size={12}>
          <RouterLink to={getRoutePath(RouteNames.GroupView, { id: slug || id })}>{name}</RouterLink>
          <BlogInviteActions notification={notification} />
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
