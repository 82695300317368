import { UiDivider, UiFloatButton, UiButton, UiIcon, UiSpace, useBreakpoint } from '@vkph/ui';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import classNames from 'classnames';
import React, { FC, PropsWithChildren, useLayoutEffect, useCallback, useState } from 'react';
import ReactDOM from 'react-dom';

import { useSpace } from '@vkph/ui/hooks';
import ArrowUpSvg from '@vkph/ui/svg/arrow-up.svg';
import CloseSVG from '@vkph/ui/svg/close.svg';

import styles from './PageOverlay.scss';

type Props = {
  visible: boolean;
  onClose?: () => void;
};

const ccn = classNames.bind(styles);

export const PageOverlay: FC<PropsWithChildren<Props>> = (props) => {
  const [portalElem, setPortalElem] = useState<HTMLDivElement | null>(null);
  const [overlayScrollElem, setOverlayScrollElem] = useState<HTMLDivElement | null>(null);
  const { visible, onClose, children } = props;
  const { spaceM, spaceL, space3XL } = useSpace();
  const { xl: isLayoutExtraLarge } = useBreakpoint();

  const onScrollRefChange = useCallback((node: HTMLDivElement | null) => {
    setOverlayScrollElem(node);
  }, []);

  const content = (
    <div className={ccn(styles.pageOverlay, { [styles.pageOverlay_visible]: visible })}>
      <div className={styles.pageOverlay__main} ref={onScrollRefChange}>
        {overlayScrollElem && isLayoutExtraLarge && (
          <UiFloatButton.BackTop
            icon={<UiIcon component={ArrowUpSvg} width={20} height={20} />}
            style={{ bottom: space3XL, right: spaceL }}
            target={() => overlayScrollElem}
          />
        )}

        <div className={styles.pageOverlay__content}>
          {!isLayoutExtraLarge && (
            <>
              <UiSpace style={{ padding: spaceM }} align="end" direction="vertical" full>
                <UiButton
                  type="link-secondary"
                  icon={<UiIcon component={CloseSVG} width={20} height={20} />}
                  onClick={onClose}
                />
              </UiSpace>
              <UiDivider emptyMargin />
            </>
          )}

          {visible ? children : null}
        </div>
      </div>
      {onClose && isLayoutExtraLarge && (
        <UiButton
          type="link-secondary"
          className={styles.pageOverlay__close}
          icon={<UiIcon component={CloseSVG} width={20} height={20} />}
          onClick={onClose}
          label="Закрыть"
        />
      )}
    </div>
  );

  useLayoutEffect(() => {
    const newPortalElem = document.createElement('div');

    document.body.appendChild(newPortalElem);

    setPortalElem(newPortalElem);

    return () => {
      document.body.removeChild(newPortalElem);
    };
  }, []);

  const enableScrolling = () => {
    if (overlayScrollElem) {
      enableBodyScroll(overlayScrollElem);
    }
  };

  useLayoutEffect(() => {
    if (overlayScrollElem && visible) {
      disableBodyScroll(overlayScrollElem);
    }

    return enableScrolling;
  }, [overlayScrollElem, visible]);

  return portalElem ? ReactDOM.createPortal(content, portalElem) : null;
};
