import { Post } from '@vkph/components';
import {
  UiCollapse,
  UiDivider,
  UiIcon,
  UiImage,
  UiRender,
  UiRenderType,
  UiSpace,
  message,
  useBreakpoint,
} from '@vkph/ui';
import React, { FC, useCallback, useMemo } from 'react';

import { useLongreadNavigation } from '@vkph/common/hooks';
import { reactionEffect } from '@vkph/common/store/reactions';
import { LongreadTypes } from '@vkph/common/types/longread';
import {
  CommentContentTypes,
  EmojiUuid,
  PostNewsModel,
  ReactionContentType,
} from '@vkph/common/types/models';
import { getErrorResponseMessage } from '@vkph/common/utils';
import { isSomePostBlogsNegativeKey } from '@vkph/components/utils';
import { useSpace } from '@vkph/ui/hooks';
import { useTheme } from '@vkph/ui/providers/theme';
import PinSvg from '@vkph/ui/svg/pin.svg';

import { getSinglePostStorage } from '../../../store/post';
import { PostCompound } from '../compound';

type Props = {
  post: PostNewsModel;
};

export const TimelineNewsPost: FC<Props> = (props) => {
  const { post } = props;
  const { id: postId, type: postType } = post;
  const { lg: isLayoutLarge } = useBreakpoint();
  const { spaceXL, spaceM } = useSpace();
  const { spaceXL: spaceXLpx } = useSpace({ unit: 'px' });

  const { openLongread } = useLongreadNavigation();

  const newsStorage = useMemo(() => getSinglePostStorage<PostNewsModel>(), []);
  const { updateCommentsCountEvent } = newsStorage;

  const [{ variables: themeVariables }] = useTheme();

  const isCommentsDisabledPostSettings = post?.settings && !post.settings.isComments;
  const isCommentsDisabledPostBlogs = isSomePostBlogsNegativeKey(post, 'isComments');
  const isCommentsDisabled = isCommentsDisabledPostSettings || isCommentsDisabledPostBlogs;
  const showComments = !isCommentsDisabled;
  const postPadding = isLayoutLarge ? spaceXL : spaceM;

  const onOpenLongread = () => {
    openLongread({
      type: LongreadTypes.News,
      id: post.id,
      newsType: post.type,
    });
  };

  const onReaction = useCallback(
    (emojiUuid?: EmojiUuid) => {
      reactionEffect({
        objectId: postId,
        contentType: ReactionContentType.News,
        emojiUuid,
      }).catch((e) =>
        message.error(
          getErrorResponseMessage(e, `Не удалось ${emojiUuid ? 'поставить' : 'удалить'} реакцию`),
        ),
      );
    },
    [postId],
  );

  const onPostCommentsCountUpdate = useCallback(
    (commentsCount: number) => updateCommentsCountEvent(commentsCount),
    [postId, postType, updateCommentsCountEvent],
  );

  return (
    <Post style={{ paddingTop: postPadding }}>
      <Post.Header
        padding={[0, postPadding]}
        author={post.author}
        postDate={post.publishedAt || post.createdAt}
        extra={
          post.timelinePinned && (
            <UiIcon component={PinSvg} width={20} height={20} color={themeVariables.colorBrand} />
          )
        }
      />
      <Post.Body padding={[spaceM, postPadding]}>
        <UiSpace size={isLayoutLarge ? 'large' : 'middle'} full direction="vertical">
          <UiCollapse.Content>
            <Post.Body.Markup
              post={post}
              onClick={onOpenLongread}
              extra={<UiImage src={post.cover} style={{ margin: `${spaceXLpx} 0` }} />}
            />
          </UiCollapse.Content>

          <Post.Body.Reactions post={post} onReaction={onReaction} />
        </UiSpace>
      </Post.Body>

      <UiRender type={UiRenderType.NullElement} visible={showComments && isLayoutLarge}>
        <UiDivider emptyMargin />
        <Post.Footer>
          <PostCompound.CommentsWithInput
            objectId={post.id}
            showInput={Boolean(post.author.isActive || post.author.keycloakUser?.isActive)}
            contentType={CommentContentTypes.NewsEntry}
            onCommentsCountUpdate={onPostCommentsCountUpdate}
          />
        </Post.Footer>
      </UiRender>
    </Post>
  );
};
