import { Notification } from '@vkph/components';
import { UiAvatarProps, UiSpace } from '@vkph/ui';
import React, { FC } from 'react';

import { useLongreadNavigation } from '@vkph/common/hooks';
import { LongreadTypes } from '@vkph/common/types/longread';
import { MicropostModel, NotificationModel } from '@vkph/common/types/models';
import {
  RouteNames,
  getFullNameWithoutPatronymic,
  getPlainTextFromHtml,
  getRoutePath,
  parseMentionsToPlainText,
} from '@vkph/common/utils';

import { getNotificationDescription } from '../../../../../plugins/notification/get-notification-description';
import { NotificationBlogPostContent } from '../../blog/post/content/NotificationBlogPostContent';

type Props = {
  notification: NotificationModel<MicropostModel>;
};

export const NotificationMicropostMention: FC<Props> = (props) => {
  const { notification } = props;
  const {
    createdAt,
    content,
    initiator: { fullName, keycloakId, isActive },
    image: initiatorAvatar,
  } = notification;
  const { body, id, type } = content;

  const plainTextContent = body?.data && getPlainTextFromHtml(body.data);
  const postText = (plainTextContent && parseMentionsToPlainText(plainTextContent)) || 'Открыть пост';

  const notificationTitle = getFullNameWithoutPatronymic(fullName);
  const notificationAvatar = { src: initiatorAvatar } satisfies UiAvatarProps;
  const notificationHeaderLink = getRoutePath(RouteNames.Profile, { id: keycloakId });
  const notificationDescription = getNotificationDescription(notification);
  const { openLongread } = useLongreadNavigation();

  const onOpenLongread = () => {
    openLongread({ type: LongreadTypes.Post, id, postType: type });
  };

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={notificationHeaderLink}
        title={notificationTitle}
        subtitle={notificationDescription}
        avatarProps={notificationAvatar}
        isActive={isActive}
      />
      <Notification.Body>
        <UiSpace size={12} direction="vertical" full>
          <NotificationBlogPostContent visible content={postText} onClick={onOpenLongread} />
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
