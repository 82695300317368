// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c00a551f_weather__daily{flex:1;display:flex;justify-content:space-between;margin-top:24px}.c00a551f_weather__daily-mock{display:flex;flex-direction:column}", "",{"version":3,"sources":["webpack://./../../libs/modules/src/components/weather/Weather.scss"],"names":[],"mappings":"AAEE,yBACE,MAAA,CACA,YAAA,CACA,6BAAA,CACA,eAAA,CAEA,8BACE,YAAA,CACA,qBAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.weather {\n  &__daily {\n    flex: 1;\n    display: flex;\n    justify-content: space-between;\n    margin-top: 24px;\n\n    &-mock {\n      display: flex;\n      flex-direction: column;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"weather__daily": "c00a551f_weather__daily",
	"weather__daily-mock": "c00a551f_weather__daily-mock",
	"weather__dailyMock": "c00a551f_weather__daily-mock"
};
export default ___CSS_LOADER_EXPORT___;
