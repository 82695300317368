import { UiImageBackground, UiOverlay, UiOverlayTypes } from '@vkph/ui';
import React, { CSSProperties, FC, PropsWithChildren, useState } from 'react';

import { useTheme } from '@vkph/ui/providers/theme';

const EVENT_IMAGE_HEIGHT = 460;

type Props = {
  image?: string;
  standalone?: boolean;
};

export const EventImage: FC<PropsWithChildren<Props>> = (props) => {
  const { image, children, standalone } = props;
  const [{ variables: themeVariables }] = useTheme();
  const [isOverlayHovered, setIsOverlayHovered] = useState(false);
  const onMouseEnter = () => setIsOverlayHovered(true);
  const onMouseLeave = () => setIsOverlayHovered(false);

  const overlayStyles: CSSProperties = {
    height: EVENT_IMAGE_HEIGHT,
    color: themeVariables.colorTextOnBrand,
  };
  const backgroundImageStyles: CSSProperties = {
    height: EVENT_IMAGE_HEIGHT,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: '.4s ease-out transform',
    ...(isOverlayHovered && standalone
      ? {
          transform: 'scale(1.1)',
        }
      : null),
  };

  return (
    <UiOverlay
      type={UiOverlayTypes.Primary}
      content={children}
      style={overlayStyles}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <UiImageBackground style={backgroundImageStyles} url={image || ''} />
    </UiOverlay>
  );
};
