import {
  CropImageArea,
  CropImageParams,
  FigureType,
  UploadDraggerArea,
  UploadDraggerAreaProps,
  getImageDimensions,
} from '@vkph/components';
import {
  UiButton,
  UiList,
  UiModal,
  UiRender,
  UiRenderType,
  UiSpinner,
  UiTypography,
  isUiUploadOriginFile,
  message,
} from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, PropsWithChildren, useMemo, useState } from 'react';

import {
  FileStorageApiVersions,
  uploadFileStorageAttachmentEffectFactory,
  uploadImageCropEffect,
} from '@vkph/common/store/filestorage';
import { GlobalModalNames, GlobalModalsStorePayloads } from '@vkph/common/store/global-modals';
import { ImageUploadCropDescription, ImageUploadCropValue } from '@vkph/common/types/imageUploadCrop';
import { FileToUploadModel, PreloadedFileModel } from '@vkph/common/types/models';
import {
  FileUploadAccepts,
  checkFileMimeType,
  checkFileSizeUploadEffect,
  getErrorResponseMessage,
  readFileToStringPromise,
} from '@vkph/common/utils';
import { getModalStepsForSingleTitle } from '@vkph/ui/utils';

export type ImageUploadCropProps = GlobalModalsStorePayloads[GlobalModalNames.ImageUploadCrop]['payload'];
type UploadAttachmentFactoryParams = Pick<ImageUploadCropProps, 'minWidth' | 'minHeight' | 'maxSize'>;

const MAX_SIZE_DEFAULT = 10;
const MIN_WIDTH_DEFAULT = 764;
const MIN_HEIGHT_DEFAULT = 432;
const uploadAttachmentEffect = uploadFileStorageAttachmentEffectFactory<PreloadedFileModel>(
  FileStorageApiVersions.v3,
);

export const uploadAttachmentEffectFactory: (
  params: UploadAttachmentFactoryParams,
) => (
  params: Parameters<Required<Pick<UploadDraggerAreaProps, 'onUploadAttachment'>>['onUploadAttachment']>[0],
) => Promise<Required<Pick<ImageUploadCropProps, 'value'>>['value']> =
  ({ minWidth = MIN_WIDTH_DEFAULT, minHeight = MIN_HEIGHT_DEFAULT, maxSize = MAX_SIZE_DEFAULT }) =>
  async (params) => {
    const { file } = params;

    if (!isUiUploadOriginFile(file)) {
      throw new Error('Неверный формат загрузки файла');
    }

    const [, previewImage] = await Promise.all([
      checkFileSizeUploadEffect({ file, maxSize }),
      readFileToStringPromise(file, true),
    ]);

    const { width, height } = await getImageDimensions(previewImage.data);

    if (width < minWidth || height < minHeight) {
      throw new Error(`Минимальный размер фото ${minWidth} × ${minHeight} пикселей.`);
    }

    const { storageObject, fileUrl } = await uploadAttachmentEffect({ file });

    return { previewImage, uploadImage: { id: storageObject, file: fileUrl } };
  };

export const ImageUploadCrop: FC<PropsWithChildren<ImageUploadCropProps>> = (props) => {
  const {
    aspect = 1,
    title,
    value,
    onChange,
    onClose,
    children,
    maxSize = MAX_SIZE_DEFAULT,
    minWidth = MIN_WIDTH_DEFAULT,
    minHeight = MIN_HEIGHT_DEFAULT,
    description,
    figureType = FigureType.Rectangle,
    uploadAreaProps,
  } = props;

  const descriptionValue = useMemo<ImageUploadCropDescription>(() => {
    if (!description) {
      return {
        rulesTitle: 'Загрузите изображение и убедитесь, что оно соответствует правилам:',
        rulesList: [
          `• минимальный размер изображения ${minWidth} × ${minHeight} пикселей;`,
          `• максимальный вес файла ${maxSize} мегабайт.`,
        ],
        cropTitle: 'Область изображения',
      };
    }

    return description;
  }, [description, minHeight, minWidth, maxSize]);

  const { rulesTitle, rulesList, cropTitle } = descriptionValue;
  const [uploadImage, setUploadImage] = useState<ImageUploadCropValue | undefined>(value?.uploadImage);
  const [previewImage, setPreviewImage] = useState<FileToUploadModel | undefined>(value?.previewImage);
  const [settings, setSettings] = useState<CropImageParams>();

  const fileType = previewImage?.rawFile?.type;
  const isCropImageDisabled = useMemo(
    () => Boolean(fileType && checkFileMimeType(FileUploadAccepts.ImageSvg, fileType)),
    [fileType],
  );
  const figureSettings = useMemo(
    () => ({ aspect, setSettings, title: cropTitle }),
    [aspect, setSettings, cropTitle],
  );

  const onUploadAttachment: UploadDraggerAreaProps['onUploadAttachment'] = async (params) => {
    try {
      const uploadAttachment = uploadAttachmentEffectFactory({ maxSize, minWidth, minHeight });
      const uploadedAttachment = await uploadAttachment(params);

      setUploadImage(uploadedAttachment.uploadImage);
      setPreviewImage(uploadedAttachment.previewImage);
    } catch (e) {
      message.error(String(e));
    }
  };

  const onSaveSuccess = (newValue: ImageUploadCropValue) => {
    onChange(newValue);
    onClose();
  };

  const onSave = () => {
    if (!isCropImageDisabled && uploadImage?.id && settings) {
      uploadImageCropEffect({ fileStorageEntryId: uploadImage.id, settings })
        .then(({ id, file }) => onSaveSuccess({ id, file }))
        .catch((err) =>
          message.error(getErrorResponseMessage(err, 'Ошибка при сохранении области изображения')),
        );
    } else if (isCropImageDisabled && uploadImage) {
      onSaveSuccess(uploadImage);
    } else {
      onClose();
    }
  };

  const isUploadAttachmentPending = useStore(uploadAttachmentEffect.pending);
  const isUploadImageCropPending = useStore(uploadImageCropEffect.pending);

  return (
    <>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle(title)} />
      </UiModal.Header>

      <UiModal.Content basePadding>
        <UiSpinner spinning={isUploadAttachmentPending}>
          {rulesTitle && <UiTypography.Title level={4}>{rulesTitle}</UiTypography.Title>}
          {rulesList.length > 0 && (
            <UiList
              split={false}
              dataSource={rulesList}
              renderItem={(rule) => (
                <UiList.Item noStyle>
                  <UiTypography.Paragraph>{rule}</UiTypography.Paragraph>
                </UiList.Item>
              )}
            />
          )}

          {!value && (
            <UploadDraggerArea
              multiple={false}
              style={{ marginTop: 24 }}
              loading={isUploadAttachmentPending}
              accept={FileUploadAccepts.ImagePngJpg}
              onUploadAttachment={onUploadAttachment}
              {...uploadAreaProps}
            />
          )}

          {previewImage && figureSettings && !isUploadAttachmentPending && (
            <CropImageArea.Wrapper>
              {figureSettings.title && <CropImageArea.Title title={figureSettings.title} />}
              <CropImageArea.Figure
                minWidth={minWidth}
                minHeight={minHeight}
                imageFile={previewImage}
                figureType={figureType}
                figureSettings={figureSettings}
                disabled={isCropImageDisabled}
              />
            </CropImageArea.Wrapper>
          )}

          <UiRender type={UiRenderType.DisplayNone} visible={!previewImage}>
            {children}
          </UiRender>
        </UiSpinner>
      </UiModal.Content>

      <UiModal.Footer>
        <UiModal.Footer.Buttons>
          <UiButton
            size="large"
            type="primary"
            label="Сохранить"
            onClick={onSave}
            loading={isUploadImageCropPending}
          />
          <UiButton size="large" type="tertiary" label="Отмена" onClick={onClose} />
        </UiModal.Footer.Buttons>
      </UiModal.Footer>
    </>
  );
};
