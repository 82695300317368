import { UiTable, UiTableProps } from '@vkph/ui';
import classnames from 'classnames';
import React, { FC } from 'react';

import styles from './SlotsTable.scss';
import { SlotsTableSelection, SlotsTableSelectionComponent } from './selection/SlotsTableSelection';
import { SlotsTableSlot, SlotsTableSlotComponent } from './slot/SlotsTableSlot';

type SlotsTableComposition = {
  Slot: SlotsTableSlotComponent;
  Selection: SlotsTableSelectionComponent;
};

interface SlotsTableProps extends UiTableProps {
  isUserActive?: boolean;
}

export const SlotsTable: FC<SlotsTableProps> & SlotsTableComposition = (props) => {
  const { className, isUserActive = true, ...restProps } = props;

  return (
    <>
      <UiTable
        scroll={{ x: 'max-content' }}
        sticky
        {...restProps}
        pagination={false}
        className={classnames(styles.slotsTable, className)}
        disabled={!isUserActive}
      />
    </>
  );
};

SlotsTable.Slot = SlotsTableSlot;
SlotsTable.Selection = SlotsTableSelection;
