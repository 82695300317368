// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._66020b5e_news-widget__button{margin-top:16px}", "",{"version":3,"sources":["webpack://./../../libs/modules/src/components/news-widget/NewsWidget.scss"],"names":[],"mappings":"AAEE,+BACE,eAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.news-widget {\n  &__button {\n    margin-top: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"news-widget__button": "_66020b5e_news-widget__button",
	"newsWidget__button": "_66020b5e_news-widget__button"
};
export default ___CSS_LOADER_EXPORT___;
