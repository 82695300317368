import { UiBadge, message, UiSkeleton, UiTypography } from '@vkph/ui';
import classnames from 'classnames';
import { useStore } from 'effector-react';
import React, { HTMLAttributes, useEffect } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { CommentListStorage } from '@vkph/common/store/comments';
import { reactionEffect } from '@vkph/common/store/reactions';
import {
  CommentContentTypes,
  CommentId,
  CommentObjectId,
  EmojiUuid,
  ReactionContentType,
} from '@vkph/common/types/models';
import { getErrorResponseMessage } from '@vkph/common/utils';

import { CommentInput, CommentInputProps } from '../comment-input';
import { CommentList } from '../comment-list';
import styles from './CommentListWithInput.scss';

const DEFAULT_MAX_SHIFT_LEVEL = 5;

export interface CommentListWithInputProps
  extends Pick<HTMLAttributes<HTMLDivElement>, 'style'>,
    Pick<CommentInputProps, 'isSuggestionsForceUp' | 'isPositionBody'> {
  objectId: CommentObjectId;
  contentType: CommentContentTypes;
  commentsStorage: CommentListStorage;
  containerClassName?: string;
  hideHeading?: boolean;
  onCommentsCountUpdate?: (countDiff: number) => void;
  maxNestLevelWithShift?: number;
  placeholderText?: string;
  placeholderEditText?: string;
  showInput?: boolean;
}

export const CommentListWithInput: React.FC<CommentListWithInputProps> = (props) => {
  const {
    objectId,
    contentType,
    commentsStorage,
    containerClassName,
    hideHeading,
    onCommentsCountUpdate,
    placeholderText,
    placeholderEditText,
    maxNestLevelWithShift = DEFAULT_MAX_SHIFT_LEVEL,
    showInput = true,
    style,
    isSuggestionsForceUp,
    isPositionBody,
  } = props;
  const { createCommentEffect, updateCommentEffect, deleteCommentEffect } = commentsStorage;
  const createCommentInProgress = useStore(createCommentEffect.pending);
  const updateCommentInProgress = useStore(updateCommentEffect.pending);
  const deleteCommentInProgress = useStore(deleteCommentEffect.pending);
  const commentActionInProgress =
    createCommentInProgress || deleteCommentInProgress || updateCommentInProgress;
  const {
    data: comments,
    loading: currentlyLoading,
    pagination: { count = 0 },
    fetchRequestCount,
    refetchWithLastParams,
  } = useAbstractStorage(commentsStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { contentType, objectId },
    cancelPendingRequestOnUnmount: true,
    resetStoreOnUnmount: true,
  });
  const isFirstLoad = fetchRequestCount <= 1;
  const shouldShowSkeleton = currentlyLoading && isFirstLoad;

  useEffect(() => {
    if (onCommentsCountUpdate) {
      onCommentsCountUpdate(count);
    }
  }, [count]);

  const hasComments = comments.length > 0;
  const shouldShowComments = currentlyLoading || hasComments;
  const shouldShowCommentsHeading = !hideHeading && shouldShowComments;

  const onComment = (text: string, parentId: CommentId | null) =>
    createCommentEffect({ objectId, contentType, text, parentId })
      .then(refetchWithLastParams)
      .catch((e) => message.error(getErrorResponseMessage(e, `Не удалось создать комментарий`)));

  const onUpdateComment = (commentId: CommentId, text: string) =>
    updateCommentEffect({ objectId, contentType, commentId, text }).catch((e) =>
      message.error(getErrorResponseMessage(e, `Не удалось изменить комментарий`)),
    );

  const onDeleteComment = (commentId: CommentId) => {
    deleteCommentEffect({ commentId }).catch((e) =>
      message.error(getErrorResponseMessage(e, `Не удалось удалить комментарий`)),
    );
  };

  const onReaction = (commentId: CommentId, emojiUuid?: EmojiUuid) => {
    reactionEffect({ objectId: commentId, contentType: ReactionContentType.Comment, emojiUuid }).catch((e) =>
      message.error(getErrorResponseMessage(e, `Не удалось ${emojiUuid ? 'поставить' : 'удалить'} реакцию`)),
    );
  };

  const onActualSend = (text: string, parentId: number | null = null) => onComment(text, parentId);

  return (
    <div className={styles.commentsWithInput}>
      {shouldShowCommentsHeading && (
        <div style={style} className={classnames(styles.commentsWithInput__heading, containerClassName)}>
          <UiTypography.Title level={3}>
            <UiSkeleton loading={shouldShowSkeleton} height={21}>
              Комментарии
              <UiBadge className={styles.commentsWithInput__count} count={count} />
            </UiSkeleton>
          </UiTypography.Title>
        </div>
      )}

      {shouldShowComments && (
        <div style={style} className={classnames(styles.commentsWithInput__comments, containerClassName)}>
          <UiSkeleton
            loading={shouldShowSkeleton}
            height={100}
            className={styles.commentsWithInput__skeletonComment}
          >
            <CommentList
              comments={comments}
              showCount={1}
              maxLevel={1}
              maxNestLevelWithShift={maxNestLevelWithShift}
              onSend={onActualSend}
              loading={commentActionInProgress}
              onCommentEdit={onUpdateComment}
              onCommentDelete={onDeleteComment}
              onReaction={onReaction}
            />
          </UiSkeleton>
        </div>
      )}
      {showInput && (
        <div
          style={style}
          className={classnames(containerClassName, styles.commentsWithInput__input, {
            [styles.commentsWithInput__inputBorder]: hasComments,
          })}
        >
          <UiSkeleton loading={currentlyLoading} height={40}>
            <CommentInput
              onSend={onActualSend}
              loading={commentActionInProgress}
              placeholderText={placeholderText}
              placeholderEditText={placeholderEditText}
              isSuggestionsForceUp={isSuggestionsForceUp}
              isPositionBody={isPositionBody}
            />
          </UiSkeleton>
        </div>
      )}
    </div>
  );
};
