import { UiSkeleton } from '@vkph/ui';
import React, { FC } from 'react';

import { createArrayMock } from '@vkph/common/utils';

import styles from '../current/WeatherCurrent.scss';

interface Prop {
  maxCount: number;
}

export const WeatherLoading: FC<Prop> = (props) => {
  const { maxCount } = props;

  return (
    <>
      {createArrayMock(maxCount, (_, id) => (
        <span key={id} className={styles.weather__dailyMock}>
          <UiSkeleton loading width={52} height={14} />
          <UiSkeleton circle loading width={52} height={50} />
          <UiSkeleton loading width={52} height={14} />
        </span>
      ))}
    </>
  );
};
