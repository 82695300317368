import { RouterLink } from '@vkph/components';
import { UiList, UiTypography } from '@vkph/ui';
import React, { FC } from 'react';

import { RouteNames, getRoutePath } from '@vkph/common/utils';

export const NavbarMobile: FC = () => {
  const items = [{ title: 'Новости', to: getRoutePath(RouteNames.News) }];

  return (
    <UiList
      dataSource={items}
      size="small"
      split={false}
      renderItem={(item) => (
        <UiList.Item>
          <RouterLink to={item.to}>
            <UiTypography.Text strong>{item.title}</UiTypography.Text>
          </RouterLink>
        </UiList.Item>
      )}
    />
  );
};
