import { endpoints } from '@vkph/common/endpoints';
import { EditorValue } from '@vkph/common/types/editor';
import {
  UserIdModel,
  BlogId,
  PostTypes,
  PostSettings,
  PostId,
  PostStatuses,
  BasicPostAttachmentModel,
  PostBasicModel,
  TagId,
  TimelineSettingsAllModel,
} from '@vkph/common/types/models';
import { api } from '@vkph/common/utils';

export type FetchPostParams = { postId: PostId; postType: PostTypes };

export type CreatePostParams = {
  blogId?: BlogId;
  toUser?: UserIdModel;
  postType: PostTypes;
  tags: TagId[];
  entitiesToAttach: Omit<BasicPostAttachmentModel, 'attachedEntity'>[];
  body?: EditorValue;
  settings?: PostSettings;
};

export type UpdatePostBaseParams = {
  postId: PostId;
  postType: PostTypes;
};

export interface UpdatePostParams extends UpdatePostBaseParams {
  tags: TagId[];
  entitiesToAttach: Omit<BasicPostAttachmentModel, 'attachedEntity'>[];
  settings?: PostSettings;
  body?: EditorValue;
}

export interface ApprovePostParams extends UpdatePostBaseParams {
  flag: PostStatuses;
}

export type DeletePostParams = { postId: number; postType: PostTypes; statusComment?: string };
export type SwitchFavoritePostParams = { postId: PostId; postType: PostTypes; favorite: boolean };
export type SwitchPinPostParams = { postId: PostId; isPinned: boolean };
export type UpdateCommentsCountEventParams = { postId: PostId; postType: PostTypes; commentsCount: number };
export type UpdateFavoriteResponse = { favorite: boolean };

export type SinglePostEndpointParams = {
  postId: PostId;
  postType: PostTypes;
};

export const getSinglePostEndpoint = ({ postId, postType }: SinglePostEndpointParams) => {
  if (postType === PostTypes.Entry) {
    return endpoints.asyncBlogs.blogEntryId(postId);
  }

  if (postType === PostTypes.Simple || postType === PostTypes.Stories) {
    return endpoints.news.newsId(postId);
  }

  if (postType === PostTypes.GamificationEntry) {
    return endpoints.blogs.blogGamificationEntryId(postId);
  }

  return endpoints.asyncBlogs.micropostEntityId(postId);
};

const getCreatePostEndpoint = ({ postType }: { postType: PostTypes }) => {
  if (postType === PostTypes.Entry) {
    return endpoints.asyncBlogs.blogEntryId();
  }

  return endpoints.asyncBlogs.micropostEntityId();
};

export const createPost = <T extends PostBasicModel>(params: CreatePostParams) => {
  const { blogId, postType, ...data } = params;

  return api.post<T>({
    url: getCreatePostEndpoint({ postType }),
    data: {
      blogs: blogId ? [blogId] : undefined,
      ...data,
    },
  });
};

export const updatePost = <T extends UpdatePostBaseParams, D>({ postId, postType, ...data }: T) =>
  api.patch<D>({
    url: getSinglePostEndpoint({ postId, postType }),
    data: {
      ...data,
    },
  });

export const deletePost = ({ postId, postType, statusComment = 'Delete post' }: DeletePostParams) =>
  api.delete({
    url: getSinglePostEndpoint({ postId, postType }),
    data: { statusComment },
  });

export const switchFavoritePost = ({ postId, favorite, postType }: SwitchFavoritePostParams) =>
  api.post<UpdateFavoriteResponse>({
    url: endpoints.asyncBlogs.postContentTypeFavoriteStatusId(postType, postId),
    data: { favorite },
  });

export const switchPinPost = ({ postId, isPinned }: SwitchPinPostParams) =>
  api.patch<UpdateFavoriteResponse>({
    url: endpoints.asyncBlogs.entryIdChangePinStatus(postId),
    data: { pinStatus: isPinned },
  });

export const updatePostsSettings = (data: TimelineSettingsAllModel) =>
  api.put<TimelineSettingsAllModel>({
    url: endpoints.timelines.settingsAll(),
    data,
  });
