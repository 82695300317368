import React from 'react';

import { SearchListItem, SearchCategoryType } from '@vkph/common/types/models';

import { SearchCategory } from '../../../store/search/advanced-search/constants';
import { SearchItemProfile } from './SearchItemProfile';

type Props = {
  item: SearchListItem;
  origin: SearchCategoryType;
};

export const SearchItemAdapter: React.FC<Props> = (props) => {
  const { item, origin } = props;

  if (origin === SearchCategory.Profile) {
    return <SearchItemProfile profile={item} />;
  }

  return null;
};
