import { UiButton, UiIcon, message, UiSpace, UiTypographyTextTypes } from '@vkph/ui';
import React, { FC } from 'react';

import { updateUserStatusEffect } from '@vkph/common/store/calendar/event';
import { notificationsStore } from '@vkph/common/store/notifications';
import { CalendarEventModel, CalendarUserStatus, NotificationModel } from '@vkph/common/types/models';
import { getErrorResponseMessage } from '@vkph/common/utils';
import { variables } from '@vkph/ui/providers/theme';
import ErrorSvg from '@vkph/ui/svg/error.svg';
import HintSvg from '@vkph/ui/svg/hint.svg';
import SuccessSvg from '@vkph/ui/svg/success.svg';

type Props = {
  notification: NotificationModel<CalendarEventModel>;
};

export const CalendarEventActions: FC<Props> = (props) => {
  const { notification } = props;
  const { myStatus, id, isDeleted } = notification.content;

  const onAccept = () => {
    updateUserStatusEffect({ [CalendarUserStatus.Accepted]: [id] })
      .then(() => {
        message.success('Приглашение принято');
        notificationsStore.refetchWithLastParams();
      })
      .catch((e) => getErrorResponseMessage(e, 'Не удалось принять приглашение'));
  };

  const onReject = () => {
    updateUserStatusEffect({ [CalendarUserStatus.Rejected]: [id] })
      .then(() => {
        message.success('Приглашение отклонено');
        notificationsStore.refetchWithLastParams();
      })
      .catch((e) => getErrorResponseMessage(e, 'Не удалось отклонить приглашение'));
  };

  const onDecideMaybe = () => {
    updateUserStatusEffect({ [CalendarUserStatus.Maybe]: [id] })
      .then(() => {
        message.success('Приглашение принято');
        notificationsStore.refetchWithLastParams();
      })
      .catch((e) => getErrorResponseMessage(e, 'Не удалось принять приглашение'));
  };

  const isCalendarEventStatus = (status: CalendarUserStatus) => status === myStatus;
  const negativeLabelProps = {
    strong: true,
    component: ErrorSvg,
    color: variables.themePalette.colorNegative,
    type: UiTypographyTextTypes.Primary,
    style: { color: variables.themePalette.colorNegative },
  };

  if (!isDeleted && isCalendarEventStatus(CalendarUserStatus.NotAttend)) {
    return <UiIcon.Label {...negativeLabelProps} label="Вас исключили из встречи" />;
  }

  if (isDeleted) {
    return <UiIcon.Label {...negativeLabelProps} label="Встреча отменена" />;
  }

  if (isCalendarEventStatus(CalendarUserStatus.Accepted)) {
    return (
      <UiIcon.Label
        strong
        label="Вы идете"
        component={SuccessSvg}
        color={variables.themePalette.colorSuccess}
        type={UiTypographyTextTypes.Primary}
        style={{ color: variables.themePalette.colorSuccess }}
      />
    );
  }

  if (isCalendarEventStatus(CalendarUserStatus.Rejected)) {
    return <UiIcon.Label {...negativeLabelProps} label="Вы не идете" />;
  }

  if (isCalendarEventStatus(CalendarUserStatus.Maybe)) {
    return (
      <UiIcon.Label
        strong
        component={HintSvg}
        label="Вы возможно пойдете"
        type={UiTypographyTextTypes.Secondary}
      />
    );
  }

  return (
    <UiSpace align="center">
      <UiButton label="Пойду" type="primary" onClick={onAccept} />
      <UiButton label="Не иду" type="tertiary" onClick={onReject} />
      <UiButton label="Может быть" type="tertiary" onClick={onDecideMaybe} />
    </UiSpace>
  );
};
