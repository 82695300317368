import { RouterLink } from '@vkph/components';
import { UiImage, UiRow, useBreakpoint } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC } from 'react';

import { headerLogoImageStorage } from '@vkph/common/store/header-logo-image';
import { RouteNames, getRoutePath } from '@vkph/common/utils';

export const HeaderLogo: FC = () => {
  const breakpoints = useBreakpoint();

  const {
    data: { desktop, mobile },
  } = useStore(headerLogoImageStorage.storage.store);

  if (breakpoints.xl) {
    return (
      <UiRow justify="center" align="middle" wrap={false} style={{ width: 216, marginRight: 22 }}>
        <RouterLink to={getRoutePath(RouteNames.Home)}>
          <UiImage
            placeholder={<UiRow style={{ width: 199, height: 70 }} />}
            transparent
            src={desktop?.file}
            style={{ maxWidth: 199, maxHeight: 70 }}
          />
        </RouterLink>
      </UiRow>
    );
  }

  return (
    <RouterLink to={getRoutePath(RouteNames.Home)}>
      <UiImage
        placeholder={<UiRow style={{ width: 32, height: 32 }} />}
        src={mobile?.file}
        style={{ maxWidth: 32, maxHeight: 70 }}
      />
    </RouterLink>
  );
};
