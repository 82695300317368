import { Markdown } from '@vkph/components';
import { UiModal } from '@vkph/ui';
import React from 'react';

import { getModalStepsForSingleTitle } from '@vkph/ui/utils';

import styles from './TextFormatHelpModal.scss';

type Props = {
  onClose: () => void;
};

const guides = [
  {
    title: '**Жирный шрифт**',
    value: '**текст**',
  },
  {
    title: '*Курсив*',
    value: '*текст*',
  },
  {
    title: '~~Зачеркнутый~~',
    value: '~~текст~~',
  },
  {
    title: '[Ссылка](https://site.ru)',
    value: '[название](https://site.ru)',
  },
  {
    title: '* Маркированный список',
    value: '* элемент',
  },
  {
    title: '1. Нумерованный список',
    value: '1. элемент',
  },
  {
    title: 'Строка кода',
    value: '`код`',
  },
  {
    title: 'Блок кода',
    value: `\`\`\`
console.log("сode block");
\`\`\``,
  },
  {
    title: 'Таблица',
    value: `| Заголовок | Заголовок | Заголовок |
| --- | --- | --- |
| Ячейка | Ячейка | Ячейка |`,
  },
];

export const TextFormatHelp: React.FC<Props> = () => {
  return (
    <>
      <UiModal.Header>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle('Справка по форматированию текста')} />
      </UiModal.Header>

      <UiModal.Content className={styles.postFormHelpModal__content}>
        {guides.map(({ title, value }) => (
          <div key={title} className={styles.postFormHelpModal__row}>
            <div className={styles.postFormHelpModal__leftCol}>
              <Markdown>{title}</Markdown>
            </div>
            <div className={styles.postFormHelpModal__rightCol}>{value}</div>
          </div>
        ))}
      </UiModal.Content>
    </>
  );
};
