import { UiRow, UiIcon, UiSpace, UiTruncateMarkup, UiTypography, UiTypographyTextTypes } from '@vkph/ui';
import React, { FC } from 'react';

import { FileStorageEntryVersionModel } from '@vkph/common/types/models';
import { variables } from '@vkph/ui/providers/theme';
import CommentOutlineSvg from '@vkph/ui/svg/comment-outline.svg';

export const FileVersionsPanelHeader: FC<FileStorageEntryVersionModel> = (props) => {
  const { revision, name, commentsCount, isDeleted } = props;

  return (
    <UiSpace size={16}>
      <UiRow>
        <UiTypography.Text
          strong
          type={isDeleted ? UiTypographyTextTypes.Danger : UiTypographyTextTypes.Primary}
        >
          <UiTruncateMarkup truncate>{`Версия ${revision} — ${
            isDeleted ? `Удалено: ${name}` : name
          }`}</UiTruncateMarkup>
        </UiTypography.Text>
      </UiRow>
      {commentsCount > 0 && (
        <UiIcon.Label
          label={commentsCount.toString()}
          type={UiTypographyTextTypes.Primary}
          strong
          component={CommentOutlineSvg}
          color={variables.themePalette.colorIcon}
        />
      )}
    </UiSpace>
  );
};
