import { UiAvatar, UiDivider, UiTruncateMarkup, UiTypography } from '@vkph/ui';
import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';
import React from 'react';
import { Link } from 'react-router-dom';

import { useLongreadLink } from '@vkph/common/hooks';
import { LongreadTypes } from '@vkph/common/types/longread';
import { NewsArticle, NewsCategoriesDist, PostTypes } from '@vkph/common/types/models';

import styles from '../result-lists/ResultLists.scss';

type NewsListItemProps = {
  newsCategoriesDist: NewsCategoriesDist | null;
  newsFeed: NewsArticle;
};

export const NewsListItem: React.FC<NewsListItemProps> = (props) => {
  const { newsCategoriesDist, newsFeed } = props;
  const linkProps = useLongreadLink({
    type: LongreadTypes.News,
    id: newsFeed.id,
    newsType: newsFeed.type || PostTypes.Simple,
  });

  return (
    <div className={styles.resultListItem}>
      <UiAvatar src={newsFeed.cover} size={40} />
      <Link tabIndex={-1} className={styles.resultListItemDescription} {...linkProps}>
        <UiTypography.Title>
          <UiTruncateMarkup
            className={styles.resultListItemDescription__title}
            truncate
            tooltipProps={{ title: newsFeed.title }}
          >
            {newsFeed.title}
          </UiTruncateMarkup>
        </UiTypography.Title>

        <UiTypography.Text type="secondary">
          <UiTruncateMarkup truncate>
            {format(new Date(newsFeed.publishedAt), 'dd MMMM, H:mm', { locale: ru })}
            <UiDivider.Dot type="secondary" />
            {(newsFeed.categoryId && newsCategoriesDist?.[newsFeed.categoryId]?.name) ?? 'Без категории'}
          </UiTruncateMarkup>
        </UiTypography.Text>
      </Link>
    </div>
  );
};
