import { useBreakpoint, UiEmpty } from '@vkph/ui';
import React, { FC, useMemo } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { LongreadBaseProps, TimelineLongreadParams } from '@vkph/common/types/longread';
import { useSpace } from '@vkph/ui/hooks';

import { getTimelineRecordStorage } from '../../../store/timeline';
import { TimelineCard } from '../../timeline-card/TimelineCard';
import { LongreadLoading } from '../loading/LongreadLoading';

type Props = LongreadBaseProps & TimelineLongreadParams;

export const TimelineLongread: FC<Props> = (props) => {
  const { id } = props;
  const { storage: timelineRecordStorage } = useMemo(getTimelineRecordStorage, []);
  const {
    data: timelineRecordData,
    loading: isTimelineRecordLoading,
    error,
  } = useAbstractStorage(timelineRecordStorage, {
    autoFetchAndRefetch: true,
    cancelPendingRequestOnUnmount: true,
    resetStoreOnUnmount: true,
    autoFetchParams: { id },
  });
  const { lg: isLayoutLarge } = useBreakpoint();
  const { space2XL, space4XL, spaceM } = useSpace();

  const isError = (!timelineRecordData && !isTimelineRecordLoading) || error;

  return (
    <>
      {isTimelineRecordLoading && <LongreadLoading />}
      {isError && <UiEmpty.Feed emptyMessage={{ header: 'Не удалось загрузить запись' }} />}
      {timelineRecordData && (
        <TimelineCard
          gutter={isLayoutLarge ? [space2XL, space4XL] : [spaceM, spaceM]}
          record={timelineRecordData}
        />
      )}
    </>
  );
};
