import { MultiSelectAvatarLabelDataType, UserAvatar } from '@vkph/components';
import {
  UiCell,
  UiButton,
  UiAvatar,
  UiForm,
  message,
  UiModal,
  UiModalProps,
  UiSelect,
  UiSelectDefaultOption,
  UiSpace,
  UiTypography,
} from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useAbstractStorage } from '@vkph/common/hooks';
import {
  getFileStorageFolderUserRoles,
  SetFileStorageFolderUserRoleParams,
} from '@vkph/common/store/filestorage';
import { useProfileOptionsListStorage } from '@vkph/common/store/profile/hooks';
import {
  UserIdModel,
  FileStorageFolderPermissionTypes,
  FileStorageFolderUserRoleModel,
  FileStorageFolderUserRoles,
  FileStorageFolderUserRolesModel,
  FileStorageListEntryModel,
  UserAvatarModel,
} from '@vkph/common/types/models';
import { getFullNameWithoutPatronymic } from '@vkph/common/utils';
import { OptionModel } from '@vkph/ui/types/option';
import { getModalStepsForSingleTitle } from '@vkph/ui/utils';

import { MultiSelectField } from '../../../multiselect-field/MultiSelectField';

type RolesDateType = Omit<MultiSelectAvatarLabelDataType, 'isRejected'>;
type RolesOption = OptionModel<UserIdModel, RolesDateType>;
type RolesItemContent = (params: RolesOption) => JSX.Element;

const getUserRoles = (roles: FormValues['userRoles']) => {
  return roles.map((selectedUser) => ({
    userUid: selectedUser.value,
    role: FileStorageFolderUserRoles.Admin,
  }));
};

const isPermissionType = (
  type: FileStorageFolderPermissionTypes,
  expectedType: FileStorageFolderPermissionTypes,
) => type === expectedType;

const getResponseParamsFromFormValues = (
  formValues: FormValues,
): Omit<SetFileStorageFolderUserRoleParams, 'entryId'> => {
  if (isPermissionType(formValues.permissionType, FileStorageFolderPermissionTypes.Some)) {
    return {
      defaultRole: FileStorageFolderUserRoles.ReadOnlyFullOwner,
      userRoles: getUserRoles(formValues.userRoles),
      eraseExisting: true,
    };
  }

  if (isPermissionType(formValues.permissionType, FileStorageFolderPermissionTypes.Nobody)) {
    return {
      defaultRole: FileStorageFolderUserRoles.ReadOnlyFullOwner,
      userRoles: [],
      eraseExisting: true,
    };
  }

  return {
    defaultRole: FileStorageFolderUserRoles.Admin,
    userRoles: [],
    eraseExisting: true,
  };
};

const getPermissionTypeByUserRoles = (roles: FileStorageFolderUserRolesModel) => {
  if (roles.defaultRole === FileStorageFolderUserRoles.Admin) {
    return FileStorageFolderPermissionTypes.Anyone;
  }

  if (roles.userRoles?.length) {
    return FileStorageFolderPermissionTypes.Some;
  }

  return FileStorageFolderPermissionTypes.Nobody;
};

const userRoleToOption = (user: FileStorageFolderUserRoleModel): RolesOption => {
  const { firstName, lastName, userUid, avatar } = user;
  const currentFullName = getFullNameWithoutPatronymic({ firstName, lastName });

  return {
    value: userUid,
    data: {
      label: <UiCell avatar={<UserAvatar src={avatar} />} title={currentFullName} />,
      selectedLabel: currentFullName,
      avatar,
    },
  };
};

const options: UiSelectDefaultOption[] = [
  { value: FileStorageFolderPermissionTypes.Nobody, label: 'Никто из пользователей' },
  { value: FileStorageFolderPermissionTypes.Some, label: 'Некоторые пользователи' },
  { value: FileStorageFolderPermissionTypes.Anyone, label: 'Все пользователи' },
];

type FormValues = {
  permissionType: FileStorageFolderPermissionTypes;
  userRoles: RolesOption[];
};

interface Props extends Pick<UiModalProps, 'onClose'> {
  folder: FileStorageListEntryModel;
}

export const FileListManagerPermissionModal: FC<Props> = (props) => {
  const { onClose, folder } = props;
  const [form] = UiForm.useForm<FormValues>();

  const { storage: fileStorageFolderUserRolesStorage, fileStorageFolderSetUserRoleEffect } = useMemo(
    getFileStorageFolderUserRoles,
    [],
  );

  const { options: profileListOptions, setQuerySearch, onUpdate } = useProfileOptionsListStorage();

  const isFileStorageFolderSetUserRolePending = useStore(fileStorageFolderSetUserRoleEffect.pending);

  const { data: roles } = useAbstractStorage(fileStorageFolderUserRolesStorage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { entryId: folder.id },
    cancelPendingRequestOnUnmount: true,
  });

  useEffect(() => {
    if (roles) {
      form.setFieldsValue({
        permissionType: getPermissionTypeByUserRoles(roles),
        userRoles: roles.userRoles.map(userRoleToOption),
      });
    }
  }, [roles]);

  const onFinish = async (formValues: FormValues) => {
    try {
      await fileStorageFolderSetUserRoleEffect({
        entryId: folder.id,
        ...getResponseParamsFromFormValues(formValues),
      });

      message.success('Разрешения на директорию успешно установлены');
      onClose?.();
    } catch (e) {
      message.error('Не удалось установить разрешения на директорию');
    }
  };

  const UserAvatarSelectOption: FC<PropsWithChildren<Partial<Pick<UserAvatarModel, 'avatar'>>>> = (
    optionProps,
  ) => {
    const { avatar, children } = optionProps;

    return (
      <UiSpace size={8} align="center">
        <UiAvatar size={24} src={avatar} />
        <UiTypography.Text>{children}</UiTypography.Text>
      </UiSpace>
    );
  };

  const onSearchDebounced = useDebouncedCallback(onUpdate, 500);

  const onSearchUsers = useCallback(
    (newSearchQuery: string) => {
      setQuerySearch(newSearchQuery);

      onSearchDebounced(newSearchQuery);
    },
    [onSearchDebounced, setQuerySearch],
  );

  const userOptions = useMemo<RolesOption[]>(() => {
    return profileListOptions.map((option) => {
      return {
        value: option?.value,
        data: {
          avatar: option?.avatar,
          selectedLabel: option?.label,
          label: <UserAvatarSelectOption avatar={option?.avatar}>{option.label}</UserAvatarSelectOption>,
        },
      };
    });
  }, [profileListOptions]);

  const itemContent = useCallback<RolesItemContent>(
    ({ data: { avatar, selectedLabel } }) => (
      <UserAvatarSelectOption key={selectedLabel} avatar={avatar}>
        {selectedLabel}
      </UserAvatarSelectOption>
    ),
    [],
  );

  return (
    <UiForm form={form} size="small" layout="horizontal" onFinish={onFinish}>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle('Настройка доступа')} />
      </UiModal.Header>

      <UiModal.Content basePadding>
        <UiSpace size={4} direction="vertical" style={{ marginBottom: 16 }}>
          <UiTypography.Text type="secondary">Название папки</UiTypography.Text>
          <UiTypography.Title level={3}>{folder.name}</UiTypography.Title>
        </UiSpace>

        <UiForm.Item name="permissionType" label="Кто может добавлять файлы в папку" labelCol={{ span: 12 }}>
          <UiSelect<FileStorageFolderPermissionTypes> options={options} />
        </UiForm.Item>

        <UiForm.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const isShowUsersField = isPermissionType(
              getFieldValue('permissionType'),
              FileStorageFolderPermissionTypes.Some,
            );

            return (
              <UiForm.Item hidden={!isShowUsersField} name="userRoles">
                <MultiSelectField
                  size="large"
                  options={userOptions}
                  name="userRoles"
                  ItemContent={itemContent}
                  onSearch={onSearchUsers}
                />
              </UiForm.Item>
            );
          }}
        </UiForm.Item>
      </UiModal.Content>

      <UiModal.Footer hasTopBorder>
        <UiModal.Footer.Buttons>
          <UiButton
            disabled={isFileStorageFolderSetUserRolePending}
            type="primary"
            size="large"
            onClick={form.submit}
            label="Сохранить"
          />
          <UiButton type="secondary" size="large" onClick={onClose} label="Отмена" />
        </UiModal.Footer.Buttons>
      </UiModal.Footer>
    </UiForm>
  );
};
