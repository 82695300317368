import {
  UiAvatar,
  UiButton,
  UiFlex,
  UiIcon,
  UiMention,
  UiMentionsInput,
  UiMentionsInputProps,
  UiRow,
  UiTypography,
  defaultUserMentionsProps,
  renderUserSuggestion,
} from '@vkph/ui';
import classNames from 'classnames';
import { useStore } from 'effector-react';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useCurrentProfile } from '@vkph/common/providers/current-profile';
import { getProfilesListStorage } from '@vkph/common/store/profile';
import { getFullNameWithoutPatronymic } from '@vkph/common/utils';
import { useSpace } from '@vkph/ui/hooks';
import AttachSvg from '@vkph/ui/svg/attach.svg';
import EmojiSvg from '@vkph/ui/svg/emoji.svg';
import SendSvg from '@vkph/ui/svg/send.svg';

import styles from './CommentInput.scss';

export type CommentInputProps = {
  defaultText?: string;
  onSend: (text: string) => void;
  loading?: boolean;
  isEditMode?: boolean;
  onCancel?: () => void;
  autoFocus?: boolean;
  isReplyMode?: boolean;
  placeholderText?: string;
  placeholderEditText?: string;
  isSuggestionsForceUp?: boolean;
  isPositionBody?: boolean;
};

const ccn = classNames.bind(styles);

type ControlIconProps = {
  className?: string;
  icon: SvgrComponent;
  iconProps?: React.ComponentProps<SvgrComponent>;
};

const ControlIcon: FC<ControlIconProps> = (props) => {
  const { icon: IconComponent, className, iconProps } = props;

  return (
    <span className={ccn(styles.commentInput__control, className)}>
      <IconComponent {...iconProps} />
    </span>
  );
};

/**
 * Скрываем кнопки аттачментов к комментам до тех пор, пока не прикрутим к ним функциональность
 * https://jira.mail.ru/browse/B2BCORE-937
 */
const showAttachmentsButton = false;
const PAGE_SIZE = 100;

const getDebounceByQuery = (query: string) => (query.length <= 3 ? 500 - query.length * 100 : 200);

export const CommentInput: FC<CommentInputProps> = (props) => {
  const {
    defaultText = '',
    onSend,
    loading,
    isEditMode,
    isReplyMode,
    onCancel,
    autoFocus,
    placeholderText,
    placeholderEditText = 'Редактировать комментарий',
    isSuggestionsForceUp,
    isPositionBody,
  } = props;
  const [text, setText] = useState(defaultText);
  const [queryValue, setQueryValue] = useState('');
  const sendButtonActive = text.trim().length > 0 && !loading;
  const profile = useCurrentProfile();
  const avatarUrl = profile?.smallAvatar;
  const profileListStorage = useMemo(getProfilesListStorage, []);
  const onSendClick = () => {
    if (sendButtonActive) {
      onSend(text);
      setText('');
    }
  };
  const { spaceM, spaceXS } = useSpace();
  const bodyRef = useRef(document.body);

  const {
    pagination: { count: total },
  } = useStore(profileListStorage.storage.store);
  const hasMore = total && total > PAGE_SIZE;

  const defaultPlaceholder = profile?.fullName?.firstName
    ? `Что вы об этом думаете, ${profile.fullName.firstName}?`
    : `Что вы об этом думаете?`;

  const placeholder = placeholderText || defaultPlaceholder;

  const onBlur: UiMentionsInputProps['onBlur'] = ({ target: { value } }) => {
    if (value.length === 0 && onCancel) {
      onCancel();
    }
  };

  useEffect(() => {
    if (!loading) {
      setText(defaultText);
    }
  }, [loading]);

  const debounceValue = useMemo(() => {
    return getDebounceByQuery(queryValue);
  }, [queryValue]);

  const onSearchUserMentionDebounced = useDebouncedCallback((query, callbackData) => {
    profileListStorage.storage.cancelPendingRequest();
    profileListStorage.storage
      .fetchEffect({ query, pageSize: PAGE_SIZE, pageNumber: 1, isActive: true, skipEmptyName: true })
      .then(({ items }) =>
        items.map((user) => ({
          id: user.id,
          display: getFullNameWithoutPatronymic(user.fullName),
          data: user,
        })),
      )
      .then(callbackData)
      .finally(() => setQueryValue(query));
  }, debounceValue);

  const placeholderTextValue = isEditMode ? placeholderEditText : placeholder;

  return (
    <div className={styles.commentInput}>
      {!isEditMode && !isReplyMode && <UiAvatar size={40} src={avatarUrl} />}

      <div
        className={ccn(styles.commentInput__inputWrapper, {
          [styles.commentInput__inputWrapper_nested]: isReplyMode || isEditMode,
        })}
      >
        <UiMentionsInput
          noStyle
          allowSpaceInQuery
          value={text}
          onBlur={onBlur}
          onChange={setText}
          autoFocus={autoFocus}
          placeholder={placeholderTextValue}
          allowSuggestionsAboveCursor
          forceSuggestionsAboveCursor={isSuggestionsForceUp}
          customSuggestionsContainer={(children) => (
            <UiFlex vertical style={{ maxHeight: 450, overflow: 'auto' }}>
              {children}
              {hasMore && (
                <UiRow padding={[spaceXS, spaceM]}>
                  <UiTypography.Text type="secondary">Уточните запрос</UiTypography.Text>
                </UiRow>
              )}
            </UiFlex>
          )}
          {...(isPositionBody && { suggestionsPortalHost: bodyRef.current })}
        >
          <UiMention
            {...defaultUserMentionsProps}
            data={onSearchUserMentionDebounced}
            renderSuggestion={renderUserSuggestion}
          />
        </UiMentionsInput>
      </div>

      <span className={styles.commentInput__controls}>
        {showAttachmentsButton && <ControlIcon icon={AttachSvg} />}
        {showAttachmentsButton && (
          <ControlIcon icon={EmojiSvg} className={styles.commentInput__controlsEmoji} />
        )}
        <UiButton
          type="primary"
          round
          className={styles.commentInput__controlsSend}
          onClick={onSendClick}
          icon={<UiIcon component={SendSvg} width={20} height={20} />}
        />
      </span>
    </div>
  );
};
