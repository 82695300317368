import { ErrorBoundaryFallback, ErrorBoundaryFallbackProps } from '@vkph/components';
import { UiFlex } from '@vkph/ui';
import React, { FC } from 'react';

import { useTheme } from '@vkph/ui/providers/theme';

export const HeaderErrorFallback: FC<ErrorBoundaryFallbackProps> = (errorProps) => {
  const [{ variables: themeVariables }] = useTheme();

  return (
    <UiFlex
      justify="center"
      style={{
        background: themeVariables.colorBgSecondary,
        position: 'absolute',
        width: '100%',
        left: 0,
        top: 0,
      }}
    >
      <ErrorBoundaryFallback {...errorProps} />
    </UiFlex>
  );
};
