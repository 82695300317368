import { UiRow, UiSkeleton, useBreakpoint, useLayout } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC } from 'react';

import { NewsCategoriesStorage, NewsFeedStorage } from '@vkph/common/store/news';
import { createArrayMock } from '@vkph/common/utils';
import { useSpace } from '@vkph/ui/hooks';

import { NewsPinnedItem } from '../item/NewsPinnedItem';
import styles from './NewsPinnedItems.scss';

const mocks = createArrayMock(6, (_, index) => (
  <div key={index} className={styles.newsPinnedItems__item}>
    <UiSkeleton loading width="100%" height="100%" />
  </div>
));

type Props = {
  newsFeedStorage: NewsFeedStorage;
  newsCategoriesStorage: NewsCategoriesStorage;
};

export const NewsPinnedItems: FC<Props> = (props) => {
  const { newsFeedStorage, newsCategoriesStorage } = props;
  const { lg: isLayoutLarge } = useBreakpoint();
  const { outerGutter } = useLayout();
  const { spaceM } = useSpace();

  const { data: newsFeedData } = useStore(newsFeedStorage.storage.store);
  const isNewsFeedLoading = useStore(newsFeedStorage.storage.fetchEffect.pending);
  const { data: newsCategoriesData } = useStore(newsCategoriesStorage.storage.store);
  const isNewsCategoriesLoading = useStore(newsCategoriesStorage.storage.fetchEffect.pending);

  const isLoading = isNewsFeedLoading || isNewsCategoriesLoading;

  return (
    <UiRow
      wrap={false}
      className={styles.newsPinnedItems}
      padding={[0, isLayoutLarge ? outerGutter : spaceM]}
    >
      {isLoading && mocks}
      {!isLoading &&
        newsFeedData.length > 0 &&
        newsFeedData.map((item) => (
          <div key={item.id} className={styles.newsPinnedItems__item}>
            <NewsPinnedItem newsArticle={item} newsCategoriesMap={newsCategoriesData} />
          </div>
        ))}
    </UiRow>
  );
};
