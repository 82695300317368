import classNames from 'classnames';
import React, { FC } from 'react';

import { WidgetModel } from '@vkph/common/types/models';
import { LayoutItem } from '@vkph/common/types/populatedLayout';

export interface WidgetsOverlayProps {
  className?: string;
  widget?: WidgetModel;
  layoutItem: LayoutItem;
  onDeactivateWidgetClick: () => void;
  isShowDeactivate: boolean;
  isDefaultWidget: boolean;
}

export const WidgetsOverlay: FC<WidgetsOverlayProps> = (props) => {
  const { className, widget, layoutItem, onDeactivateWidgetClick, isShowDeactivate, isDefaultWidget } = props;
  const { name } = widget?.attributes || {};

  const getWidgetStatus = () => {
    if (!widget) {
      return 'Виджет не задан';
    }

    return isDefaultWidget ? 'Задан для всех' : 'Задан для вас';
  };

  const description = [
    {
      name: 'Название',
      value: name || 'не задано',
    },
    {
      name: 'Тип',
      value: layoutItem.types.join(', '),
    },
    {
      name: getWidgetStatus(),
    },
  ];

  const descriptionEls = description.map((item) => (
    <li key={item.name} className="widgets-overlay__overlay-description-item">
      <span>{item.name}</span>
      {Boolean(item.value) && (
        <>
          <span>: </span>
          <span>{item.value}</span>
        </>
      )}
    </li>
  ));

  return (
    <div className={classNames('widgets-overlay', className)}>
      <div className="widgets-overlay__overlay-content">
        <ul className="widgets-overlay__overlay-item-wrapper">{descriptionEls}</ul>
        {widget && (
          <div
            className={classNames(
              'widgets-overlay__overlay-item-wrapper',
              'widgets-overlay__overlay-item-wrapper_buttons',
            )}
          >
            {isShowDeactivate && (
              <button
                type="button"
                className={classNames('widgets-overlay__settings-button', 'ui-button')}
                onClick={onDeactivateWidgetClick}
              >
                Отключить
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
