import { Markdown, Post, Reactions, RouterLink, ShareButton } from '@vkph/components';
import { UiCol, UiDivider, UiRow, UiSpace, UiTypography, UiTypographyTextTypes, message } from '@vkph/ui';
import React, { FC, useCallback, useContext } from 'react';
import { To } from 'react-router-dom';

import { useLongreadNavigation } from '@vkph/common/hooks';
import { reactionEffect } from '@vkph/common/store/reactions';
import { LongreadParams } from '@vkph/common/types/longread';
import {
  CommentDetailListModel,
  EmojiUuid,
  ReactionContentType,
  TimelineRecordActorModel,
  TimelineTypes,
} from '@vkph/common/types/models';
import {
  getErrorResponseMessage,
  getFullNameWithoutPatronymic,
  parseEntityText,
  utcToLocalDate,
} from '@vkph/common/utils';
import { useSpace } from '@vkph/ui/hooks';

import { TimelineCardGutterContext } from '../TimelineCard';

export enum TimelineCardCommentEntryTypes {
  Longread = 'longread',
  Entry = 'entry',
}

type TimelineCardCommentBaseEntry = {
  title: string;
  comment: CommentDetailListModel;
  parentComment?: CommentDetailListModel;
};

interface TimelineCardCommentLongreadEntry extends TimelineCardCommentBaseEntry {
  type: TimelineCardCommentEntryTypes.Longread;
  longreadParams: LongreadParams;
}

interface TimelineCardCommentTargetEntry extends TimelineCardCommentBaseEntry {
  type: TimelineCardCommentEntryTypes.Entry;
  url?: To;
}

export type TimelineCardCommentEntry = TimelineCardCommentLongreadEntry | TimelineCardCommentTargetEntry;

type TimelineCardCommentInfo = {
  prefix: string;
  suffix: string;
};

interface Props {
  record: Omit<TimelineRecordActorModel, 'content'>;
  entry: TimelineCardCommentEntry;
}

export const TimelineCardComment: FC<Props> = (props) => {
  const { record, entry } = props;
  const { publishedAt, createdAt, actor, type: recordType } = record;
  const { title, comment, parentComment } = entry;
  const [verticalGutter, horizontalGutter] = useContext(TimelineCardGutterContext);
  const { spaceM } = useSpace();
  const { spaceM: spaceMpx, spaceXL: spaceXLpx } = useSpace();

  const { openLongread } = useLongreadNavigation();

  const parsedCommentText = parseEntityText(comment.text).textValue;
  const parsedParentCommentText = parentComment && parseEntityText(parentComment.text).textValue;

  const commentText = parsedParentCommentText || parsedCommentText;
  const replyText = parsedParentCommentText ? parsedCommentText : undefined;

  const createPrefix = `оставил(а) `;
  const replyPrefix = `ответил(а) на `;

  const commentTargetNames: Partial<Record<TimelineTypes, TimelineCardCommentInfo>> = {
    [TimelineTypes.CommentCreatedNews]: { prefix: createPrefix, suffix: ' к новости' },
    [TimelineTypes.CommentCreatedEntry]: { prefix: createPrefix, suffix: ' к посту' },
    [TimelineTypes.CommentCreatedMicropost]: { prefix: createPrefix, suffix: ' к микропосту' },
    [TimelineTypes.CommentCreatedCms]: { prefix: createPrefix, suffix: ' к странице' },
    [TimelineTypes.CommentCreatedAlbumImage]: { prefix: createPrefix, suffix: ' к изображению' },
    [TimelineTypes.CommentCreatedFileVersion]: { prefix: createPrefix, suffix: ' к файлу' },
    [TimelineTypes.CommentCreatedUserBadge]: { prefix: createPrefix, suffix: ' к награде' },
    [TimelineTypes.CommentCreatedThanks]: { prefix: createPrefix, suffix: ' к благодарности' },
    [TimelineTypes.CommentCreatedTask]: { prefix: createPrefix, suffix: ' к задаче' },
    [TimelineTypes.CommentCreatedRecord]: { prefix: createPrefix, suffix: ' к записи' },

    [TimelineTypes.CommentReplyNews]: { prefix: replyPrefix, suffix: ' к новости' },
    [TimelineTypes.CommentReplyEntry]: { prefix: replyPrefix, suffix: ' к посту' },
    [TimelineTypes.CommentReplyMicropost]: { prefix: replyPrefix, suffix: ' к микропосту' },
    [TimelineTypes.CommentReplyCms]: { prefix: replyPrefix, suffix: ' к странице' },
    [TimelineTypes.CommentReplyAlbumImage]: { prefix: replyPrefix, suffix: ' к изображению' },
    [TimelineTypes.CommentReplyFileVersion]: { prefix: replyPrefix, suffix: ' к файлу' },
    [TimelineTypes.CommentReplyUserBadge]: { prefix: replyPrefix, suffix: ' к награде' },
    [TimelineTypes.CommentReplyThanks]: { prefix: replyPrefix, suffix: ' к благодарности' },
    [TimelineTypes.CommentReplyTask]: { prefix: replyPrefix, suffix: ' к задаче' },
    [TimelineTypes.CommentReplyRecord]: { prefix: replyPrefix, suffix: ' к записи' },
  };

  const onReaction = useCallback(
    (emojiUuid?: EmojiUuid) => {
      reactionEffect({ objectId: comment.id, contentType: ReactionContentType.Comment, emojiUuid }).catch(
        (e) => {
          message.error(
            getErrorResponseMessage(e, `Не удалось ${emojiUuid ? 'поставить' : 'удалить'} реакцию`),
          );
        },
      );
    },
    [comment.id],
  );

  const CommentTitleLink: FC = () => {
    if (entry.type === TimelineCardCommentEntryTypes.Longread) {
      return (
        <UiTypography.Link style={{ fontSize: 20 }} onClick={() => openLongread(entry.longreadParams)}>
          {title}
        </UiTypography.Link>
      );
    }

    if (entry.url) {
      return (
        <RouterLink to={entry.url} style={{ fontSize: 20 }} strong>
          {title}
        </RouterLink>
      );
    }

    return null;
  };

  const CommentBody: FC = () => {
    if (parentComment) {
      return (
        <UiRow align="stretch" wrap={false}>
          <UiCol>
            <UiDivider emptyMargin type="vertical" style={{ minHeight: '100%' }} />
          </UiCol>
          <UiSpace direction="vertical" style={{ padding: `${spaceMpx} ${spaceXLpx}` }}>
            <UiSpace direction="vertical" size={0}>
              <UiTypography.Text strong type={UiTypographyTextTypes.Secondary}>
                {getFullNameWithoutPatronymic(parentComment.user)}
              </UiTypography.Text>
              <UiTypography.Text type={UiTypographyTextTypes.Secondary}>
                {utcToLocalDate(parentComment.createdAt, 'dd MMMM в H:mm')}
              </UiTypography.Text>
            </UiSpace>
            <Markdown htmlMarkup>{parsedCommentText}</Markdown>
          </UiSpace>
        </UiRow>
      );
    }

    return <Markdown htmlMarkup>{commentText}</Markdown>;
  };

  const CommentHeaderExtraLink: FC = () => {
    if (entry.type === TimelineCardCommentEntryTypes.Longread) {
      return (
        <UiTypography.Link
          onClick={() => openLongread(entry.longreadParams, { scrollToComments: true })}
          underline
        >
          комментарий
        </UiTypography.Link>
      );
    }

    if (entry.type === TimelineCardCommentEntryTypes.Entry && entry.url) {
      return (
        <RouterLink to={entry.url} state={{ scrollToComments: true }} underline>
          комментарий
        </RouterLink>
      );
    }

    return <UiTypography.Text type="secondary">комментарий</UiTypography.Text>;
  };

  return (
    <Post style={{ paddingTop: verticalGutter }}>
      <Post.Header
        padding={[0, horizontalGutter]}
        author={actor}
        postDate={publishedAt || createdAt}
        extra={
          <UiTypography.Text type="secondary">
            {commentTargetNames[recordType]?.prefix}
            <CommentHeaderExtraLink />
            {commentTargetNames[recordType]?.suffix}
          </UiTypography.Text>
        }
      />
      <Post.Body padding={[spaceM, horizontalGutter]}>
        <UiSpace size="middle" direction="vertical">
          <CommentTitleLink />
          <CommentBody />
          {replyText && <Markdown htmlMarkup>{commentText}</Markdown>}
          <Reactions
            onReaction={onReaction}
            reactions={comment.reactions || []}
            comments={comment.repliesCount}
          >
            <ShareButton />
          </Reactions>
        </UiSpace>
      </Post.Body>
    </Post>
  );
};
