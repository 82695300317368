import { Editor, Reactions } from '@vkph/components';
import { UiCol, UiRow, UiSpace, UiTypography } from '@vkph/ui';
import React, { FC, ReactNode } from 'react';

import { CMSPageModel } from '@vkph/common/types/models';

interface Props {
  page: CMSPageModel;
  actions?: ReactNode[];
}

export const PagePreview: FC<Props> = (props) => {
  const { page, actions } = props;
  const { name, body, extraData } = page;
  const { isReactionsDisabled, isCommentDisabled, isShowViewCount } = extraData || {};

  const reactions = isReactionsDisabled ? undefined : page.reactions;
  const views = isShowViewCount ? page.viewsCount : undefined;
  const comments = isCommentDisabled ? undefined : page.commentsCount;

  const isHasFooter = !isReactionsDisabled || !isCommentDisabled || isShowViewCount;

  return (
    <UiSpace full direction="vertical">
      <UiRow align="middle" wrap={false} justify="space-between">
        <UiTypography.Title level={2}>{name}</UiTypography.Title>
        {actions && actions?.length > 0 && (
          <UiCol>
            <UiSpace size={16}>{actions.map((action) => action)}</UiSpace>
          </UiCol>
        )}
      </UiRow>

      <UiTypography.Paragraph style={{ margin: 0 }} ellipsis={{ rows: 3 }}>
        <Editor.Viewer html={body.data} />
      </UiTypography.Paragraph>

      {isHasFooter && (
        <Reactions
          reactions={reactions}
          comments={comments}
          views={views}
          stretch
          style={{ marginTop: 16 }}
        />
      )}
    </UiSpace>
  );
};
