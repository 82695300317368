import { UiUploadFile, UiUploadProps, UiUploadRequestOption, UploadChangeParam } from '@vkph/ui';
import React, { FC, useMemo } from 'react';

import {
  FileStorageApiVersions,
  uploadFileStorageAttachmentEffectFactory,
} from '@vkph/common/store/filestorage';
import { FileId, FileStorageEntryId, PreloadedFileModel } from '@vkph/common/types/models';
import { UiUploadFileAttach, UiUploadOriginFileAttach } from '@vkph/common/types/uploadsFormItem';

import { UploadsFormItem, UploadsFormItemProps } from '../UploadsFormItem';

const patchAttachProperty = (file: UiUploadFileAttach<FileId | FileStorageEntryId>) => {
  if (!file.attachmentId) {
    // eslint-disable-next-line no-param-reassign
    file.attachmentId = file.originFileObj?.attachmentId;
  }
};

interface UploadsFormItemAdapterProps extends UploadsFormItemProps, Pick<UiUploadProps, 'onRemove'> {
  apiVersion: FileStorageApiVersions;
}

export const UploadsFormItemAdapter: FC<UploadsFormItemAdapterProps> = (props) => {
  const { apiVersion, onRemove, onAddAttachment, ...uploadFormItemProps } = props;

  const changesInFiles = useMemo(
    () =>
      new Map<
        UiUploadFileAttach<FileId | FileStorageEntryId>['uid'],
        UiUploadFileAttach<FileId | FileStorageEntryId>
      >(),
    [],
  );

  const uploadAttachmentEffectV2 = useMemo(uploadFileStorageAttachmentEffectFactory, []);
  const uploadAttachmentEffectV3 = useMemo(() => {
    return uploadFileStorageAttachmentEffectFactory<PreloadedFileModel>(FileStorageApiVersions.v3);
  }, []);

  const onCustomRequest = (options: UiUploadRequestOption) => {
    const { file, onSuccess, onError } = options;
    const uploadedFile = file as UiUploadOriginFileAttach<FileStorageEntryId | FileId>;
    const { name: fileName, size } = uploadedFile;

    if (apiVersion === FileStorageApiVersions.v3) {
      uploadAttachmentEffectV3({ file: uploadedFile })
        .then(({ storageObject, fileUrl }) => {
          const status = changesInFiles.get(uploadedFile.uid)?.status;
          const isSkipUpload = status && ['removed', 'error'].includes(status);

          if (!isSkipUpload) {
            uploadedFile.attachmentId = storageObject;
          }

          // onSuccess, onError - не удалять, перестанет работать статус загрузки (UploadFileStatus)
          onSuccess?.({ ...uploadedFile, url: fileUrl });
        })
        .catch((error) => onError?.(error));
    }

    // TODO: Удалить после перехода на v3
    if (apiVersion === FileStorageApiVersions.v2) {
      uploadAttachmentEffectV2({ file: uploadedFile })
        .then(({ id, fileUrl }) => {
          const status = changesInFiles.get(uploadedFile.uid)?.status;
          const isSkipUpload = status && ['removed', 'error'].includes(status);

          if (!isSkipUpload) {
            uploadedFile.attachmentId = id;
            onAddAttachment?.({ id, name: fileName, size, url: fileUrl });
          }

          // onSuccess, onError - не удалять, перестанет работать статус загрузки (UploadFileStatus)
          onSuccess?.(uploadedFile);
        })
        .catch((error) => onError?.(error));
    }
  };

  const onChange = ({ file }: UploadChangeParam) => {
    changesInFiles.set(file.uid, file);
  };

  const onRemoveFile = (file: UiUploadFile) => {
    const currentFile = file as UiUploadOriginFileAttach<FileId | FileStorageEntryId>;

    patchAttachProperty(currentFile);

    if (currentFile.attachmentId) {
      onRemove?.(currentFile);
    }
  };

  return (
    <UploadsFormItem
      customRequest={onCustomRequest}
      onChange={onChange}
      onRemove={onRemoveFile}
      {...uploadFormItemProps}
    />
  );
};
