import { Markdown, UserAvatar } from '@vkph/components';
import { UiTypography } from '@vkph/ui';
import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { CommentContentTypes, CommentDetailListModel } from '@vkph/common/types/models';
import {
  getFullNameWithoutPatronymic,
  getRoutePath,
  RouteNames,
  getFormattedDateToWord,
  parseEntityText,
} from '@vkph/common/utils';

import styles from './CommentListItem.scss';
import { CommentListItemLink } from './link/CommentListItemLink';

const commentLinkPrefixes = {
  [CommentContentTypes.Post]: 'к',
  [CommentContentTypes.Micropost]: 'к',
  [CommentContentTypes.NewsEntry]: 'к новости',
  [CommentContentTypes.Gamification]: 'к награде',
  [CommentContentTypes.ProfileEntry]: 'к профилю',
  [CommentContentTypes.AlbumImage]: 'к изображению в группе',
  [CommentContentTypes.File]: 'к файлу',
  [CommentContentTypes.FileVersion]: 'к файлу',
  [CommentContentTypes.Cms]: 'к странице',
  [CommentContentTypes.Project]: 'к проекту',
  [CommentContentTypes.Task]: 'к задаче',
  [CommentContentTypes.Thanks]: 'к благодарности',
  [CommentContentTypes.TimelineRecord]: 'к записи',
  [CommentContentTypes.UserBadge]: 'к награде',
  [CommentContentTypes.Idea]: 'к идее',
};

type Props = {
  comment: CommentDetailListModel;
  onClickProfileLink?: () => void;
};

export const CommentListItem: FC<Props> = (props) => {
  const { comment, onClickProfileLink } = props;
  const { user, text, createdAt, contentType, data } = comment;
  const { textValue: commentText } = useMemo(() => parseEntityText(text), [text]);

  const linkPrefix = commentLinkPrefixes[contentType];
  const profileId = user.keycloakUser?.keycloakId || '';
  const profileRoute = getRoutePath(RouteNames.Profile, { id: profileId });
  const fullName = getFullNameWithoutPatronymic(user);
  const createdAtFormatted = getFormattedDateToWord({ date: createdAt });
  const isUserActive = Boolean(comment.user.isActive || comment.user.keycloakUser?.isActive);

  return (
    <div className={styles.commentListItem}>
      <span className={styles.commentListItem__header}>
        <Link to={profileRoute} onClick={onClickProfileLink}>
          <UserAvatar size={40} isActive={isUserActive} src={user.avatar} />
        </Link>
        <div className={styles.commentListItem__headerText}>
          <span className={styles.commentListItem__title}>
            <Link to={profileRoute} onClick={onClickProfileLink}>
              <UiTypography.Text strong>{fullName}</UiTypography.Text>
            </Link>
            <UiTypography.Text type="secondary" className={styles.commentListItem__titleFrom}>
              оставил комментарий {linkPrefix}
            </UiTypography.Text>
            {data && <CommentListItemLink comment={comment} />}
          </span>
          <UiTypography.Text type="secondary">{createdAtFormatted}</UiTypography.Text>
        </div>
      </span>
      <div className={styles.postListItem__contentEntry}>
        <UiTypography.Paragraph
          className={styles.commentListItem__contentText}
          style={{ marginBottom: 0 }}
          ellipsis={{ rows: 3 }}
        >
          <Markdown htmlMarkup>{commentText}</Markdown>
        </UiTypography.Paragraph>
      </div>
    </div>
  );
};
