import { UiButton, UiRow, UiIcon, UiDrawerProps } from '@vkph/ui';
import React, { FC } from 'react';

import { useSpace } from '@vkph/ui/hooks';
import CloseSVG from '@vkph/ui/svg/close.svg';

export interface DrawerCloseProps {
  onClose: UiDrawerProps['onClose'];
}

export const DrawerClose: FC<DrawerCloseProps> = (props) => {
  const { onClose } = props;
  const { spaceXL } = useSpace();

  return (
    <UiRow style={{ paddingRight: spaceXL }}>
      <UiButton
        onClick={onClose}
        type="link-secondary"
        icon={<UiIcon component={CloseSVG} width={20} height={20} />}
      />
    </UiRow>
  );
};
