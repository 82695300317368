import { UiModal, UiTypography, UiTruncateMarkup, UiList, UiSpace } from '@vkph/ui';
import React, { FC } from 'react';

import { Note } from '@vkph/common/types/notes';
import { getFormattedDateToWord } from '@vkph/common/utils';
import { useSpace, usePaddingStyle } from '@vkph/ui/hooks';
import { getModalStepsForSingleTitle } from '@vkph/ui/utils';

type Props = {
  notesList: Note[];
  onOpenNoteEditor: (note?: Note) => void;
};

export const NotesWidgetListModal: FC<Props> = (props) => {
  const { notesList, onOpenNoteEditor } = props;
  const { spaceS, spaceL } = useSpace();
  const { padding } = usePaddingStyle([spaceS, spaceL]);

  return (
    <>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle('Все заметки')} />
      </UiModal.Header>

      <UiModal.Content>
        <UiList
          split={false}
          dataSource={notesList}
          rowKey={({ id }) => id}
          renderItem={(note) => (
            <UiList.Item style={{ padding }} onClick={() => onOpenNoteEditor(note)}>
              <UiSpace size={0} direction="vertical">
                <UiTypography.Title level={3}>
                  <UiTruncateMarkup truncate>{note.title}</UiTruncateMarkup>
                </UiTypography.Title>
                <UiTypography.Text strong type="secondary">
                  {getFormattedDateToWord({ date: note.createdAt })}
                </UiTypography.Text>
              </UiSpace>
            </UiList.Item>
          )}
        />
      </UiModal.Content>
    </>
  );
};
