import { CreatableSearchSelect } from '@vkph/components';
import { UiButton, UiOptionData, message } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, useMemo, useState } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getDictsStorage } from '@vkph/common/store/dictionaries';
import { GetSkillsStorage } from '@vkph/common/store/profile/skills';
import { SaveSkillParams } from '@vkph/common/store/profile/skills/api';
import { DictDataParams, DictMatchTypes } from '@vkph/common/types/api';
import { Dictionaries, RecordResponse, SkillType } from '@vkph/common/types/models';
import { getErrorResponseMessage, generateAutoCompleteOptions } from '@vkph/common/utils';

import { SkillsBaseModal } from '../skills-base/SkillsBaseModal';

type Props = {
  closeModal: () => void;
  skillStorage: GetSkillsStorage;
};

const skillsDictsStorage = getDictsStorage<RecordResponse, DictDataParams>({
  dictionaryName: Dictionaries.Names.Skills,
  dataBuilder: ({ name: { type, value } }) => ({ name: { type, value } }),
  getEndpointParams: () => ({ ordering: 'name' }),
});

const skillsDictsOptions = skillsDictsStorage.storage.store.map(({ data }) =>
  generateAutoCompleteOptions(data),
);

export const SkillsAddModal: FC<Props> = (props) => {
  const { closeModal, skillStorage } = props;
  const [searchText, setSearchText] = useState('');
  const [selectedSkill, setSelectedSkill] = useState<UiOptionData | null>(null);

  const { saveSkillEffect } = skillStorage;
  const skillsList = useStore(skillStorage.storage.store);
  const saveSkillInProgress = useStore(saveSkillEffect.pending);

  const existingSkillsOptions = useMemo(
    () =>
      generateAutoCompleteOptions(skillsList.data, {
        valuePath: 'skill.id',
        labelPath: 'skill.name',
      }),
    [skillsList.data],
  );

  useAbstractStorage(skillsDictsStorage.storage, {
    autoFetchAndRefetch: searchText.length > 0,
    autoFetchParams: { name: { type: DictMatchTypes.Icontains, value: searchText } },
  });

  const { createNewDictsRecordEffect } = skillsDictsStorage;
  const skillsOptions = useStore(skillsDictsOptions);
  const createSkillPending = useStore(createNewDictsRecordEffect.pending);

  const onSaveSkillHandler = () => {
    if (selectedSkill) {
      const saveSkillParams: SaveSkillParams = {
        name: selectedSkill.value,
        skillId: selectedSkill.key,
        skillType: SkillType.Skill,
      };

      saveSkillEffect(saveSkillParams)
        .then(closeModal)
        .catch((e) => message.error(getErrorResponseMessage(e)));
    }
  };

  const onSearchSkill = (value: string) => {
    setSearchText(value);
    setSelectedSkill(null);
  };

  const onSelectSkill = (_: string, option: UiOptionData) => {
    setSelectedSkill(option);
  };

  const SubmitChildren = (
    <UiButton
      block
      size="large"
      type="primary"
      label="Сохранить"
      onClick={onSaveSkillHandler}
      loading={saveSkillInProgress}
      disabled={createSkillPending || !selectedSkill}
    />
  );

  return (
    <SkillsBaseModal title="Добавить навык" onCancel={closeModal} submitChildren={SubmitChildren}>
      <CreatableSearchSelect
        size="large"
        placeholder="Выбрать"
        defaultActiveFirstOption
        value={searchText}
        options={skillsOptions}
        excludeOptions={existingSkillsOptions}
        onSearch={onSearchSkill}
        onSelect={onSelectSkill}
        disabled={createSkillPending}
        onCreateEffect={createNewDictsRecordEffect}
        maxLength={50}
        style={{ width: '100%' }}
      />
    </SkillsBaseModal>
  );
};
