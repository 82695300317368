import { endpoints } from '@vkph/common/endpoints';
import { refetchOnCreateUpdateMeetingEvent } from '@vkph/common/store/calendar/api';
import { CalendarBusySlots } from '@vkph/common/types/models';
import { abstractStorageFactory } from '@vkph/common/utils/effector';

export type FetchBusySlotsResponse = CalendarBusySlots[];

export type FetchBusySlotsParams = {
  userIds: string[];
  since: string;
  till: string;
};

export type GetBusySlotsStorageParams = {
  isSubscribedOnCreateUpdateFromHeader?: boolean;
};

export const getBusySlotsStorage = ({
  isSubscribedOnCreateUpdateFromHeader,
}: GetBusySlotsStorageParams = {}) => {
  const storage = abstractStorageFactory<
    FetchBusySlotsResponse,
    FetchBusySlotsResponse,
    FetchBusySlotsResponse,
    FetchBusySlotsParams
  >({
    endpointBuilder: () => endpoints.calendar.freeBusy(),
    requestMethod: 'post',
    dataBuilder: ({ since, till, userIds }) => ({ since, till, userIds }),
    dataMapper: (data) => data,
    defaultValue: [],
  });

  if (isSubscribedOnCreateUpdateFromHeader) {
    storage.store.on(refetchOnCreateUpdateMeetingEvent, () => {
      storage.refetchWithLastParams();
    });
  }

  return { storage };
};
