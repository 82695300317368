import { UiList, UiCheckboxOptionType, UiRadio, UiRadioChangeEvent } from '@vkph/ui';
import React, { FC } from 'react';

import { NotificationSortAttributes } from '@vkph/common/types/models';

type Props = {
  onClose: () => void;
  onChange: (sortByValue: NotificationSortAttributes) => void;
  sortBy: NotificationSortAttributes;
};

const sortByAttrs: UiCheckboxOptionType[] = [
  {
    label: 'По дате',
    value: NotificationSortAttributes.Date,
  },
  {
    label: 'Сперва непросмотренные',
    value: NotificationSortAttributes.UnreadFirst,
  },
];

export const SettingsPopoverContent: FC<Props> = (props) => {
  const { sortBy, onChange } = props;

  const onRadioChange = ({ target: { value } }: UiRadioChangeEvent) => {
    onChange(value);
  };

  return (
    <UiRadio.Group value={sortBy} onChange={onRadioChange}>
      <UiList
        split={false}
        dataSource={sortByAttrs}
        renderItem={({ value, label }) => (
          <UiList.Item hoverable>
            <UiRadio key={String(label)} value={value}>
              {label}
            </UiRadio>
          </UiList.Item>
        )}
      />
    </UiRadio.Group>
  );
};
