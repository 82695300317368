import { Editor } from '@vkph/components';
import { UiButton, UiForm, UiInput, message, UiModal, useModalBase } from '@vkph/ui';
import { format } from 'date-fns';
import React, { useCallback, useMemo } from 'react';

import { EntityContentType } from '@vkph/common/types/models';
import { Note } from '@vkph/common/types/notes';
import { getErrorResponseMessage } from '@vkph/common/utils';
import { convertEditorJSDeprecatedToCKEditorFormat, isEditorVersion } from '@vkph/common/utils/markupContent';
import { MAX_LENGTH_INPUT_300, requiredRule } from '@vkph/common/utils/validation';
import { generateRandomString, getModalStepsForSingleTitle } from '@vkph/ui/utils';

import styles from './NoteEditor.scss';

type Props = {
  note?: Note;
  onClose: () => void;
  onSave: (note: Note) => Promise<unknown>;
};

export const NoteEditor: React.FC<Props> = (props) => {
  const { note, onClose, onSave } = props;
  const [form] = UiForm.useForm<Note>();
  const { confirm } = useModalBase();

  const getFormFieldsValues = () => ({
    ...form.getFieldsValue(),
    id: form.getFieldValue('id') || generateRandomString(32),
    createdAt: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
  });

  const onFormFinish = useCallback(
    () =>
      onSave(getFormFieldsValues())
        .then(() => {
          onClose();
          message.success('Заметка сохранена');
        })
        .catch((e) => message.error(getErrorResponseMessage(e, 'Ошибка сохранения'))),
    [],
  );

  const onCloseDraft = () => {
    if (form.isFieldsTouched(['title', 'body'])) {
      confirm({
        title: 'Сохранить заметку?',
        okText: 'Сохранить',
        cancelText: 'Не сохранять',
        autoFocusButton: null,
        onCancel: onClose,
        onOk: form.submit,
        closeIcon: true,
      });
    } else {
      onClose();
    }
  };

  const formInitialValues = useMemo(() => {
    if (note) {
      if (isEditorVersion(EntityContentType.CKEditor5, note.body)) {
        return note;
      }

      return {
        ...note,
        body: convertEditorJSDeprecatedToCKEditorFormat(note.body),
      };
    }

    return {};
  }, [note]);

  return (
    <UiForm
      size="large"
      layout="horizontal"
      form={form}
      onFinish={onFormFinish}
      initialValues={formInitialValues}
      className={styles.noteEditor__form}
    >
      <UiModal.Header hasBottomBorder>
        <UiForm.Item noStyle shouldUpdate>
          {({ getFieldValue, isFieldsTouched }) => (
            <UiModal.Header.Title
              truncate
              noCloseIcon
              steps={getModalStepsForSingleTitle(getFieldValue('title') || 'Создать заметку')}
              wrapperClassName={styles.noteEditor__header}
            >
              <div className={styles.noteEditor__actions}>
                <UiButton size="large" type="tertiary" onClick={onCloseDraft}>
                  Закрыть
                </UiButton>
                <UiButton
                  size="large"
                  type="primary"
                  disabled={!isFieldsTouched(['title', 'body'])}
                  onClick={form.submit}
                >
                  Сохранить
                </UiButton>
              </div>
            </UiModal.Header.Title>
          )}
        </UiForm.Item>
      </UiModal.Header>

      <UiModal.Content className={styles.noteEditor__content}>
        <UiForm.Item name="title" rules={[requiredRule]} className={styles.noteEditor__titleInput}>
          <UiInput
            size="large"
            bordered={false}
            placeholder="Заголовок"
            maxLength={MAX_LENGTH_INPUT_300}
            autoComplete="off"
          />
        </UiForm.Item>

        <UiForm.Item name="body" className={styles.noteEditor__editor}>
          <Editor />
        </UiForm.Item>
      </UiModal.Content>
    </UiForm>
  );
};
