import { UiSkeleton } from '@vkph/ui';
import React, { FC } from 'react';

import styles from '../WeatherCurrent.scss';

export const WeatherCurrentLoader: FC = () => (
  <span className={styles.weatherCurrent}>
    <UiSkeleton circle loading width={72} height={72} />
    <div className={styles.weatherCurrent__temp}>
      <div className={styles.weatherCurrent__tempMain}>
        <UiSkeleton loading width={73} height={40} />
      </div>

      <UiSkeleton loading width={51} height={20} />
    </div>

    <div className={styles.weatherCurrent__info}>
      <UiSkeleton loading width={120} height={18} />

      <div className={styles.weatherCurrent__infoFeelings}>
        <UiSkeleton loading width={120} height={18} />
      </div>

      <div className={styles.weatherCurrent__infoIndicator}>
        <UiSkeleton loading width={90} height={18} />
      </div>

      <div className={styles.weatherCurrent__infoIndicator}>
        <UiSkeleton loading width={90} height={18} />
      </div>

      <div className={styles.weatherCurrent__infoIndicator}>
        <UiSkeleton loading width={90} height={18} />
      </div>
    </div>
  </span>
);
