// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9a27d3cf_poll-controls{padding-top:24px}._9a27d3cf_poll-controls__button{width:100%}", "",{"version":3,"sources":["webpack://./../../libs/modules/src/components/poll/poll-controls/PollControls.scss"],"names":[],"mappings":"AACA,yBACE,gBAAA,CAEA,iCACE,UAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.poll-controls {\n  padding-top: $baseSpacing;\n\n  &__button {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"poll-controls": "_9a27d3cf_poll-controls",
	"pollControls": "_9a27d3cf_poll-controls",
	"poll-controls__button": "_9a27d3cf_poll-controls__button",
	"pollControls__button": "_9a27d3cf_poll-controls__button"
};
export default ___CSS_LOADER_EXPORT___;
