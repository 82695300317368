import React, { FC } from 'react';

import {
  NotificationModel,
  NotificationProjectCommentModel,
  NotificationTypes,
} from '@vkph/common/types/models';

import { isNotificationSomeOfTypes } from '../../../../types/guards/notification-guards';
import { NotificationProjectsComment } from './comment/NotificationProjectsComment';

interface Props {
  notification: NotificationModel;
}

export const NotificationProjects: FC<Props> = (props) => {
  const { notification } = props;

  if (
    isNotificationSomeOfTypes<NotificationProjectCommentModel>(notification, [
      NotificationTypes.ProjectCommentReactionCreated,
    ])
  ) {
    return <NotificationProjectsComment notification={notification} />;
  }

  return null;
};
