import { useBreakpoint } from '@vkph/ui';
import React, { FC } from 'react';

import { HeaderDesktop } from './desktop';
import { HeaderMobile } from './mobile';

export const Header: FC = () => {
  const breakpoints = useBreakpoint();

  if (breakpoints.lg) return <HeaderDesktop />;

  return <HeaderMobile />;
};
