import { UiSkeleton } from '@vkph/ui';
import React from 'react';

import styles from './NotificationLoading.scss';

export const NotificationLoading = () => {
  return (
    <div className={styles.notificationLoading}>
      <UiSkeleton circle loading width={40} height={40} className={styles.notificationLoading__userAvatar} />
      <div>
        <UiSkeleton loading width={120} height={18} />
        <UiSkeleton loading width={240} height={16} />
        <div>
          <UiSkeleton loading width="100%" height={40} />
        </div>
      </div>
    </div>
  );
};
