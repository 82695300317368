import { AvatarsTooltip } from '@vkph/components';
import {
  MAX_BADGE_COUNT,
  UiBadge,
  UiButton,
  UiIcon,
  UiTooltip,
  UiTruncateMarkup,
  UiTypography,
} from '@vkph/ui';
import classNames from 'classnames';
import React, { FC, useMemo } from 'react';

import { GetSkillsStorage } from '@vkph/common/store/profile/skills';
import { SkillId, SkillType, SkillsUsersSteps, UserSkill } from '@vkph/common/types/models';
import { colleagueDeclension, declension } from '@vkph/common/utils';
import CheckSmallSVG from '@vkph/ui/svg/check-small.svg';
import { getTruncateStringEnding } from '@vkph/ui/utils';

import { skillsTextMap } from '../Skills';
import styles from './SkillsButton.scss';

const ccn = classNames.bind(styles);

type Props = {
  isOwner: boolean;
  currentUserId?: string;
  userSkill: UserSkill;
  skillStorage: GetSkillsStorage;
  onSkillDelete: (skillId: SkillId) => void;
  buttonClassName?: string;
  skillType: SkillType;
  onOpenUsers: (startStep: SkillsUsersSteps) => void;
};

export const SkillsButton: FC<Props> = (props) => {
  const {
    userSkill,
    isOwner,
    currentUserId,
    skillStorage,
    onSkillDelete,
    buttonClassName,
    skillType,
    onOpenUsers,
  } = props;
  const { skill, count, proveThreshold, approvers } = userSkill;
  const { approveSkillEffect } = skillStorage;

  const isSkillApprovedByMe = useMemo(
    () => approvers.some((approver) => approver.userFrom.id === currentUserId),
    [approvers, currentUserId],
  );

  const isProSkill = count >= proveThreshold;

  const getTooltipTitle = () => {
    if (isOwner) {
      return `Удалить ${skillsTextMap[skillType].OneDown}`;
    }

    return isSkillApprovedByMe
      ? `Отменить ${skillsTextMap[skillType].OneDown}`
      : `Подтвердить ${skillsTextMap[skillType].OneDown}`;
  };

  const onSkillHandler = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (!isOwner) {
      approveSkillEffect({ skillId: skill.id, isAccept: !isSkillApprovedByMe, skillType });
    } else {
      onSkillDelete(skill.id);
    }
  };

  const title = (
    <UiTypography.Text className={styles.skillsButton__approversTitle}>
      Подтвердили:&nbsp;
      <strong>{approvers.length}</strong>
      &nbsp;
      {declension(approvers.length, colleagueDeclension)}
    </UiTypography.Text>
  );

  const nameTruncatedEnding = getTruncateStringEnding(skill.name);

  return (
    <>
      <UiButton className={classNames(styles.skillsButton, buttonClassName)}>
        <UiTooltip placement="top" title={getTooltipTitle()}>
          <span
            role="button"
            onClick={onSkillHandler}
            onKeyPress={onSkillHandler}
            className={ccn(styles.skillsButton__icon, {
              [styles.skillsButton__iconPro]: isProSkill,
              [styles.skillsButton__iconClear]: isSkillApprovedByMe || isOwner,
              [styles.skillsButton__iconConfirm]: !isSkillApprovedByMe,
            })}
            tabIndex={0}
          />
        </UiTooltip>

        <div
          role="button"
          onClick={() => onOpenUsers(SkillsUsersSteps.Possess)}
          onKeyPress={() => onOpenUsers(SkillsUsersSteps.Possess)}
          tabIndex={-1}
          className={styles.skillsButton__desc}
        >
          <UiTruncateMarkup truncate lines={1} ellipsis={`...${nameTruncatedEnding}`}>
            {skill.name}
          </UiTruncateMarkup>
        </div>
        <AvatarsTooltip
          users={approvers.map((approver) => approver.userFrom)}
          title={title}
          onClickSuffix={() => onOpenUsers(SkillsUsersSteps.Confirmed)}
        >
          <UiBadge
            count={count}
            overflowCount={MAX_BADGE_COUNT}
            className={styles.skillsButton__count}
            title=""
          />
        </AvatarsTooltip>
        {isSkillApprovedByMe && (
          <UiIcon
            className={styles.skillsButton__approved}
            component={CheckSmallSVG}
            height={20}
            width={20}
          />
        )}
      </UiButton>
    </>
  );
};
