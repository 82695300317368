import { endpoints } from '@vkph/common/endpoints';
import { TimelineId, TimelineRecordModel } from '@vkph/common/types/models';
import { abstractStorageFactory } from '@vkph/common/utils';

export type GetTimelineRecordStorageParams = {
  id: TimelineId;
};

export const getTimelineRecordStorage = () => {
  const storage = abstractStorageFactory<
    TimelineRecordModel,
    TimelineRecordModel,
    null,
    GetTimelineRecordStorageParams
  >({
    endpointBuilder: ({ id }) => endpoints.timelines.records(id),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};
