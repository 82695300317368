import { UiUploadListItemRender, UiUploadFileType, UiUploadFileList } from '@vkph/ui';
import React, { FC } from 'react';

interface Props {
  itemRenderParams: Parameters<UiUploadListItemRender>;
  type?: UiUploadFileType;
}

export const UploadsFormListItem: FC<Props> = (props) => {
  const { itemRenderParams, ...uploadItemProps } = props;
  const [_, file, __, actions] = itemRenderParams;

  return <UiUploadFileList.Item file={file} onDelete={actions.remove} {...uploadItemProps} />;
};
