import { endpoints } from '@vkph/common/endpoints';
import { OrderingParams, PaginatedListResults } from '@vkph/common/types/api';
import { RecordResponse, SearchParams, Tag } from '@vkph/common/types/models';
import { abstractStorageFactory, generateDictRecord } from '@vkph/common/utils';

export const getSearchTagDictStorage = () => {
  const storage = abstractStorageFactory<
    PaginatedListResults<Tag>,
    RecordResponse[],
    RecordResponse[],
    SearchParams
  >({
    endpointBuilder: endpoints.tags.tagsList,
    requestMethod: 'post',
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataBuilder: ({ searchString, ordering, ...restParams }) => ({
      ordering: ordering || [OrderingParams.NameAtAsc],
      search: searchString,
      ...restParams,
    }),
    dataMapper: ({ results }) => results.map((item) => generateDictRecord(item)),
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsCount }),
  });

  return { storage };
};
