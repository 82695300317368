import { UiButton, UiCard, UiIcon, UiRow, useBreakpoint } from '@vkph/ui';
import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getNewsFeedStorage, newsCategoriesStore } from '@vkph/common/store/news';
import { getRoutePath, RouteNames } from '@vkph/common/utils';
import { useSpace } from '@vkph/ui/hooks';
import ArrowRight from '@vkph/ui/svg/arrow-right.svg';

import { NewsPinnedItems } from './items/NewsPinnedItems';

export const NewsPinned: FC = () => {
  const breakpoints = useBreakpoint();
  const navigate = useNavigate();
  const newsFeedStorage = useMemo(getNewsFeedStorage, []);
  const { spaceM, spaceS } = useSpace({ unit: 'px' });

  useAbstractStorage(newsFeedStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { pageSize: 6, pinned: true },
    cancelPendingRequestOnUnmount: true,
  });

  useAbstractStorage(newsCategoriesStore.storage, {
    autoFetchAndRefetch: ({ fetchedAtLeastOnce }) => !fetchedAtLeastOnce,
  });

  if (breakpoints.lg) {
    return <NewsPinnedItems newsFeedStorage={newsFeedStorage} newsCategoriesStorage={newsCategoriesStore} />;
  }

  return (
    <UiCard size="default" emptyPadding bodyStyle={{ padding: `${spaceM} 0` }}>
      <NewsPinnedItems newsFeedStorage={newsFeedStorage} newsCategoriesStorage={newsCategoriesStore} />
      <UiRow wrap={false} justify="center" align="middle" style={{ marginTop: spaceS }}>
        <UiButton type="link" onClick={() => navigate(getRoutePath(RouteNames.News))}>
          Все новости
          <UiIcon component={ArrowRight} width={20} height={20} />
        </UiButton>
      </UiRow>
    </UiCard>
  );
};
