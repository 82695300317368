import { UiSpace } from '@vkph/ui';
import React, { FC, PropsWithChildren } from 'react';

import { ControlBarLeftActions } from './left-actions/ControlBarLeftActions';
import { ControlBarRightActions } from './right-actions/ControlBarRightActions';

export type FileListManagerControlBarComposition = {
  RightActions: typeof ControlBarRightActions;
  LeftActions: typeof ControlBarLeftActions;
};

export type FileListManagerControlBarComponent = FileListManagerControlBarComposition & FC<PropsWithChildren>;

export const FileListManagerControlBar: FileListManagerControlBarComponent = (props) => {
  const { children } = props;

  return (
    <UiSpace size={16} full align="center" style={{ justifyContent: 'space-between', padding: 24 }}>
      {children}
    </UiSpace>
  );
};

FileListManagerControlBar.LeftActions = ControlBarLeftActions;
FileListManagerControlBar.RightActions = ControlBarRightActions;
