import { Layout, UiSpace } from '@vkph/ui';
import React, { FC } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { GetFileStorageEntryInfoStorage } from '@vkph/common/store/filestorage';
import { FileStorageEntryId } from '@vkph/common/types/models';
import { useSpace } from '@vkph/ui/hooks';

import { FileActions } from '../actions';
import { FileHistory } from '../history';
import { FileInfoCard } from '../info-card';
import { FileInfoCardGeneral } from '../info-card/general';
import { FileVersions } from '../versions';

type Props = {
  fileInfoStorage: GetFileStorageEntryInfoStorage;
  fileId: FileStorageEntryId;
  fileStorageRootId?: FileStorageEntryId;
};

export const FileView: FC<Props> = (props) => {
  const { fileInfoStorage, fileId, fileStorageRootId } = props;
  const { spaceL } = useSpace();

  useAbstractStorage(fileInfoStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: {
      id: fileId,
    },
    cancelPendingRequestOnUnmount: true,
    resetStoreOnChangeParams: { id: fileId },
  });

  return (
    <Layout.Main>
      <Layout.Content>
        <UiSpace size={spaceL} direction="vertical" style={{ display: 'flex' }}>
          <FileInfoCard fileInfoStorage={fileInfoStorage} fileStorageRootId={fileStorageRootId} />
          <FileVersions id={fileId} fileInfoStorage={fileInfoStorage} />
        </UiSpace>
      </Layout.Content>
      <Layout.Sider>
        <UiSpace size={spaceL} direction="vertical" style={{ display: 'flex' }}>
          <FileInfoCardGeneral fileInfoStorage={fileInfoStorage} id={fileId} />
          <FileActions fileInfoStorage={fileInfoStorage} />
          <FileHistory id={fileId} />
        </UiSpace>
      </Layout.Sider>
    </Layout.Main>
  );
};
