import { UiButton, UiIcon, message, UiSpace, UiTypographyTextTypes } from '@vkph/ui';
import React, { FC } from 'react';

import { confirmEventParticipationEffect, declineEventParticipationEffect } from '@vkph/common/store/events';
import { notificationsStore } from '@vkph/common/store/notifications';
import { EventModel, NotificationModel } from '@vkph/common/types/models';
import { getErrorResponseMessage } from '@vkph/common/utils';
import { variables } from '@vkph/ui/providers/theme';
import SuccessSvg from '@vkph/ui/svg/success.svg';

type Props = {
  notification: NotificationModel<EventModel>;
};

export const NotificationEventActions: FC<Props> = (props) => {
  const { notification } = props;

  const { isUserParticipate, isUserInvite, isRemoved, id } = notification.content;
  const isUserRejected = !isUserInvite && !isUserParticipate;

  const isEventInactive = isUserRejected || isRemoved;

  const onAccept = () => {
    confirmEventParticipationEffect({ id, body: { invitedGuestsCount: 0 } })
      .then(() => {
        message.success('Приглашение принято');
        notificationsStore.refetchWithLastParams();
      })
      .catch((e) => getErrorResponseMessage(e, 'Не удалось принять приглашение'));
  };

  const onReject = () => {
    declineEventParticipationEffect(id)
      .then(() => {
        message.success('Приглашение отклонено');
        notificationsStore.refetchWithLastParams();
      })
      .catch((e) => getErrorResponseMessage(e, 'Не удалить отклонить приглашение'));
  };

  if (isUserParticipate) {
    return (
      <UiIcon.Label
        component={SuccessSvg}
        style={{ color: variables.themePalette.colorSuccess }}
        type={UiTypographyTextTypes.Primary}
        label="Вы участвуете"
        strong
      />
    );
  }

  return (
    <UiSpace align="center">
      <UiButton label="Принять участие" type="primary" disabled={isEventInactive} onClick={onAccept} />
      <UiButton label="Отказаться" type="tertiary" disabled={isEventInactive} onClick={onReject} />
    </UiSpace>
  );
};
