import { UiCard } from '@vkph/ui';
import { format } from 'date-fns';
import { useStore } from 'effector-react';
import React, { useMemo, useState } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getAppearanceDatesStore } from '@vkph/common/store/calendar/appearance-dates';
import { getEventsStorage } from '@vkph/common/store/calendar/event';
import { openGlobalModal, GlobalModalNames } from '@vkph/common/store/global-modals';
import { getFormattedDateForAPI, getNearestHourInterval } from '@vkph/common/utils';

import styles from './CalendarWidget.scss';
import { CalendarWidgetEmpty } from './empty/CalendarWidgetEmpty';
import { CalendarWidgetHeader } from './header/CalendarWidgetHeader';
import { CalendarWidgetListItem } from './list-item/CalendarWidgetListItem';
import { CalendarWidgetWeek } from './week/CalendarWidgetWeek';

export const CalendarWidget: React.FC = () => {
  const eventsStorage = useMemo(getEventsStorage, []);
  const appearanceDatesStorage = useMemo(getAppearanceDatesStore, []);

  const { sinceDate, tillDate } = useStore(appearanceDatesStorage.store);

  const { data: reducedEvents, refetchWithLastParams } = useAbstractStorage(eventsStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: {
      since: getFormattedDateForAPI(sinceDate),
      till: getFormattedDateForAPI(tillDate),
    },
  });

  const [activeDate, setActiveDate] = useState(new Date());

  const openNewMeetingCreating = () => {
    const [since, till] = getNearestHourInterval(activeDate);

    openGlobalModal(GlobalModalNames.CalendarCreateUpdateMeeting, {
      initialEventFormValues: { sinceDate: since, tillDate: till },
      onSuccess: refetchWithLastParams,
    });
  };

  const onDayClickHandler = (selectedDate: Date) => {
    setActiveDate(selectedDate);
  };

  const activeDateKey = format(activeDate, 'yyyy.MM.dd');

  const eventsOnDay = reducedEvents ? reducedEvents[activeDateKey] : [];

  const openEventClickHandler = (index: number) => {
    openGlobalModal(GlobalModalNames.CalendarEventDetail, {
      event: eventsOnDay[index],
      onSuccess: refetchWithLastParams,
    });
  };

  const tasksEls =
    eventsOnDay && eventsOnDay.length > 0 ? (
      eventsOnDay.map(({ id, summary, since, till }, index) => {
        const timeStartString = format(new Date(since), 'HH:mm');
        const timeEndString = tillDate ? `– ${format(new Date(till), 'HH:mm')}` : '';
        const onClickHandler = () => openEventClickHandler(index);

        return (
          <CalendarWidgetListItem
            key={id}
            title={`${timeStartString} ${timeEndString}`}
            subtitle={summary}
            onClick={onClickHandler}
          />
        );
      })
    ) : (
      <CalendarWidgetEmpty addEvent={openNewMeetingCreating} />
    );

  const Title = (
    <div className={styles.calendarWidget__headerContainer}>
      <CalendarWidgetHeader addEvent={openNewMeetingCreating} />
    </div>
  );

  return (
    <UiCard title={Title}>
      <div className={styles.calendarWidget__content}>
        <CalendarWidgetWeek
          selectedDate={activeDate}
          onDayClick={onDayClickHandler}
          appearanceDatesStore={appearanceDatesStorage.store}
        />
      </div>
      <div className={styles.calendarWidget__content}>{tasksEls}</div>
    </UiCard>
  );
};
