import { ActionsDropdown, ActionsDropdownItem } from '@vkph/components';
import {
  ColumnsType,
  UiButton,
  UiIcon,
  UiModal,
  UiSpace,
  UiTable,
  UiTag,
  message,
  useModalBase,
} from '@vkph/ui';
import React, { FC, useMemo } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import {
  GlobalModalNames,
  GlobalModalsStorePayloads,
  closeGlobalModal,
  openGlobalModal,
} from '@vkph/common/store/global-modals';
import { FileStorageCategoryModel } from '@vkph/common/types/models';
import { getErrorResponseMessage } from '@vkph/common/utils';
import { variables } from '@vkph/ui/providers/theme';
import AddCircleSVG from '@vkph/ui/svg/add-circle.svg';
import CloseSVG from '@vkph/ui/svg/close.svg';
import { getModalStepsForSingleTitle } from '@vkph/ui/utils';

type Props = NonNullable<GlobalModalsStorePayloads[GlobalModalNames.FileStorageCategoryList]['payload']>;

export const FileStorageCategoryListModal: FC<Props> = (props) => {
  const { onClose, categoriesStorage, entryId } = props;
  const {
    storage: fileStorageCategoriesStorage,
    createFileStorageCategoryEvent,
    updateFileStorageCategoryEvent,
    deleteFileStorageCategoryEffect,
  } = categoriesStorage;

  const { warning } = useModalBase();

  const { data: fileStorageCategoryData } = useAbstractStorage(fileStorageCategoriesStorage, {
    autoFetchAndRefetch: true,
    cancelPendingRequestOnUnmount: true,
  });

  const onAddCategory = () => {
    openGlobalModal(GlobalModalNames.FileStorageCategoryCreateUpdate, {
      entryId,
      onSuccess: createFileStorageCategoryEvent,
      onClose: () => closeGlobalModal(GlobalModalNames.FileStorageCategoryCreateUpdate),
    });
  };

  const onEdit = (item: FileStorageCategoryModel) => {
    openGlobalModal(GlobalModalNames.FileStorageCategoryCreateUpdate, {
      entryId,
      category: item,
      onSuccess: (category) => {
        updateFileStorageCategoryEvent(category);
      },
      onClose: () => closeGlobalModal(GlobalModalNames.FileStorageCategoryCreateUpdate),
    });
  };

  const onDelete = (item: FileStorageCategoryModel) => {
    warning({
      title: `Удалить категорию?`,
      okText: 'Удалить',
      cancelText: 'Отмена',
      onOk: (close) => {
        deleteFileStorageCategoryEffect({ categoryId: item.id })
          .then(() => {
            close();
            message.success(`Категория успешно удалена`);
          })
          .catch((e) => message.error(getErrorResponseMessage(e, `Ошибка удаления категории`)));
      },
    });
  };

  const columns: ColumnsType<FileStorageCategoryModel> = useMemo(
    () => [
      {
        title: 'Категории',
        key: 'name',
        colSpan: 2,
        align: 'left',
        onHeaderCell: (_) => {
          return {
            style: {
              paddingRight: 24,
            },
          };
        },
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (_, item) => {
          return (
            <UiTag
              style={{ marginLeft: 8 }}
              title={item.name}
              color={variables.themePalette.colorTagSilver}
            />
          );
        },
      },
      {
        key: 'actions',
        width: 20,
        colSpan: 0,
        render: (_, item) => {
          const dropdownActions: ActionsDropdownItem[] = [
            {
              label: 'Удалить',
              onClick: () => onDelete(item),
            },
            {
              label: 'Редактировать',
              onClick: () => onEdit(item),
            },
          ];

          return (
            <div style={{ marginRight: 8 }}>
              <ActionsDropdown key="dropdown" items={dropdownActions} />
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title
          style={{ justifyContent: 'space-between' }}
          steps={getModalStepsForSingleTitle('Категории')}
          noCloseIcon
        >
          <UiSpace size={24}>
            <UiButton
              type="link"
              label="Добавить"
              icon={<UiIcon component={AddCircleSVG} height={20} width={20} />}
              onClick={onAddCategory}
            />
            <UiButton size="middle" type="link-secondary" onClick={onClose} icon={<CloseSVG />} />
          </UiSpace>
        </UiModal.Header.Title>
      </UiModal.Header>
      <UiModal.Content>
        <UiTable
          style={{ padding: '20px 0' }}
          showHeader
          pagination={false}
          columns={columns}
          dataSource={fileStorageCategoryData}
        />
      </UiModal.Content>
    </>
  );
};
