import React, { FC } from 'react';

import {
  GamificationBadges,
  LinkContentModel,
  NotificationModel,
  NotificationThanksCommentModel,
  NotificationThanksReactionModel,
  NotificationTypes,
  NotificationUserBadgeCommentModel,
  NotificationUserBadgeReactionModel,
} from '@vkph/common/types/models';

import { isNotificationSomeOfTypes } from '../../../../types/guards/notification-guards';
import { NotificationGamificationReward } from './reward/NotificationGamificationReward';
import { NotificationGamificationRewardComment } from './reward/comment/NotificationGamificationRewardComment';
import { NotificationGamificationRewardReaction } from './reward/reaction/NotificationGamificationRewardReaction';
import { NotificationGamificationThanks } from './thanks/NotificationGamificationThanks';
import { NotificationGamificationThanksComment } from './thanks/comment/NotificationGamificationThanksComment';
import { NotificationGamificationThanksReaction } from './thanks/reaction/NotificationGamificationThanksReaction';

interface Props {
  notification: NotificationModel;
}

export const NotificationGamification: FC<Props> = (props) => {
  const { notification } = props;

  if (isNotificationSomeOfTypes<LinkContentModel>(notification, [NotificationTypes.GamificationNewThanks])) {
    return <NotificationGamificationThanks notification={notification} />;
  }

  if (
    isNotificationSomeOfTypes<NotificationUserBadgeCommentModel>(notification, [
      NotificationTypes.UserBadgeCommentCreated,
      NotificationTypes.UserBadgeCommentReply,
      NotificationTypes.UserBadgeCommentUserMentioned,
      NotificationTypes.UserBadgeCommentReactionCreated,
    ])
  ) {
    return <NotificationGamificationRewardComment notification={notification} />;
  }

  if (
    isNotificationSomeOfTypes<NotificationThanksCommentModel>(notification, [
      NotificationTypes.ThanksCommentCreated,
      NotificationTypes.ThanksCommentReply,
      NotificationTypes.ThanksCommentUserMentioned,
      NotificationTypes.ThanksCommentReactionCreated,
    ])
  ) {
    return <NotificationGamificationThanksComment notification={notification} />;
  }

  if (
    isNotificationSomeOfTypes<NotificationThanksReactionModel>(notification, [
      NotificationTypes.ThanksReactionCreated,
    ])
  ) {
    return <NotificationGamificationThanksReaction notification={notification} />;
  }

  if (
    isNotificationSomeOfTypes<NotificationUserBadgeReactionModel>(notification, [
      NotificationTypes.UserBadgeReactionCreated,
    ])
  ) {
    return <NotificationGamificationRewardReaction notification={notification} />;
  }

  if (
    isNotificationSomeOfTypes<GamificationBadges>(notification, [
      NotificationTypes.GamificationEventsManualGiveBadges,
      NotificationTypes.GamificationEventsAutomaticGiveBadges,
    ])
  ) {
    return <NotificationGamificationReward notification={notification} />;
  }

  return null;
};
