import { Notification, RouterLink } from '@vkph/components';
import { UiAvatarProps, UiSpace, UiTruncateMarkup } from '@vkph/ui';
import React, { FC } from 'react';

import { CMSPageModel, CMSViewType, NotificationModel } from '@vkph/common/types/models';
import { RouteNames, getFullNameWithoutPatronymic, getRoutePath } from '@vkph/common/utils';

import { getNotificationDescription } from '../../../../../plugins/notification/get-notification-description';

interface Props {
  notification: NotificationModel<CMSPageModel>;
}

export const NotificationCmsMention: FC<Props> = (props) => {
  const { notification } = props;
  const { content, createdAt, initiator, image } = notification;
  const { url, name } = content;
  const { fullName, keycloakId, isActive } = initiator;

  const notificationDescription = getNotificationDescription(notification);
  const notificationTitle = getFullNameWithoutPatronymic(fullName);
  const avatar = { src: image } satisfies UiAvatarProps;

  return (
    <Notification status={notification.status}>
      <Notification.Header
        to={getRoutePath(RouteNames.Profile, { id: keycloakId })}
        title={notificationTitle}
        subtitle={notificationDescription}
        avatarProps={avatar}
        isActive={isActive}
      />
      <Notification.Body>
        <UiSpace direction="vertical" full>
          <RouterLink to={getRoutePath(RouteNames.CmsView, { type: CMSViewType.Page, slugId: url })}>
            <UiTruncateMarkup truncate tooltipProps={{ title: name }}>
              {name}
            </UiTruncateMarkup>
          </RouterLink>
          <Notification.Body.Footer date={createdAt} />
        </UiSpace>
      </Notification.Body>
    </Notification>
  );
};
