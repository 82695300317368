import { UiButton, UiForm, UiInput, UiModal, UiModalProps } from '@vkph/ui';
import React, { FC, useMemo } from 'react';

import { normalizeValueTrimStart } from '@vkph/common/utils';
import { getModalStepsForSingleTitle } from '@vkph/ui/utils';

import styles from './AddEditFileFolderModal.scss';

export interface AddEditFileFolderModalProps extends Pick<UiModalProps, 'onClose'> {
  existingItemName?: string;
  title: string;
  inputPlaceholder: string;
  onApply: (name: string) => void;
  loading: boolean;
}

type FormValues = {
  name: string;
};

const MAX_NAME_LENGTH = 100;

export const AddEditFileFolderModal: FC<AddEditFileFolderModalProps> = (props) => {
  const { onClose, existingItemName = '', title, onApply, loading, inputPlaceholder } = props;
  const [form] = UiForm.useForm<FormValues>();
  const initialFormValues: FormValues = useMemo(
    () => ({
      name: existingItemName,
    }),
    [existingItemName],
  );

  const onFormFinish = ({ name }: FormValues) => {
    if (name) {
      onApply(name.trim());
    }
  };

  return (
    <UiForm form={form} initialValues={initialFormValues} onFinish={onFormFinish}>
      <UiModal.Header>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle(title)} />
      </UiModal.Header>

      <UiModal.Content className={styles.addEditFileFolderModal__content}>
        <UiForm.Item noStyle name="name" normalize={normalizeValueTrimStart} shouldUpdate>
          <UiInput size="large" placeholder={inputPlaceholder} autoFocus maxLength={MAX_NAME_LENGTH} />
        </UiForm.Item>
      </UiModal.Content>

      <UiModal.Footer hasTopBorder={false}>
        <UiModal.Footer.Buttons>
          <UiForm.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const fieldValue = getFieldValue('name');
              const isDisabled = !fieldValue || fieldValue === existingItemName;

              return (
                <UiButton
                  disabled={loading || isDisabled}
                  type="primary"
                  size="large"
                  onClick={form.submit}
                  label="Сохранить"
                />
              );
            }}
          </UiForm.Item>
          <UiButton disabled={loading} type="secondary" size="large" onClick={onClose} label="Отмена" />
        </UiModal.Footer.Buttons>
      </UiModal.Footer>
    </UiForm>
  );
};
