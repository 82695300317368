import { endpoints } from '@vkph/common/endpoints';
import { buildEndpointWithQueryParams } from '@vkph/common/utils';
import { abstractStorageFactory } from '@vkph/common/utils/effector';

import { CurrencyRates } from '../../types/currency-rates';

export const getCurrencyRatesStorage = () => {
  const storage = abstractStorageFactory<CurrencyRates.Currency[], CurrencyRates.Currency, null>({
    endpointBuilder: () => buildEndpointWithQueryParams(endpoints.informers.exchangeRates()),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
    dataMapper: (data) => data[0],
  });

  return { storage };
};

export type CurrencyRatesStorage = ReturnType<typeof getCurrencyRatesStorage>;
