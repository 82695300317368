import { Achievement, Post, Reactions } from '@vkph/components';
import {
  UiButton,
  UiDivider,
  UiModal,
  UiModalTypes,
  UiRender,
  UiRenderType,
  UiSpace,
  message,
  useBreakpoint,
} from '@vkph/ui';
import React, { FC, SyntheticEvent, useContext, useState } from 'react';

import { reactionEffect } from '@vkph/common/store/reactions';
import {
  CommentContentTypes,
  EmojiUuid,
  ReactionContentType,
  TimelineBadgeContentModel,
  TimelineRecordTargetModel,
} from '@vkph/common/types/models';
import { getErrorResponseMessage } from '@vkph/common/utils';
import { useSpace } from '@vkph/ui/hooks';

import { PostCompound } from '../../post/compound/PostCompound';
import { TimelineCardGutterContext, TimelineCardProps } from '../TimelineCard';
import { TimelineCardBadgeUsersModal } from './users-modal/TimelineCardBadgeUsersModal';

interface Props extends Pick<TimelineCardProps, 'onClick'> {
  record: TimelineRecordTargetModel<TimelineBadgeContentModel>;
}

export const TimelineCardBadge: FC<Props> = (props) => {
  const { record, onClick } = props;
  const [isBadgeUsersModalOpen, setIsBadgeUsersModalOpen] = useState(false);
  const [verticalGutter, horizontalGutter] = useContext(TimelineCardGutterContext);
  const { content, createdAt, publishedAt, target } = record;
  const { userBadge } = content;
  const { badge, id, reactions, commentsCount } = userBadge;
  const { lg: isLayoutLarge } = useBreakpoint();
  const { spaceM } = useSpace();

  const onOpenUsersModal = (e: SyntheticEvent) => {
    e.stopPropagation();

    setIsBadgeUsersModalOpen(true);
  };

  const onCloseUsersModal = () => setIsBadgeUsersModalOpen(false);

  const onReaction = (emojiUuid?: EmojiUuid) => {
    reactionEffect({
      objectId: id,
      contentType: ReactionContentType.UserBadge,
      emojiUuid,
    }).catch((e) =>
      message.error(getErrorResponseMessage(e, `Не удалось ${emojiUuid ? 'поставить' : 'удалить'} реакцию`)),
    );
  };

  return (
    <>
      <Post style={{ paddingTop: verticalGutter }}>
        <Post.Header
          padding={[0, horizontalGutter]}
          author={target}
          postDate={publishedAt || createdAt}
          extra="получил(а) награду"
        />
        <Post.Body padding={[spaceM, horizontalGutter]}>
          <UiSpace size={spaceM} direction="vertical" full>
            <UiButton.Decorator onClick={() => onClick?.(record)}>
              <Achievement.Placeholder>
                <Achievement align="start" title={badge.name} paragraph={badge.description}>
                  <UiButton.Decorator onClick={onOpenUsersModal}>
                    <Achievement.Icon type="badge" src={badge.image} />
                  </UiButton.Decorator>
                </Achievement>
              </Achievement.Placeholder>
            </UiButton.Decorator>
            <Reactions onReaction={onReaction} comments={commentsCount} reactions={reactions} />
          </UiSpace>
        </Post.Body>
        <UiRender type={UiRenderType.NullElement} visible={isLayoutLarge}>
          <UiDivider emptyMargin />
          <Post.Footer>
            <PostCompound.CommentsWithInput
              showInput={Boolean(record.target?.isActive)}
              objectId={id}
              contentType={CommentContentTypes.UserBadge}
            />
          </Post.Footer>
        </UiRender>
      </Post>
      <UiModal type={UiModalTypes.Large} isOpen={isBadgeUsersModalOpen} onClose={onCloseUsersModal}>
        <TimelineCardBadgeUsersModal badge={badge} />
      </UiModal>
    </>
  );
};
