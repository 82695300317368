import { UiButton, UiIcon, UiTypography } from '@vkph/ui';
import React from 'react';

import ArrowExpandSVG from '@vkph/ui/svg/arrow-expand.svg';

import { SearchFilter, FilterCatogories } from '../SearchPage';
import { SearchFiltersModal } from '../search-filters-modal/SearchFiltersModal';
import styles from './SearchFilters.scss';

type Props = {
  changeFilters: (selectedSearchFilter: SearchFilter) => void;
  searchFilters: FilterCatogories[];
  selectedSearchFilters: SearchFilter[];
};

export const SearchFilters: React.FC<Props> = (props) => {
  const { changeFilters, searchFilters, selectedSearchFilters } = props;
  const [searchFilter, setSearchFilter] = React.useState<FilterCatogories | null>();

  const onModalOpen = (filter: FilterCatogories) => {
    setSearchFilter(filter);
  };

  const onModalClose = () => setSearchFilter(null);

  return (
    <>
      {searchFilter && (
        <SearchFiltersModal
          searchFilter={searchFilter}
          onClose={onModalClose}
          changeFilters={changeFilters}
          selectedSearchFilters={selectedSearchFilters}
        />
      )}
      <div className={styles.searchFilters}>
        <UiTypography.Title level={3}>Фильтровать</UiTypography.Title>
        {searchFilters.map((filter) => (
          <UiButton
            key={filter.key}
            type="tertiary"
            className={styles.searchFilters__button}
            onClick={() => onModalOpen(filter)}
          >
            {filter.label}
            <UiIcon
              component={ArrowExpandSVG}
              height={20}
              width={20}
              className={styles.searchFilters__icon}
            />
          </UiButton>
        ))}
      </div>
    </>
  );
};
