import { UiModal, UiButton, UiIcon } from '@vkph/ui';
import classNames from 'classnames';
import React, { FC } from 'react';

import DeleteSVG from '@vkph/ui/svg/delete.svg';
import { getModalStepsForSingleTitle } from '@vkph/ui/utils';

import styles from './CreateNewSection.scss';

type Props = {
  title: string;
  children: React.ReactNode;
  onSave: () => void;
  onCancel: () => void;
  onRemove?: () => void;
};

export const CreateNewSection: FC<Props> = (props) => {
  const { title, children, onSave, onCancel, onRemove } = props;

  return (
    <>
      <UiModal.Header>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle(title)} />
      </UiModal.Header>
      <UiModal.Content className={styles.createNewSection__content}>{children}</UiModal.Content>
      <UiModal.Footer hasTopBorder={false}>
        <UiButton size="large" block type="primary" label="Сохранить" onClick={onSave} />
        <UiButton
          block
          size="large"
          type="tertiary"
          label="Отмена"
          onClick={onCancel}
          className={styles.createNewSection__button_leftMargin}
        />

        {onRemove && (
          <UiButton
            block
            size="large"
            type="secondary"
            onClick={onRemove}
            icon={<UiIcon component={DeleteSVG} width={20} height={20} />}
            className={classNames(
              styles.createNewSection__button,
              styles.createNewSection__button_leftMargin,
            )}
          />
        )}
      </UiModal.Footer>
    </>
  );
};
